import * as React from 'react'
import {
  Button,
  Typography,
  Box,
  TextField,
  MenuItem,
  LinearProgress,
  Stack,
} from '@mui/material/'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import GlobalContext from '../../../GlobalContext'
import leftArrow from '../../../images/icon/arrow-left.png'
import crossClose from '../../../images/icon/cross-close.png'
import vocabularyWorkflow from '../../../vocabulary/vocabularyWorkflow'
import documentBlack from '../../../images/icon/document-black.png'
import buildingImage from '../../../images/icon/building-black.png'
import marriageRingsImage from '../../../images/marriage-black.png'
import courtImage from '../../../images/icon/court-black.png'
import balanceImage from '../../../images/balance-black.png'
import passportImage from '../../../images/passport-black.png'
import stampImage from '../../../images/icon/stamp-black.png'

import { FileText } from '@phosphor-icons/react'

export default function FirstScreenUserFlowMain({ smallScreen }) {
  const theme = useTheme()
  const navigate = useNavigate()
  const [serviceSelected, setServiceSelected] = React.useState('')
  const [serviceNeeded, setServiceNeed] = React.useState('')
  const { langSelected, GlobalActions } = React.useContext(GlobalContext)
  const [selected, setSelected] = React.useState(false)

  const handleClose = () => {
    GlobalActions.userFlowMainOpen(false)
  }

  return (
    <Box
      sx={{
        minHeight: smallScreen ? null : 660,
        minWidth: smallScreen ? null : 550,
        maxWidth: smallScreen ? null : 550,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ mx: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '76px',
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
            <img src={leftArrow} alt='left arrow' width={'24px'} />
          </Box>

          <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
            <img src={crossClose} alt='cross' width={'24px'} />
          </Box>
        </Box>

        <Typography component='p' variant='titleLarge' sx={{ mb: '32px' }}>
          {vocabularyWorkflow.applicationSubmit[langSelected]}
        </Typography>

        <TextField
          value={serviceSelected}
          placeholder={vocabularyWorkflow.chooseFromList[langSelected]}
          onChange={event => {
            setSelected(true)
            setServiceSelected(event.target.value)
            console.log(event.target, 'selected service')
          }}
          sx={{
            width: 1,
            height: 56,
            mr: '16px',
            borderRadius: 0,

            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              color: theme.palette.primary.onSurface,
            },

            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
              color: theme.palette.primary.onSurfaceVariant,
              opacity: 1,
              display: 'flex',
              overflow: 'hidden',
            },
          }}
          variant='outlined'
          margin='dense'
          select
          label={vocabularyWorkflow.typeOfService[langSelected]}
        >
          {vocabularyWorkflow.sections[langSelected].map((textSub, index) => {
            return (
              <MenuItem
                key={uuidv4()}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '328px',
                }}
                value={vocabularyWorkflow.sectionsDir[index]}
              >
                <Typography sx={{ display: 'flex' }} component='div'>
                  <Box sx={{ mr: '8px' }}>
                    {index === 0 ? <FileText size={24} weight='light' /> : null}
                    {index === 1 ? (
                      <img src={passportImage} alt='logo' width={'24px'} />
                    ) : null}
                    {index === 2 ? (
                      <img src={documentBlack} alt='logo' width={'24px'} />
                    ) : null}
                    {index === 3 ? (
                      <img src={stampImage} alt='logo' width={'24px'} />
                    ) : null}
                    {index === 4 ? (
                      <img src={buildingImage} alt='logo' width={'24px'} />
                    ) : null}
                    {index === 5 ? (
                      <img src={passportImage} alt='logo' width={'24px'} />
                    ) : null}
                    {index === 6 ? (
                      <img src={marriageRingsImage} alt='logo' width={'24px'} />
                    ) : null}
                    {index === 7 ? (
                      <img src={courtImage} alt='logo' width={'24px'} />
                    ) : null}
                    {index === 8 ? (
                      <img src={balanceImage} alt='logo' width={'24px'} />
                    ) : null}
                  </Box>
                  {textSub}
                </Typography>
              </MenuItem>
            )
          })}
        </TextField>

        <TextField
          value={serviceNeeded}
          placeholder={vocabularyWorkflow.describeServiceNeeded[langSelected]}
          minRows='3'
          maxRows='3'
          onChange={event => {
            setSelected(true)

            setServiceNeed(event.target.value)
          }}
          sx={{
            minWidth: 1,
            mt: 2,
            mr: '16px',

            borderRadius: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              color: theme.palette.primary.onSurface,
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
              color: theme.palette.primary.onSurfaceVariant,
              opacity: 1,
            },
          }}
          variant='outlined'
          margin='dense'
          multiline
        ></TextField>
      </Box>
      <Box>
        <Stack>
          <LinearProgress
            color='progressBar'
            variant='determinate'
            value={5}
            sx={{ backgroundColor: theme.palette.primary.primaryContainer }}
          />
        </Stack>
        <Box
          sx={{
            pb: '16px',
            pt: '24px',
            mt: '8px',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            variant='text'
            size='medium'
            disabled={selected}
            sx={{ border: 'none', height: '40px' }}
            color='blueButton'
            onClick={() => {
              GlobalActions.setDataToSend({
                section: serviceSelected,
              })
              GlobalActions.setDialogServiceOpen({
                step: 0,
                serviceIndex: null,
              })
              GlobalActions.userFlowMainOpen(false)
              navigate('/' + serviceSelected)
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyWorkflow.skip[langSelected]}
            </Typography>
          </Button>

          <Button
            variant='text'
            size='medium'
            disabled={!selected}
            sx={{
              border: 'none',
              height: '40px',
              mr: '16px',
              '&.Mui-disabled': {
                border: 'none', // Remove border when the button is disabled
              },
            }}
            color='blueButton'
            onClick={() => {
              GlobalActions.setDataToSend({
                section: serviceSelected,
                details: serviceNeeded,
              })
              GlobalActions.setDialogServiceOpen({
                step: 0,
                section: serviceSelected,
                serviceIndex: null,
              })
              GlobalActions.userFlowMainOpen(false)

              navigate('/' + serviceSelected)
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyWorkflow.forward[langSelected]}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

import { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import vocabulary from '../../vocabulary/vocabulary'
import Layout600 from '../Layouts/Layout600'
import GlobalContext from '../../GlobalContext'
import Typography from '@mui/material/Typography'
import { Box, Card } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import vocabularyPowerOfAttorney from 'vocabulary/vocabularyPowerOfAttorney'
import arrowRightImg from '../../images/arrow-black-right.png'
import vocabularyConsent from 'vocabulary/vocabularyConsent'
import vocabularyCertificates from 'vocabulary/vocabularyCertificates'
import vocabularyLegalEntities from 'vocabulary/vocabularyLegalEntities'
import vocabularyCitizenship from 'vocabulary/vocabularyCitizenship'
import vocabularyZags from 'vocabulary/vocabularyZags'
import vocabularyOther from 'vocabulary/vocabularyOther'
import vocabularyPassport from 'vocabulary/vocabularyPassport'
import vocabularyPrices from 'vocabulary/vocabularyPrices'
import PriceCard from 'pages/components/PriceCard/PriceCard'
import { useNavigate } from 'react-router-dom'
import squareImg from '../../images/brown-square.png'

export default function Prices600() {
  const { langSelected, GlobalActions } = useContext(GlobalContext)
  const [pageActive, setPageActive] = useState(null)
  const [menuActive, setMenuActive] = useState(null)
  const [headerMenu, setHeaderMenu] = useState('')
  const theme = useTheme()

  const navigate = useNavigate()

  const handleClick = (index, header) => {
    switch (index) {
      case 0:
        setMenuActive({
          priceWithoutAppointment:
            vocabularyPowerOfAttorney.priceWithoutAppointment,
          priceWithAppointment: vocabularyPowerOfAttorney.priceWithAppointment,
        })
        break
      case 1:
        setMenuActive({
          priceWithoutAppointment: vocabularyPassport.priceWithoutAppointment,
          priceWithAppointment: vocabularyPassport.priceWithAppointment,
        })
        break
      case 2:
        setMenuActive({
          priceWithoutAppointment: vocabularyConsent.priceWithoutAppointment,
          priceWithAppointment: vocabularyConsent.priceWithAppointment,
        })
        break
      case 3:
        setMenuActive({
          priceWithoutAppointment:
            vocabularyCertificates.priceWithoutAppointment,
          priceWithAppointment: vocabularyCertificates.priceWithAppointment,
        })
        break
      case 4:
        setMenuActive({
          priceWithoutAppointment:
            vocabularyLegalEntities.priceWithoutAppointment,
          priceWithAppointment: vocabularyLegalEntities.priceWithAppointment,
        })
        break
      case 5:
        setMenuActive({
          priceWithoutAppointment:
            vocabularyCitizenship.priceWithoutAppointment,
          priceWithAppointment: vocabularyCitizenship.priceWithAppointment,
        })
        break
      case 6:
        setMenuActive({
          priceWithoutAppointment: vocabularyZags.priceWithoutAppointment,
          priceWithAppointment: vocabularyZags.priceWithAppointment,
        })
        break
      case 7:
        setMenuActive({
          priceWithoutAppointment: vocabularyOther.priceWithoutAppointment,
          priceWithAppointment: vocabularyOther.priceWithAppointment,
        })
        break

      default:
    }

    setHeaderMenu(header)
    setPageActive(index)
  }

  const consentPageExtraPrice = pageActive === 2
  const shouldShowPrices = pageActive !== 8 && pageActive !== 7

  return (
    <Layout600 theme={theme} menu='true'>
      <Typography variant='h1Small' component='p' sx={{ mx: 2, mt: 10.5 }}>
        {vocabularyPrices.pricesAndTariffs[langSelected]}
      </Typography>

      {pageActive === null ? (
        <Box sx={{ mx: 2, mb: 6.5 }}>
          <Box sx={{ mb: '48px' }}></Box>
          <Typography variant='titleLarge' component='p' sx={{ mb: 4 }}>
            {vocabularyPrices.selectService[langSelected]}
          </Typography>

          {vocabularyPrices.typesOfPrice.map((val, index) => {
            return (
              <Box
                key={uuidv4()}
                sx={{
                  borderTop:
                    !index > 0
                      ? '1px solid ' + theme.palette.primary.outlineVariant
                      : 'none',
                  borderBottom:
                    '1px solid ' + theme.palette.primary.outlineVariant,
                  backgroundColor: theme.palette.primary.surface,
                  width: '100%',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={() => {
                  handleClick(index, val[langSelected])
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {vocabularyPrices.icons[index]}
                  <Typography variant='labelLarge' component='p' sx={{ ml: 1 }}>
                    {val[langSelected]}
                  </Typography>
                </Box>

                <Box>
                  <img
                    src={arrowRightImg}
                    alt='logo'
                    width={20}
                    style={{ marginTop: '4px', marginRight: '16px' }}
                  />
                </Box>
              </Box>
            )
          })}
        </Box>
      ) : (
        <Box sx={{ mt: '56px', mx: '16px', mb: 6.5 }}>
          {shouldShowPrices ? (
            <>
              <Typography variant='h2Small' component='p' sx={{ mb: 4 }}>
                {headerMenu}
              </Typography>

              <PriceCard
                data={menuActive.priceWithoutAppointment[langSelected]}
                squareImg={squareImg}
                colorBorder={true}
              />

              <Box sx={{ mt: 2 }} />
              <PriceCard
                data={menuActive.priceWithAppointment[langSelected]}
                squareImg={squareImg}
                colorBorder={false}
              />

              {consentPageExtraPrice ? (
                <>
                  <Typography variant='h2Small' component='p' sx={{ my: 4 }}>
                    {vocabularyConsent.contractsAgreements[langSelected]}
                  </Typography>

                  <PriceCard
                    data={vocabularyConsent.priceWithAppointment1[langSelected]}
                    squareImg={squareImg}
                    colorBorder={false}
                  />

                  <Box sx={{ mt: 2 }} />

                  <PriceCard
                    data={
                      vocabularyConsent.priceWithoutAppointment1[langSelected]
                    }
                    squareImg={squareImg}
                    colorBorder={false}
                  />
                </>
              ) : null}
            </>
          ) : (
            <>
              <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
                {vocabularyOther.priceText[langSelected]}
              </Typography>

              <Typography
                variant='bodySmall'
                component='div'
                sx={{ mb: '32px' }}
              >
                {vocabularyPassport.priceText2[langSelected]}
              </Typography>
            </>
          )}

          <Box
            sx={{
              my: 6.5,
              display: 'flex',

              width: '100%',
            }}
          >
            <Button
              variant='contained'
              size='medium'
              sx={{ width: 260, height: '40px' }}
              color='blueButton'
              onClick={() => {
                GlobalActions.userFlowMainOpen(true)
              }}
            >
              <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                {vocabulary.applicationSubmit[langSelected]}
              </Typography>
            </Button>
          </Box>

          <Card
            onClick={() => {
              setPageActive(0)
              setMenuActive(null)
              setHeaderMenu('')
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
            sx={{
              mt: '32px',
              mb: '50px',
              px: 2,
              pt: '8px',
              height: 70,
              borderRadius: '12px',
              width: 1 / 2,
              backgroundColor: theme.palette.primary.surfaceContainer,
            }}
            variant='outlined'
          >
            <Typography
              variant='labelSmall'
              component='div'
              sx={{ color: theme.palette.primary.onSurfaceVariant }}
            >
              &larr; {vocabularyPassport.back[langSelected]}
            </Typography>
            <Typography variant='titleSmall'>
              {vocabularyPrices.allServices[langSelected]}
            </Typography>
          </Card>
        </Box>
      )}
    </Layout600>
  )
}

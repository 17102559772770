import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, useTheme, Box, Typography, Card } from '@mui/material/'
import vocabulary from '../../vocabulary/vocabulary'
import GlobalContext from '../../GlobalContext'
import vocabularyPowerOfAttorney from '../../vocabulary/vocabularyPowerOfAttorney'
import squareImg from '../../images/brown-square.png'
import { v4 as uuidv4 } from 'uuid'
import styles from './styles1439'
import AccordionCustom from '../components/AccordionCustom'
import PriceCard from '../components/PriceCard/PriceCard'
import vocabularyPassport from '../../vocabulary/vocabularyPassport'
import vocabularyConsent from '../../vocabulary/vocabularyConsent'
import arrowRightImg from '../../images/arrow-black-right.png'
import { Check, FileText, Info, TagSimple } from '@phosphor-icons/react'
import Layout1439 from '../Layouts/Layout1439'
import CardServiceProcess1439 from '../components/ServiceProcess/CardServiceProcess1439'
import Faq12391439 from '../components/Faq/Faq12391439'
import { Workflow } from '../components/DialogServices/Consent/Workflow'
import { useOpenServiceTypeDialog, useOpenDialogService } from 'pages/helpers'
import { useNavigate } from 'react-router-dom'

export default function Consent1439() {
  const { langSelected } = useContext(GlobalContext)
  const [stateMenu, setMenu] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [columnHeight, setColumnHeight] = useState(0)
  const elementRef = useRef(null)
  const navigate = useNavigate()

  function setStateMenu(val) {
    setMenu(val)
  }

  useEffect(() => {
    const height = elementRef?.current?.offsetHeight

    setColumnHeight(height - 52)
    console.log('Height:', height)
  }, [activeTab])

  const theme = useTheme()

  const CustomTab = ({ children, value, index }) => {
    return <Box hidden={value !== index}>{children}</Box>
  }

  const openConsentDialogService = useOpenDialogService({ section: 'consent' })
  const openConsentTypeDialog = useOpenServiceTypeDialog({
    section: 'consent',
    step: -1,
  })
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <Layout1439
      stateMenu={stateMenu}
      setStateMenu={setStateMenu}
      langSelected={langSelected}
      theme={theme}
      menu='true'
    >
      <Box
        sx={{
          mt: 2,
          mx: '16px',
          pt: '32px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            width: 708,
            display: 'flex',
            alignSelf: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography variant='h1Small'>
            {vocabularyConsent.consentHeader[langSelected]}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',

              mt: 2,
            }}
          >
            <Box
              onClick={() => {
                setActiveTab(0)
              }}
              sx={{
                textTransform: 'none',
                border: '1px solid',
                borderTopLeftRadius: '20px',
                borderBottomLeftRadius: '20px',
                width: 200,
                height: 56,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  activeTab === 0
                    ? theme.palette.primary.secondaryContainer
                    : null,
              }}
            >
              <Typography
                variant='labelLarge'
                component='div'
                sx={{
                  color: theme.palette.primary.onSurface,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {activeTab === 0 ? (
                  <Check
                    size={18}
                    weight='light'
                    style={{ marginRight: '8px' }}
                  />
                ) : (
                  <Info
                    size={18}
                    weight='light'
                    style={{ marginRight: '8px' }}
                  />
                )}

                {vocabularyPassport.info[langSelected]}
              </Typography>
            </Box>
            <Box
              onClick={() => {
                setActiveTab(1)
              }}
              sx={{
                textTransform: 'none',
                borderTop: '1px solid',
                borderBottom: '1px solid',
                width: 200,
                height: 56,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  activeTab === 1
                    ? theme.palette.primary.secondaryContainer
                    : null,
              }}
            >
              <Typography
                component='div'
                variant='labelLarge'
                sx={{
                  color: theme.palette.primary.onSurface,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {activeTab === 1 ? (
                  <Check
                    size={18}
                    weight='light'
                    style={{ marginRight: '8px' }}
                  />
                ) : (
                  <FileText
                    size={18}
                    weight='light'
                    style={{ marginRight: '8px' }}
                  />
                )}
                {vocabularyPassport.documents[langSelected]}
              </Typography>
            </Box>
            <Box
              onClick={() => {
                setActiveTab(2)
              }}
              sx={{
                textTransform: 'none',
                border: '1px solid',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',

                width: 200,
                height: 56,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                backgroundColor:
                  activeTab === 2
                    ? theme.palette.primary.secondaryContainer
                    : null,
              }}
            >
              <Typography
                component='div'
                variant='labelLarge'
                sx={{
                  color: theme.palette.primary.onSurface,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {activeTab === 2 ? (
                  <Check
                    size={18}
                    weight='light'
                    style={{ marginRight: '8px' }}
                  />
                ) : (
                  <TagSimple
                    size={18}
                    weight='light'
                    style={{ marginRight: '8px' }}
                  />
                )}

                {vocabularyPassport.prices[langSelected]}
              </Typography>
            </Box>
          </Box>

          {/*first tab*/}
          <CustomTab value={activeTab} index={0}>
            <Typography variant='h2Small' component='div' sx={{ mt: 6.5 }}>
              {vocabularyPassport.commonInformation[langSelected]}
            </Typography>

            <Typography
              variant='bodySmall'
              component='div'
              sx={{ mt: '32px', mb: 2 }}
            >
              {vocabularyConsent.consentText1[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
              {vocabularyConsent.consentText2[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='div'>
              {vocabularyConsent.consentText3[langSelected]}
            </Typography>

            <Box
              sx={{
                my: 6.5,
                display: 'flex',

                width: '100%',
              }}
            >
              <Button
                variant='contained'
                size='medium'
                sx={{ width: 280, height: '40px' }}
                color='blueButton'
                disableElevation={true}
                onClick={openConsentDialogService}
              >
                <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                  {vocabulary.applicationSubmit[langSelected]}
                </Typography>
              </Button>
            </Box>

            {vocabularyConsent.typesOfConsents.map(val => {
              return (
                <Box sx={{ mb: 8 }} key={uuidv4()}>
                  <Typography sx={{ mb: 3 }} component='div' variant='h2Small'>
                    {val.section[langSelected]}
                  </Typography>
                  {val.types.map((val, index) => {
                    return (
                      <Box
                        key={uuidv4()}
                        sx={{
                          borderTop:
                            !index > 0
                              ? '1px solid ' +
                                theme.palette.primary.outlineVariant
                              : 'none',
                          borderBottom:
                            '1px solid ' + theme.palette.primary.outlineVariant,
                          backgroundColor: theme.palette.primary.surface,
                          width: '100%',
                          minHeight: '48px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}
                        onClick={() => openConsentTypeDialog(val)}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          {val.icon}
                          <Typography
                            component='div'
                            sx={{ my: 1, mx: 2 }}
                            variant='labelLarge'
                          >
                            {val.text[langSelected]}
                          </Typography>
                        </Box>
                        <Box>
                          <img
                            src={arrowRightImg}
                            alt='logo'
                            width={20}
                            style={{ marginTop: '4px', marginRight: '16px' }}
                          />
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
              )
            })}

            <Typography variant='titleLarge' component='p' sx={{ mb: 4 }}>
              {vocabularyConsent.registrationProcess[langSelected]}
            </Typography>

            {vocabularyConsent.orderDescription[langSelected].map(
              (service, index) => {
                let last = false

                if (
                  vocabularyConsent.orderDescription[langSelected].length ===
                  index + 1
                ) {
                  last = true
                }

                return (
                  <CardServiceProcess1439
                    langSelected={langSelected}
                    key={uuidv4()}
                    headerText={service.headerText}
                    cardText={service.cardText}
                    cardImage={service.cardImage}
                    last={last}
                  />
                )
              },
            )}

            <Box
              sx={{
                mt: 6.5,
                mb: 2,
                display: 'flex',
                width: '100%',
              }}
            >
              <Button
                variant='contained'
                size='medium'
                sx={{ width: 280, height: '40px' }}
                color='blueButton'
                disableElevation={true}
                onClick={openConsentDialogService}
              >
                <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                  {vocabulary.applicationSubmit[langSelected]}
                </Typography>
              </Button>
            </Box>

            <Box
              sx={{
                display: 'flex',
                width: '100%',

                mb: 6.5,
              }}
            >
              <Button
                variant='outlined'
                color='blueButton'
                size='medium'
                sx={{ width: 280, borderColor: 'black', height: '40px' }}
                onClick={() => {
                  navigate(`${window.location.pathname}?open=chat`)
                }}
              >
                <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                  {vocabularyPowerOfAttorney.askQuestions[langSelected]}
                </Typography>
              </Button>
            </Box>

            <Box
              sx={{ display: 'flex', flexDirection: 'row-reverse', width: 1 }}
            >
              <Card
                onClick={() => {
                  setActiveTab(1)
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                sx={{
                  mt: '32px',
                  mb: '50px',
                  px: '16px',
                  pt: '8px',
                  height: 70,
                  width: 1,
                  borderRadius: '12px',
                  backgroundColor: theme.palette.primary.surfaceContainer,
                  cursor: 'pointer',
                }}
                variant='outlined'
              >
                <Typography
                  variant='labelSmall'
                  component='div'
                  sx={{ color: theme.palette.primary.onSurfaceVariant }}
                >
                  {vocabularyPassport.next[langSelected]} &rarr;
                </Typography>
                <Typography variant='titleSmall'>
                  {vocabularyPassport.necessaryDoc[langSelected]}
                </Typography>
              </Card>
            </Box>
          </CustomTab>

          {/*second tab*/}

          <CustomTab value={activeTab} index={1}>
            <Typography
              variant='h2Small'
              component='div'
              sx={{ mt: 6.5, mb: '32px' }}
            >
              {vocabularyPassport.requiredDocuments[langSelected]}
            </Typography>

            <Typography variant='bodySmall' component='p' sx={{ mb: '32px' }}>
              {console.log(vocabularyConsent)}
              {vocabularyConsent.requiredDocumentsText1[langSelected]}
            </Typography>

            {vocabularyConsent.userTypes.map((val, index) => {
              return (
                <AccordionCustom
                  key={uuidv4()}
                  index={index}
                  styles={styles}
                  val={val}
                  langSelected={langSelected}
                />
              )
            })}

            <Typography
              variant='bodySmall'
              component='p'
              sx={{ mb: 6.5, mt: 4 }}
            >
              {vocabularyConsent.requiredDocumentsText2[langSelected]}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Card
                onClick={() => {
                  setActiveTab(0)
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                sx={{
                  mb: 2,
                  px: '16px',
                  pt: '8px',
                  mr: '16px',
                  height: 70,
                  borderRadius: '12px',
                  backgroundColor: theme.palette.primary.surfaceContainer,
                  flex: 1,
                }}
                variant='outlined'
              >
                <Typography
                  variant='labelSmall'
                  component='div'
                  sx={{ color: theme.palette.primary.onSurfaceVariant }}
                >
                  &larr; {vocabularyPassport.back[langSelected]}
                </Typography>
                <Typography variant='titleSmall'>
                  {vocabularyPassport.commonInformation[langSelected]}
                </Typography>
              </Card>

              <Card
                onClick={() => {
                  setActiveTab(2)
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                sx={{
                  mb: '50px',
                  px: '16px',
                  pt: '8px',
                  flex: 1,
                  height: 70,

                  borderRadius: '12px',
                  backgroundColor: theme.palette.primary.surfaceContainer,
                }}
                variant='outlined'
              >
                <Typography
                  variant='labelSmall'
                  component='div'
                  sx={{ color: theme.palette.primary.onSurfaceVariant }}
                >
                  {vocabularyPassport.next[langSelected]} &rarr;
                </Typography>
                <Typography variant='titleSmall'>
                  {vocabularyPassport.prices[langSelected]}
                </Typography>
              </Card>
            </Box>
          </CustomTab>

          {/* third tab */}
          <CustomTab value={activeTab} index={2}>
            <Typography
              variant='h2Small'
              component='div'
              sx={{ mt: 6.5, mb: 4 }}
            >
              {vocabularyPassport.priceAndTariffs[langSelected]}
            </Typography>

            <Typography
              variant='titleLarge'
              component='div'
              sx={{ mt: 4, mb: 2.5 }}
            >
              {vocabularyConsent.consentsStatements[langSelected]}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', rowGap: 1 }}>
              <Box sx={{ flex: 1 }}>
                <PriceCard
                  data={vocabularyConsent.priceWithoutAppointment[langSelected]}
                  squareImg={squareImg}
                  colorBorder={true}
                />
              </Box>
              <Box sx={{ flex: 1, ml: 2 }}>
                <PriceCard
                  data={vocabularyConsent.priceWithAppointment[langSelected]}
                  squareImg={squareImg}
                  colorBorder={false}
                />
              </Box>
            </Box>
            <Typography
              variant='titleLarge'
              component='div'
              sx={{ mt: 4, mb: 2.5 }}
            >
              {vocabularyConsent.contractsAgreements[langSelected]}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', rowGap: 1 }}>
              <Box sx={{ flex: 1 }}>
                <PriceCard
                  data={
                    vocabularyConsent.priceWithoutAppointment1[langSelected]
                  }
                  squareImg={squareImg}
                  colorBorder={true}
                />
              </Box>
              <Box sx={{ flex: 1, ml: 2 }}>
                <PriceCard
                  data={vocabularyConsent.priceWithAppointment1[langSelected]}
                  squareImg={squareImg}
                  colorBorder={false}
                />
              </Box>
            </Box>

            <Typography
              variant='bodySmall'
              component='div'
              sx={{ mt: '32px', mb: 2 }}
            >
              {vocabularyPassport.priceText1[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='div' sx={{ mb: '32px' }}>
              {vocabularyPassport.priceText2[langSelected]}
            </Typography>

            <Box
              sx={{
                mt: 4,

                mb: 2,
                display: 'flex',

                width: 1,
              }}
            >
              <Button
                variant='contained'
                size='medium'
                sx={{ width: 280, height: 40 }}
                color='blueButton'
                disableElevation={true}
                onClick={openConsentDialogService}
              >
                <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                  {vocabulary.applicationSubmit[langSelected]}
                </Typography>
              </Button>
            </Box>

            <Box
              sx={{
                mb: 6.5,
                display: 'flex',

                width: 1,
              }}
            >
              <Button
                variant='outlined'
                size='medium'
                sx={{ width: 280, height: 40, borderColor: 'black' }}
                color='blueButton'
                onClick={() => {
                  navigate(`${window.location.pathname}?open=chat`)
                }}
              >
                <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                  {vocabulary.ask[langSelected]}
                </Typography>
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Card
                onClick={() => {
                  setActiveTab(1)
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                sx={{
                  mb: 2,
                  px: '16px',
                  pt: '8px',
                  mr: '16px',
                  width: 1,
                  height: 70,
                  borderRadius: '12px',
                  backgroundColor: theme.palette.primary.surfaceContainer,
                }}
                variant='outlined'
              >
                <Typography
                  variant='labelSmall'
                  component='div'
                  sx={{ color: theme.palette.primary.onSurfaceVariant }}
                >
                  &larr; {vocabularyPassport.back[langSelected]}
                </Typography>
                <Typography variant='titleSmall'>
                  {vocabularyPassport.necessaryDoc[langSelected]}
                </Typography>
              </Card>
            </Box>
          </CustomTab>
          {/* forth tab*/}
        </Box>

        <Box
          sx={{
            width: 304,
            ml: '16px',
            backgroundColor: theme.palette.primary.surfaceContainerLow,
            px: '24px',
            overflowY: 'auto',
            maxHeight: columnHeight,
          }}
        >
          {console.log('rerender', columnHeight)}
          <Typography
            variant='titleLarge'
            component='div'
            sx={{ py: 2, textTransform: 'none' }}
          >
            {vocabularyPowerOfAttorney.faq[langSelected]}
          </Typography>

          <Faq12391439 value={vocabularyConsent.faqDesc[langSelected]} />
        </Box>
      </Box>
      <Workflow />
    </Layout1439>
  )
}

const vocabularyAppointment = {
  getAppointment: [
    'Запись в консульство',
    'Get an appointment',
    '预约领事馆',
    'Hacer una cita',
  ],
  text1: [
    'Для всех наших услуг существует два варианта тарифов:',
    'There are two tariff options for all our services:',
    '我们所有服务都有两种收费选项：',
    'Hay dos opciones de tarifa para todos nuestros servicios:',
  ],
  withoutAppointment: [
    'Без записи в консульство',
    'Without an appointment at the consulate',
    '无需预约领事馆',
    'Sin cita en el consulado',
  ],
  withAppointment: [
    'С записью в консульство',
    'With an appointment at the consulate',
    '预约领事馆',
    'Con cita en el consulado',
  ],
  withoutAppointmentText: [
    'Вы записываетесь на приём самостоятельно, при этом мы со своей стороны готовы консультировать вас на каждом этапе',
    'You make an appointment yourself, and we, for our part, are ready to advise you at every stage',
    '您可以自行预约，我们将随时为您提供每个阶段的咨询',
    'Usted hace la cita por sí mismo, y nosotros, por nuestra parte, estamos listos para asesorarlo en cada etapa.',
  ],
  withAppointmentText: [
    'Мы полностью берём на себя процесс записи на приём, вам достаточно просто указать местонахождение консульств, которое вы планируете посетить',
    'We completely take care of the process of making an appointment; you just need to indicate the location of the consulates you plan to visit',
    '我们全权负责预约流程；您只需指明您计划访问的领事馆的位置',
    'Nosotros nos encargamos completamente del proceso de hacer una cita; solo necesita indicar la ubicación de los consulados que planea visitar.',
  ],
  text2: [
    'Отдельной услуги по записи на приём в консульство мы не предоставляем.',
    'We do not provide a separate service for making an appointment at the consulate.',
    '我们不提供单独的预约领事馆服务。',
    'No ofrecemos un servicio separado para hacer una cita en el consulado.',
  ],
  howToGetAnAppointment: [
    'Как записаться на приём самостоятельно',
    'How can I receive an appointment by myself',
    '如何自行预约',
    'Cómo puedo obtener una cita por mí mismo',
  ],
  text3: [
    'Записаться на приём в Консульство России можно через электронную систему на официальном сайте нужного вам Посольства или Консульства России.',
    'You can make an appointment at the Russian Consulate through the electronic system on the official website of the Russian Embassy or Consulate you need.',
    '您可以通过所需的俄罗斯大使馆或领事馆的官方网站上的电子系统预约俄罗斯领事馆。',
    'Puede hacer una cita en el Consulado Ruso a través del sistema electrónico en el sitio web oficial de la Embajada o Consulado Ruso que necesite.',
  ],
  appointmentList: [
    [
      'Зайдите на сайт нужного вам Посольства или Консульства России',
      'Go to the website of the Russian Embassy or Consulate you need',
      '访问您所需的俄罗斯大使馆或领事馆网站',
      'Visite el sitio web de la Embajada o Consulado Ruso que necesite',
    ],
    [
      'В разделе «Консульские услуги» или «Консульский отдел» выберите «Запись на приём»',
      'In the “Consular Services” or “Consular Section” section, select “Make an Appointment”',
      '在“领事服务”或“领事处”部分，选择“预约”',
      'En la sección de “Servicios consulares” o “Sección consular”, seleccione “Hacer una cita”',
    ],
    [
      'Перейдите в систему электронной записи и выберите нужную вам услугу',
      'Go to the electronic recording system and select the service you need',
      '进入电子登记系统并选择您需要的服务',
      'Vaya al sistema de registro electrónico y seleccione el servicio que necesita',
    ],
    [
      'Зарегистрируйтесь, чтобы получить доступ к календарю с доступными датами и временем',
      'Register to access a calendar with available dates and time slots',
      '注册以获取可用日期和时间的日历访问',
      'Regístrese para acceder a un calendario con fechas y horarios disponibles',
    ],
    [
      'Если выбрать день невозможно, это значит, что свободных слотов нет. В таком случае добавьте себя в список ожидания',
      'If it is impossible to select a day, this means that there are no free slots. If so, please add yourself to the waiting list.',
      '如果无法选择日期，这意味着没有空位。如果是这样，请将自己添加到候补名单中。',
      'Si no es posible seleccionar un día, significa que no hay espacios libres. Si es así, agréguese a la lista de espera.',
    ],
    [
      'На почту, которую вы указали при регистрации, придёт письмо со ссылкой, по которой нужно переходить каждый день (ровно один раз в день) для повышения рейтинга заявки. Ожидание может занять от 10 до 30 дней, в зависимости от вашей последовательности, выбранной услуги и местонахождения консульства',
      'An email with a link will be sent to the email address you provided during registration, which you must click on every day (exactly once a day) to increase your application rating. The wait may take from 10 to 30 days, depending on your sequence, the chosen service and the location of the consulate',
      '会向您在注册时提供的电子邮件地址发送一封带有链接的电子邮件，您必须每天点击该链接（每天仅一次），以提高您的申请评分。等待时间可能从10到30天，具体取决于您的顺序、所选服务和领事馆的位置。',
      'Se enviará un correo electrónico con un enlace a la dirección de correo electrónico que proporcionó durante el registro, que debe hacer clic todos los días (exactamente una vez al día) para aumentar la calificación de su solicitud. La espera puede durar de 10 a 30 días, dependiendo de su secuencia, el servicio elegido y la ubicación del consulado.',
    ],
  ],
}

export default vocabularyAppointment

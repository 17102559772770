const vocabularyPersonalArea = {
  personalArea: [
    'Личный кабинет',
    'Personal area',
    '个人中心',
    'Área personal',
  ],
  profile: ['Профиль', 'Profile', '个人资料', 'Perfil'],
  applications: ['Заявки', 'Applications', '申请', 'Solicitudes'],
  changePassword: [
    'Изменить пароль',
    'Change password',
    '更改密码',
    'Cambiar contraseña',
  ],
  exit: ['Выйти', 'Exit', '退出', 'Salir'],
  openOrder: ['Открыть заявку', 'Open order', '打开申请', 'Abrir solicitud'],
  changeOrder: [
    'Изменить заявку',
    'Change order',
    '更改申请',
    'Cambiar solicitud',
  ],
  copyOrder: [
    'Копировать заявку',
    'Copy order',
    '复制申请',
    'Copiar solicitud',
  ],
  cancelOrder: [
    'Отменить заявку',
    'Cancel order',
    '取消申请',
    'Cancelar solicitud',
  ],
  service: ['Услуга', 'Service', '服务', 'Servicio'],
  status: ['Статус', 'Status', '状态', 'Estado'],
  confirmPassword: [
    'Подтвердите пароль',
    'Confirm password',
    '确认密码',
    'Confirmar contraseña',
  ],
}

export default vocabularyPersonalArea

import React, {
  useReducer,
  useMemo,
  createContext,
  useState,
  useEffect,
} from 'react'
import { signIn, signInGoogle, signUp } from './api/auth'
// import { SignInAttempt, SignUpAttempt, sendCode } from './api/authApi';
import { getUserInformation } from './api/auth'
import { getUserRequests } from 'api/workflow'
import { merge } from './utils/merge'

const GlobalContext = createContext()

const ActionTypes = {
  LOG_IN: 'LOG_IN',
  COUNTRY: 'COUNTRY',
  SET_CURRENCY: 'SET_CURRENCY',
  LOG_OUT: 'LOG_OUT',
  USER_DATA_SET: 'USER_DATA_SET',
  RESTORE_SESSION: 'RESTORE_SESSION',
  SET_USER_INFO: 'SET_USER_INFO',
  SET_CHAT_FLOW: 'SET_CHAT_FLOW',
  SET_REDACTOR_STATE: 'SET_REDACTOR_STATE',
  SET_NODES: 'SET_NODES',
  SET_USER_FLOW_MAIN_OPEN_SCREEN: 'SET_USER_FLOW_MAIN_OPEN_SCREEN',
  SET_DIALOG_SERVICE_OPEN: 'SET_DIALOG_SERVICE_OPEN',
  SET_DIALOG_SERVICE_PREVIOUS_STEP: 'SET_DIALOG_SERVICE_PREVIOUS_STEP',
  SET_DATA_TO_SEND: 'SET_DATA_TO_SEND',
  SET_SECTION: 'SET_SECTION',
  SET_SUBMENU_SELECTED: 'SET_SUBMENU_SELECTED',
  SET_MENU_SELECTED: 'SET_MENU_SELECTED',
  SET_LOGIN_FLOW_OPEN: 'SET_LOGIN_FLOW_OPEN',
  SET_LOGIN_FLOW_CLOSED: 'SET_LOGIN_FLOW_CLOSED',
  GET_USER_REQUESTS: 'GET_USER_REQUESTS',
  SET_NEXT_STEP: 'SET_NEXT_STEP',
  SET_COUNTRY: 'SET_COUNTRY',
}

function globalReducer(prevState, action) {
  switch (action.type) {
    case ActionTypes.LOG_IN:
      return {
        ...prevState,
        userToken: action.userToken,
        userInfo: action.userInfo,
      }
    case ActionTypes.LOG_OUT:
      return {
        ...prevState,
        userToken: null,
        userSessionsList: null,
        userInfo: null,
      }

    case ActionTypes.USER_DATA_SET:
      return {
        ...prevState,
        userSessionsList: action.userSessionsList,
      }
    case ActionTypes.RESTORE_SESSION:
      return {
        ...prevState,
        userToken: action.userToken,
      }
    case ActionTypes.SET_USER_INFO:
      return {
        ...prevState,
        userInfo: action.userInfo,
        userRequests: action.userRequests,
      }

    case ActionTypes.SET_RESOLUTION:
      return {
        ...prevState,
        resolution: action.resolution,
      }

    case ActionTypes.SET_CURRENCY:
      return {
        ...prevState,
        currency: action.currency,
      }

    case ActionTypes.SET_MENU_SELECTED:
      return {
        ...prevState,
        menuSelected: action.menuSelected,
      }

    case ActionTypes.SET_SUBMENU_SELECTED:
      return {
        ...prevState,
        submenuSelected: action.submenuSelected,
      }

    case ActionTypes.SET_USER_FLOW_MAIN_OPEN_SCREEN:
      return {
        ...prevState,
        userFlowMainOpenScreen: action.userFlowMainOpenScreen,
      }
    case ActionTypes.SET_CONFIG:
      return {
        ...prevState,
        config: action.config,
      }
    case ActionTypes.SET_SECTION:
      return {
        ...prevState,
        section: action.section,
      }

    case ActionTypes.SET_COUNTRY:
      return {
        ...prevState,
        country: action.country,
      }

    case ActionTypes.SET_DIALOG_SERVICE_OPEN: {
      if (!action.dialogService) {
        return {
          ...prevState,
          dialogService: action.dialogService,
        }
      }

      const previousStep = prevState.dialogService.step

      const history = prevState.dialogService.history
        ? [...prevState.dialogService.history, previousStep]
        : []

      return {
        ...prevState,
        dialogService: {
          ...prevState.dialogService,
          ...action.dialogService,
          history,
        },
      }
    }

    case ActionTypes.SET_DIALOG_SERVICE_PREVIOUS_STEP: {
      const history = prevState.dialogService.history
      const step = history.pop()

      return {
        ...prevState,
        dialogService: {
          ...prevState.dialogService,
          history: [...history],
          step,
        },
      }
    }

    case ActionTypes.SET_NEXT_STEP:
      return {
        ...prevState,
        nextStep: action.nextStep,
      }

    case ActionTypes.SET_DATA_TO_SEND:
      return {
        ...prevState,
        dataToSend: action.dataToSend,
      }

    case ActionTypes.SET_LOGIN_FLOW_OPEN: {
      return {
        ...prevState,
        loginFlow: {
          isOpened: true,
          window: action.payload.window,
        },
      }
    }

    case ActionTypes.GET_USER_REQUESTS: {
      return {
        ...prevState,
        userRequests: action.payload.userRequests,
      }
    }

    case ActionTypes.SET_LOGIN_FLOW_CLOSED: {
      return {
        ...prevState,
        loginFlow: {
          isOpened: false,
          window: null,
        },
      }
    }

    default:
      return prevState
  }
}

export function GlobalContextWrapper({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [langSelected, setLangSelected] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false)

  useEffect(() => {
    GlobalActions.restoreSession()

    const userLanguage = localStorage.getItem('lang') || 'ru'
    const lang = userLanguage === 'ru' ? 0 : 1

    localStorage.setItem('lang', userLanguage)

    setLangSelected(lang)
  }, [])

  const [state, dispatch] = useReducer(globalReducer, {
    loginFlow: {
      isOpened: false,
      window: null,
    },
    userToken: null,
    userSessionsList: null,
    userInfo: null,
    nextStep: 1,
    country: 'Australia',
    currency: 'usd',
    chatFlow: null,
    redactorState: null,
    nodes: null,
    userFlowMainOpenScreen: false,
    resolution: null,
    dialogService: false,
    section: false,
    menuSelected: 0,
    submenuSelected: false,
    userRequests: [],
    dataToSend: {
      section: null,
      service: null,
      country: null,
      city: null,
      serviceDetails: {},
      additionalServiceType: null,
      details: null,
      appointment: null,
      userData: null,
    },
  })

  const GlobalActions = useMemo(
    () => ({
      restoreSession: async () => {
        setIsLoading(true)

        const authToken = localStorage.getItem('authToken')

        if (authToken?.length > 10) {
          try {
            await dispatch({
              type: ActionTypes.RESTORE_SESSION,
              userToken: authToken,
            })

            let { user } = await getUserInformation()
            let userRequests = await getUserRequests()
            setIsLoggedIn(true)
            dispatch({
              type: ActionTypes.SET_USER_INFO,
              userInfo: user,
              userRequests: userRequests,
            })
          } catch (error) {
            console.error('Error restoring token:', error)
          }
        }

        setIsLoading(false)
      },
      userFlowMainOpen: async data => {
        try {
          await dispatch({
            type: ActionTypes.SET_USER_FLOW_MAIN_OPEN_SCREEN,
            userFlowMainOpenScreen: data,
          })
        } catch (error) {
          console.error('Error restoring token:', error)
        }
      },
      setResolution: async data => {
        try {
          await dispatch({
            type: ActionTypes.SET_RESOLUTION,
            resolution: data,
          })
        } catch (error) {
          console.error('Error restoring token:', error)
        }
      },
      setCountry: async data => {
        try {
          await dispatch({
            type: ActionTypes.SET_COUNTRY,
            country: data,
          })
        } catch (error) {
          console.error('Error restoring token:', error)
        }
      },
      setCurrency: async data => {
        try {
          await dispatch({
            type: ActionTypes.SET_CURRENCY,
            currency: data,
          })
        } catch (error) {
          console.error('Error next step:', error)
        }
      },

      setLang: async data => {
        await localStorage.setItem('lang', data === 0 ? 'ru' : 'en')

        await setLangSelected(data)
      },

      setDrawerOpen: async data => {
        await setLeftDrawerOpen(data)
      },

      setDialogServiceOpen: async data => {
        try {
          await dispatch({
            type: ActionTypes.SET_DIALOG_SERVICE_OPEN,
            dialogService: data,
          })
        } catch (error) {
          console.error('Error restoring token:', error)
        }
      },

      setDialogServicePreviousStep: async data => {
        try {
          await dispatch({
            type: ActionTypes.SET_DIALOG_SERVICE_PREVIOUS_STEP,
            dialogService: data,
          })
        } catch (error) {
          console.error('Error:', error)
        }
      },

      setMenuSelected: async data => {
        try {
          await dispatch({
            type: ActionTypes.SET_MENU_SELECTED,
            menuSelected: data,
          })
        } catch (error) {
          console.error('Error :', error)
        }
      },

      setSubmenuSelected: async data => {
        try {
          await dispatch({
            type: ActionTypes.SET_SUBMENU_SELECTED,
            submenuSelected: data,
          })
        } catch (error) {
          console.error('Error :', error)
        }
      },

      setDataToSend: async data => {
        if (data.section) {
          try {
            await dispatch({
              type: ActionTypes.SET_SECTION,
              section: data.section,
            })
          } catch (error) {
            console.error('Error', error)
          }
        }

        const dataToSend = merge(state.dataToSend, data)

        try {
          await dispatch({
            type: ActionTypes.SET_DATA_TO_SEND,
            dataToSend: dataToSend,
          })
        } catch (error) {
          console.error('Error', error)
        }
      },
      setNextStep: async data => {
        try {
          await dispatch({
            type: ActionTypes.SET_NEXT_STEP,
            nextStep: data,
          })
        } catch (error) {
          console.error('Error next step:', error)
        }
      },

      clearDataToSend: async data => {
        GlobalActions.userFlowMainOpen(false)
        let dataToSend = {
          section: null,
          service: null,
          country: null,
          city: null,
          serviceDetails: {},
          additionalServiceType: null,
          details: null,
          appointment: null,
          userData: null,
        }

        try {
          await dispatch({
            type: ActionTypes.SET_DATA_TO_SEND,
            dataToSend: dataToSend,
          })
        } catch (error) {
          console.error('Error', error)
        }

        try {
          dispatch({
            type: ActionTypes.SET_SECTION,
            section: false,
          })
        } catch (error) {
          console.error('Error', error)
        }
      },

      logIn: async data => {
        let result = null

        try {
          switch (data?.variant) {
            case 'google':
              result = await signInGoogle(data)
              break
            default:
              result = await signIn(data)
          }

          if (result === 'bad credentials') {
            return result
          }

          const authToken = result.token

          await localStorage.setItem('authToken', authToken)
          let userInfo = result.user

          let userRequests = await getUserRequests()

          await dispatch({
            type: ActionTypes.LOG_IN,
            userToken: authToken,
            //  userInfo: userInfo.data.userData,
          })
          await dispatch({
            type: ActionTypes.SET_USER_INFO,
            userInfo: userInfo,
            userRequests: userRequests,
          })

          setIsLoggedIn(true)
        } catch (error) {
          console.error('Error logging in:', error)
        }
      },

      /*    sendCode: async data => {
        try {
          let result = '' //await sendCode(data);

          if (result === 'Code incorrect.') {
            return result
          }

          const { authToken } = result
          localStorage.setItem('authToken', authToken)
          dispatch({
            type: ActionTypes.LOG_IN,
            userToken: authToken,
          })
          setIsLoggedIn(true)

          let userInfo = //await getUserInformation();
            dispatch({
              type: ActionTypes.SET_USER_INFO,
              userInfo: userInfo.data.userData,
            })

          return true
        } catch (error) {
          console.error('Error signing up:', error)

          return false
        }
      },
*/
      signUp: async data => {
        try {
          let result = await signUp(data)

          if (result === 'bad credentials') {
            return result
          }

          if ('token' in result) {
            const { token } = result

            localStorage.setItem('authToken', token)
            dispatch({
              type: ActionTypes.LOG_IN,
              userToken: token,
            })
            setIsLoggedIn(true)

            dispatch({
              type: ActionTypes.SET_USER_INFO,
              userInfo: result.user,
            })
          }

          return true
        } catch (error) {
          console.error('Error signing up:', error)

          return false
        }
      },

      logOut: async data => {
        try {
          localStorage.removeItem('userToken')
          localStorage.removeItem('authToken')
          dispatch({
            type: ActionTypes.LOG_OUT,
            userToken: null,
          })
          setIsLoggedIn(false)
        } catch (error) {
          console.error('Error logging in:', error)
        }
      },

      setLoginFlowOpened: async payload => {
        dispatch({ type: ActionTypes.SET_LOGIN_FLOW_OPEN, payload })
      },

      setLoginFlowClosed: async payload => {
        dispatch({ type: ActionTypes.SET_LOGIN_FLOW_CLOSED, payload })
      },

      getUserRequests: async payload => {
        setIsLoading(true)

        try {
          let userRequests = await getUserRequests()
          setIsLoggedIn(true)
          dispatch({
            type: ActionTypes.GET_USER_REQUESTS,
            payload: { userRequests: userRequests },
          })
        } catch (error) {
          console.error('Error restoring token:', error)
        }

        setIsLoading(false)
      },
    }),
    [state],
  )

  return (
    <GlobalContext.Provider
      value={{
        state,
        isLoading,
        isLoggedIn,
        GlobalActions,
        langSelected,
        leftDrawerOpen,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext

const vocabularyWorkflow = {
  applicationSubmit: [
    'Оформить заявку',
    'Submit an Application',
    '提交申请',
    'Enviar solicitud',
  ],
  submit: ['Оформить', 'Submit', '提交', 'Enviar'],
  ask: ['Спросить', 'Ask', '询问', 'Preguntar'],
  typeOfService: [
    'Вид услуги',
    'Type of service',
    '服务类型',
    'Tipo de servicio',
  ],
  describeServiceNeeded: [
    'Или опишите своими словами, чем мы вам можем помочь',
    'Or describe in your own words how we can help you.',
    '或者用自己的话描述我们如何帮助您',
    'O describa en sus propias palabras cómo podemos ayudarle.',
  ],
  chooseFromList: [
    'Выберите из списка',
    'Choose from list',
    '从列表中选择',
    'Elija de la lista',
  ],
  skip: ['Пропустить', 'Skip', '跳过', 'Saltar'],
  chooseConsulate: [
    'В каком консульстве вы хотите оформлять документы?',
    'In which consulate do you want to process documents',
    '您想在哪个领事馆办理文件？',
    '¿En qué consulado desea procesar documentos?',
  ],
  country: ['Страна', 'Country', '国家', 'País'],
  city: ['Город', 'City', '城市', 'Ciudad'],
  sectionsDir: [
    'poa',
    'passport',
    'consent',
    'certificates',
    'legal-entities',
    'citizenship',
    'zags',
    'other',
    'consulting',
  ],
  sections: [
    [
      'Доверенности',
      'Загранпаспорт',
      'Согласия, заявления, договоры',
      'Справки',
      'Документы для юр. лиц',
      'Гражданство',
      'Услуги ЗАГС',
      'Другие консульские услуги',
      'Консультационные услуги',
    ],
    [
      'Powers of Attorney',
      'Passport (foreign)',
      'Consents, Statements, Contracts',
      'References',
      'Documents for Legal Entities',
      'Citizenship',
      'Registry Office Services',
      'Other Consular Services',
      'Consulting Services',
    ],
    [
      '委托书',
      '护照（外国）',
      '同意书、声明、合同',
      '参考资料',
      '法人文件',
      '国籍',
      '登记处服务',
      '其他领事服务',
      '咨询服务',
    ],
    [
      'Poderes notariales',
      'Pasaporte (extranjero)',
      'Consentimientos, declaraciones, contratos',
      'Referencias',
      'Documentos para entidades legales',
      'Ciudadanía',
      'Servicios de registro civil',
      'Otros servicios consulares',
      'Servicios de consultoría',
    ],
  ],
  countries: [
    {
      Абхазия: ['Сухум'],
      Австралия: ['Канберра', 'Сидней'],
      Австрия: ['Вена', 'Зальцбург'],
      Азербайджан: ['Баку'],
      Албания: ['Тирана'],
      Алжир: ['Алжир', 'Аннаба'],
      Ангола: ['Луанда'],
      Аргентина: ['Буэнос-Айрес'],
      Армения: ['Гюмри', 'Ереван'],
      Афганистан: ['Кабул', 'Мазари-Шариф'],
      Бангладеш: ['Дакка', 'Читтагонг'],
      Бахрейн: ['Манама'],
      Беларусь: ['Брест', 'Минск'],
      Бельгия: ['Брюссель'],
      Бенин: ['Котону'],
      Болгария: ['София'],
      Боливия: ['Ла-Пас'],
      'Босния и Герцеговина': ['Сараево'],
      Ботсвана: ['Габороне'],
      Бразилия: ['Бразилиа', 'Рио-де-Жанейро', 'Сан-Пауло'],
      Бруней: ['Бандар-Сери-Бегаван'],
      Бурунди: ['Бужумбур'],
      Ватикан: ['Ватикан'],
      Великобритания: ['Лондон', 'Эдинбург'],
      Венгрия: ['Будапешт', 'Дебрецен'],
      Венесуэла: ['Каракас'],
      Вьетнам: ['Дананг', 'Ханой', 'Хошимин'],
      Габон: ['Либревиль'],
      Гайана: ['Джорджтаун'],
      Гана: ['Аккра'],
      Гватемала: ['Гватемала'],
      Гвинея: ['Конакри'],
      'Гвинея-Бисау': ['Бисау'],
      Германия: ['Берлин', 'Бонн'],
      Греция: ['Афины', 'Салоники'],
      Грузия: ['Тбилиси'],
      Дания: ['Копенгаген'],
      Джибути: ['Джибути'],
      Египет: ['Александрия', 'Каир'],
      Замбия: ['Лусака'],
      Зимбабве: ['Хараре'],
      Израиль: ['Тель-Авив', 'Хайфа'],
      Индия: ['Калькутта', 'Мумбаи', 'Нью-Дели', 'Ченнаи'],
      Индонезия: ['Джакарта'],
      Иордания: ['Амман'],
      Ирак: ['Багдад', 'Басра', 'Эрбиль'],
      Иран: ['Исфахан', 'Решт', 'Тегеран'],
      Ирландия: ['Дублин'],
      Исландия: ['Рейкьявик'],
      Испания: ['Барселона', 'Мадрид'],
      Италия: ['Генуя', 'Милан', 'Палермо', 'Рим'],
      'Йеменская Республика': ['Аден', 'Сана'],
      'Кабо-Верде': ['Прайя'],
      Казахстан: ['Алма-Ата', 'Астана', 'Уральск', 'Усть-Каменогорск'],
      Камбоджа: ['Пномпень'],
      Камерун: ['Яунде'],
      Канада: ['Монреаль', 'Оттава', 'Торонто'],
      Катар: ['Доха'],
      Кения: ['Найроби'],
      Кипр: ['Лимасол', 'Никосия'],
      Киргизия: ['Бишкек', 'Ош'],
      Китай: ['Гонконг', 'Гуанчжоу', 'Пекин', 'Шанхай', 'Шэньян'],
      КНДР: ['Пхеньян', 'Чондин'],
      Колумбия: ['Богота'],
      'Конго, Демократическая Республика': ['Киншаса'],
      'Конго, Республика': ['Браззавиль'],
      'Коста-Рика': ['Сан-Хосе'],
      'Кот-д’Ивуар': ['Абиджан'],
      Куба: ['Гавана'],
      Кувейт: ['Эль-Кувейт'],
      Лаос: ['Вьентьян'],
      Латвия: ['Даугавпилс', 'Лиепая', 'Рига'],
      Ливан: ['Бейрут'],
      Ливия: ['Триполи'],
      Литва: ['Вильнюс'],
      Люксембург: ['Люксембург'],
      Маврикий: ['Порт-Луи'],
      Мавритания: ['Нуакшот'],
      Мадагаскар: ['Антананариву'],
      Малайзия: ['Куала-Лумпур'],
      Мали: ['Бамако'],
      Мальта: ['Валлета'],
      Марокко: ['Касабланка', 'Рабат'],
      Мексика: ['Мехико'],
      Мозамбик: ['Мапуту'],
      Молдавия: ['Кишинёв'],
      Монголия: ['Дархан', 'Улан-Батор', 'Эрдэнэт'],
      Мьянма: ['Янгон'],
      Намибия: ['Виндхук'],
      Непал: ['Катманду'],
      Нигерия: ['Абуджа', 'Лагос'],
      Нидерланды: ['Гаага'],
      Никарагуа: ['Манагуа'],
      'Новая Зеландия': ['Веллингтон'],
      Норвегия: ['Баренцбург', 'Киркенес', 'Осло'],
      ОАЭ: ['Абу-Даби', 'Дубай'],
      Оман: ['Маскат'],
      Пакистан: ['Исламабад', 'Карачи'],
      Палестина: ['Газа'],
      Панама: ['Панама'],
      Перу: ['Лима'],
      Польша: ['Варшава', 'Гданьск', 'Краков', 'Познань'],
      Португалия: ['Лиссабон'],
      Руанда: ['Кигали'],
      Румыния: ['Бухарест', 'Констанца'],
      'Саудовская Аравия': ['Джидда', 'Эр-Рияд'],
      'Северная Македония': ['Скопье'],
      'Сейшельские острова': ['Виктория'],
      Сенегал: ['Дакар'],
      Сербия: ['Белград'],
      Сингапур: ['Сингапур'],
      Сирия: ['Алеппо', 'Дамаск'],
      Словакия: ['Братислава'],
      Словения: ['Любляна'],
      Судан: ['Хартум'],
      США: ['Вашингтон', 'Нью-Йорк', 'Хьюстон'],
      Таджикистан: ['Душанбе', 'Худжанд'],
      Таиланд: ['Бангкок'],
      Тайвань: ['Тайбей'],
      Танзания: ['Дар-Эс-Салам'],
      Тунис: ['Тунис'],
      Туркменистан: ['Ашхабад', 'Туркменбаши'],
      Турция: ['Анкара', 'Анталья', 'Стамбул', 'Трабзон'],
      Уганда: ['Кампала'],
      Узбекистан: ['Ташкент'],
      Украина: ['Киев', 'Львов', 'Одесса', 'Харьков'],
      Уругвай: ['Монтевидео'],
      Филиппины: ['Манила'],
      Финляндия: ['Мариенхамн', 'Хельсинки'],
      Франция: ['Вильфранш-сюр-Мер', 'Марсель', 'Париж', 'Страсбург'],
      Хорватия: ['Загреб'],
      ЦАР: ['Банги'],
      Чад: ['Нджамена'],
      Черногория: ['Подгорица'],
      Чехия: ['Прага'],
      Чили: ['Сантьяго'],
      Швейцария: ['Берн', 'Женева'],
      Швеция: ['Стокгольм'],
      'Шри-Ланка': ['Коломбо'],
      Эквадор: ['Кито'],
      Эритрея: ['Асмара'],
      Эстония: ['Таллинн'],
      Эфиопия: ['Аддис-Абеба'],
      ЮАР: ['Кейптаун', 'Претория'],
      'Южная Корея': ['Пусан', 'Сеул'],
      Ямайка: ['Кингстон'],
      Япония: ['Ниигата', 'Осака', 'Саппоро', 'Токио', 'Хакодатэ'],
    },
    {
      Abkhazia: ['Sukhumi'],
      Australia: ['Canberra', 'Sydney'],
      Austria: ['Vienna', 'Salzburg'],
      Azerbaijan: ['Baku'],
      Albania: ['Tirana'],
      Algeria: ['Algiers', 'Annaba'],
      Angola: ['Luanda'],
      Argentina: ['Buenos Aires'],
      Armenia: ['Gyumri', 'Yerevan'],
      Afghanistan: ['Kabul', 'Mazar-i-Sharif'],
      Bangladesh: ['Dhaka', 'Chittagong'],
      Bahrain: ['Manama'],
      Belarus: ['Brest', 'Minsk'],
      Belgium: ['Brussels'],
      Benin: ['Cotonou'],
      Bulgaria: ['Sofia'],
      Bolivia: ['La Paz'],
      'Bosnia and Herzegovina': ['Sarajevo'],
      Botswana: ['Gaborone'],
      Brazil: ['Brasilia', 'Rio de Janeiro', 'Sao Paulo'],
      Brunei: ['Bandar Seri Begawan'],
      Burundi: ['Bujumbura'],
      Vatican: ['Vatican'],
      'United Kingdom': ['London', 'Edinburgh'],
      Hungary: ['Budapest', 'Debrecen'],
      Venezuela: ['Caracas'],
      Vietnam: ['Da Nang', 'Hanoi', 'Ho Chi Minh City'],
      Gabon: ['Libreville'],
      Guyana: ['Georgetown'],
      Ghana: ['Accra'],
      Guatemala: ['Guatemala City'],
      Guinea: ['Conakry'],
      'Guinea-Bissau': ['Bissau'],
      Germany: ['Berlin', 'Bonn'],
      Greece: ['Athens', 'Thessaloniki'],
      Georgia: ['Tbilisi'],
      Denmark: ['Copenhagen'],
      Djibouti: ['Djibouti'],
      Egypt: ['Alexandria', 'Cairo'],
      Zambia: ['Lusaka'],
      Zimbabwe: ['Harare'],
      Israel: ['Tel Aviv', 'Haifa'],
      India: ['Kolkata', 'Mumbai', 'New Delhi', 'Chennai'],
      Indonesia: ['Jakarta'],
      Jordan: ['Amman'],
      Iraq: ['Baghdad', 'Basra', 'Erbil'],
      Iran: ['Isfahan', 'Rasht', 'Tehran'],
      Ireland: ['Dublin'],
      Iceland: ['Reykjavik'],
      Spain: ['Barcelona', 'Madrid'],
      Italy: ['Genoa', 'Milan', 'Palermo', 'Rome'],
      Yemen: ['Aden', "Sana'a"],
      'Cape Verde': ['Praia'],
      Kazakhstan: ['Almaty', 'Astana', 'Oral', 'Oskemen'],
      Cambodia: ['Phnom Penh'],
      Cameroon: ['Yaounde'],
      Canada: ['Montreal', 'Ottawa', 'Toronto'],
      Qatar: ['Doha'],
      Kenya: ['Nairobi'],
      Cyprus: ['Limassol', 'Nicosia'],
      Kyrgyzstan: ['Bishkek', 'Osh'],
      China: ['Hong Kong', 'Guangzhou', 'Beijing', 'Shanghai', 'Shenyang'],
      'North Korea': ['Pyongyang', 'Chongjin'],
      Colombia: ['Bogota'],
      'Democratic Republic of the Congo': ['Kinshasa'],
      'Republic of the Congo': ['Brazzaville'],
      'Costa Rica': ['San Jose'],
      'Ivory Coast': ['Abidjan'],
      Cuba: ['Havana'],
      Kuwait: ['Kuwait City'],
      Laos: ['Vientiane'],
      Latvia: ['Daugavpils', 'Liepaja', 'Riga'],
      Lebanon: ['Beirut'],
      Libya: ['Tripoli'],
      Lithuania: ['Vilnius'],
      Luxembourg: ['Luxembourg'],
      Mauritius: ['Port Louis'],
      Mauritania: ['Nouakchott'],
      Madagascar: ['Antananarivo'],
      Malaysia: ['Kuala Lumpur'],
      Mali: ['Bamako'],
      Malta: ['Valletta'],
      Morocco: ['Casablanca', 'Rabat'],
      Mexico: ['Mexico City'],
      Mozambique: ['Maputo'],
      Moldova: ['Chisinau'],
      Mongolia: ['Darkhan', 'Ulaanbaatar', 'Erdenet'],
      Myanmar: ['Yangon'],
      Namibia: ['Windhoek'],
      Nepal: ['Kathmandu'],
      Nigeria: ['Abuja', 'Lagos'],
      Netherlands: ['The Hague'],
      Nicaragua: ['Managua'],
      'New Zealand': ['Wellington'],
      Norway: ['Barentsburg', 'Kirkenes', 'Oslo'],
      UAE: ['Abu Dhabi', 'Dubai'],
      Oman: ['Muscat'],
      Pakistan: ['Islamabad', 'Karachi'],
      Palestine: ['Gaza'],
      Panama: ['Panama City'],
      Peru: ['Lima'],
      Poland: ['Warsaw', 'Gdansk', 'Krakow', 'Poznan'],
      Portugal: ['Lisbon'],
      Rwanda: ['Kigali'],
      Romania: ['Bucharest', 'Constanta'],
      'Saudi Arabia': ['Jeddah', 'Riyadh'],
      'North Macedonia': ['Skopje'],
      Seychelles: ['Victoria'],
      Senegal: ['Dakar'],
      Serbia: ['Belgrade'],
      Singapore: ['Singapore'],
      Syria: ['Aleppo', 'Damascus'],
      Slovakia: ['Bratislava'],
      Slovenia: ['Ljubljana'],
      Sudan: ['Khartoum'],
      USA: ['Washington', 'New York', 'Houston'],
      Tajikistan: ['Dushanbe', 'Khujand'],
      Thailand: ['Bangkok'],
      Taiwan: ['Taipei'],
      Tanzania: ['Dar es Salaam'],
      Tunisia: ['Tunis'],
      Turkmenistan: ['Ashgabat', 'Turkmenbashi'],
      Turkey: ['Ankara', 'Antalya', 'Istanbul', 'Trabzon'],
      Uganda: ['Kampala'],
      Uzbekistan: ['Tashkent'],
      Ukraine: ['Kyiv', 'Lviv', 'Odessa', 'Kharkiv'],
      Uruguay: ['Montevideo'],
      Philippines: ['Manila'],
      Finland: ['Mariehamn', 'Helsinki'],
      France: ['Villefranche-sur-Mer', 'Marseille', 'Paris', 'Strasbourg'],
      Croatia: ['Zagreb'],
      CAR: ['Bangui'],
      Chad: ["N'Djamena"],
      Montenegro: ['Podgorica'],
      'Czech Republic': ['Prague'],
      Chile: ['Santiago'],
      Switzerland: ['Bern', 'Geneva'],
      Sweden: ['Stockholm'],
      'Sri Lanka': ['Colombo'],
      Ecuador: ['Quito'],
      Eritrea: ['Asmara'],
      Estonia: ['Tallinn'],
      Ethiopia: ['Addis Ababa'],
      'South Africa': ['Cape Town', 'Pretoria'],
      'South Korea': ['Busan', 'Seoul'],
      Jamaica: ['Kingston'],
      Japan: ['Niigata', 'Osaka', 'Sapporo', 'Tokyo', 'Hakodate'],
    },
    {
      阿布哈兹: ['苏呼米'],
      澳大利亚: ['堪培拉', '悉尼'],
      奥地利: ['维也纳', '萨尔茨堡'],
      阿塞拜疆: ['巴库'],
      阿尔巴尼亚: ['地拉那'],
      阿尔及利亚: ['阿尔及尔', '安纳巴'],
      安哥拉: ['罗安达'],
      阿根廷: ['布宜诺斯艾利斯'],
      亚美尼亚: ['久姆里', '埃里温'],
      阿富汗: ['喀布尔', '马扎里沙里夫'],
      孟加拉国: ['达卡', '吉大港'],
      巴林: ['麦纳麦'],
      白俄罗斯: ['布列斯特', '明斯克'],
      比利时: ['布鲁塞尔'],
      贝宁: ['科托努'],
      保加利亚: ['索非亚'],
      玻利维亚: ['拉巴斯'],
      波斯尼亚和黑塞哥维那: ['萨拉热窝'],
      博茨瓦纳: ['哈博罗内'],
      巴西: ['巴西利亚', '里约热内卢', '圣保罗'],
      文莱: ['斯里巴加湾市'],
      布隆迪: ['布琼布拉'],
      梵蒂冈: ['梵蒂冈'],
      英国: ['伦敦', '爱丁堡'],
      匈牙利: ['布达佩斯', '德布勒森'],
      委内瑞拉: ['加拉加斯'],
      越南: ['岘港', '河内', '胡志明市'],
      加蓬: ['利伯维尔'],
      圭亚那: ['乔治敦'],
      加纳: ['阿克拉'],
      危地马拉: ['危地马拉城'],
      几内亚: ['科纳克里'],
      几内亚比绍: ['比绍'],
      德国: ['柏林', '波恩'],
      希腊: ['雅典', '塞萨洛尼基'],
      格鲁吉亚: ['第比利斯'],
      丹麦: ['哥本哈根'],
      吉布提: ['吉布提'],
      埃及: ['亚历山大', '开罗'],
      赞比亚: ['卢萨卡'],
      津巴布韦: ['哈拉雷'],
      以色列: ['特拉维夫', '海法'],
      印度: ['加尔各答', '孟买', '新德里', '金奈'],
      印度尼西亚: ['雅加达'],
      约旦: ['安曼'],
      伊拉克: ['巴格达', '巴士拉', '埃尔比勒'],
      伊朗: ['伊斯法罕', '拉什特', '德黑兰'],
      爱尔兰: ['都柏林'],
      冰岛: ['雷克雅未克'],
      西班牙: ['巴塞罗那', '马德里'],
      意大利: ['热那亚', '米兰', '巴勒莫', '罗马'],
      也门: ['亚丁', '萨那'],
      佛得角: ['普拉亚'],
      哈萨克斯坦: ['阿拉木图', '阿斯塔纳', '乌拉尔', '乌斯季卡缅诺戈尔斯克'],
      柬埔寨: ['金边'],
      喀麦隆: ['雅温得'],
      加拿大: ['蒙特利尔', '渥太华', '多伦多'],
      卡塔尔: ['多哈'],
      肯尼亚: ['内罗毕'],
      塞浦路斯: ['利马索尔', '尼科西亚'],
      吉尔吉斯斯坦: ['比什凯克', '奥什'],
      中国: ['香港', '广州', '北京', '上海', '沈阳'],
      朝鲜: ['平壤', '清津'],
      哥伦比亚: ['波哥大'],
      刚果民主共和国: ['金沙萨'],
      刚果共和国: ['布拉柴维尔'],
      哥斯达黎加: ['圣何塞'],
      科特迪瓦: ['阿比让'],
      古巴: ['哈瓦那'],
      科威特: ['科威特城'],
      老挝: ['万象'],
      拉脱维亚: ['陶格夫匹尔斯', '利耶帕亚', '里加'],
      黎巴嫩: ['贝鲁特'],
      利比亚: ['的黎波里'],
      立陶宛: ['维尔纽斯'],
      卢森堡: ['卢森堡'],
      毛里求斯: ['路易港'],
      毛里塔尼亚: ['努瓦克肖特'],
      马达加斯加: ['塔那那利佛'],
      马来西亚: ['吉隆坡'],
      马里: ['巴马科'],
      马耳他: ['瓦莱塔'],
      摩洛哥: ['卡萨布兰卡', '拉巴特'],
      墨西哥: ['墨西哥城'],
      莫桑比克: ['马普托'],
      摩尔多瓦: ['基希讷乌'],
      蒙古: ['达尔汗', '乌兰巴托', '额尔登特'],
      缅甸: ['仰光'],
      纳米比亚: ['温得和克'],
      尼泊尔: ['加德满都'],
      尼日利亚: ['阿布贾', '拉各斯'],
      荷兰: ['海牙'],
      尼加拉瓜: ['马那瓜'],
      新西兰: ['惠灵顿'],
      挪威: ['巴伦支堡', '希尔克内斯', '奥斯陆'],
      阿联酋: ['阿布扎比', '迪拜'],
      阿曼: ['马斯喀特'],
      巴基斯坦: ['伊斯兰堡', '卡拉奇'],
      巴勒斯坦: ['加沙'],
      巴拿马: ['巴拿马城'],
      秘鲁: ['利马'],
      波兰: ['华沙', '格但斯克', '克拉科夫', '波兹南'],
      葡萄牙: ['里斯本'],
      卢旺达: ['基加利'],
      罗马尼亚: ['布加勒斯特', '康斯坦察'],
      沙特阿拉伯: ['吉达', '利雅得'],
      北马其顿: ['斯科普里'],
      塞舌尔: ['维多利亚'],
      塞内加尔: ['达喀尔'],
      塞尔维亚: ['贝尔格莱德'],
      新加坡: ['新加坡'],
      叙利亚: ['阿勒颇', '大马士革'],
      斯洛伐克: ['布拉迪斯拉发'],
      斯洛文尼亚: ['卢布尔雅那'],
      苏丹: ['喀土穆'],
      美国: ['华盛顿', '纽约', '休斯顿'],
      塔吉克斯坦: ['杜尚别', '苦盏'],
      泰国: ['曼谷'],
      台湾: ['台北'],
      坦桑尼亚: ['达累斯萨拉姆'],
      突尼斯: ['突尼斯'],
      土库曼斯坦: ['阿什哈巴德', '土库曼巴希'],
      土耳其: ['安卡拉', '安塔利亚', '伊斯坦布尔', '特拉布宗'],
      乌干达: ['坎帕拉'],
      乌兹别克斯坦: ['塔什干'],
      乌克兰: ['基辅', '利沃夫', '敖德萨', '哈尔科夫'],
      乌拉圭: ['蒙得维的亚'],
      菲律宾: ['马尼拉'],
      芬兰: ['玛丽港', '赫尔辛基'],
      法国: ['维尔弗朗什', '马赛', '巴黎', '斯特拉斯堡'],
      克罗地亚: ['萨格勒布'],
      中非共和国: ['班吉'],
      乍得: ['恩贾梅纳'],
      黑山: ['波德戈里察'],
      捷克共和国: ['布拉格'],
      智利: ['圣地亚哥'],
      瑞士: ['伯尔尼', '日内瓦'],
      瑞典: ['斯德哥尔摩'],
      斯里兰卡: ['科伦坡'],
      厄瓜多尔: ['基多'],
      厄立特里亚: ['阿斯马拉'],
      爱沙尼亚: ['塔林'],
      埃塞俄比亚: ['亚的斯亚贝巴'],
      南非: ['开普敦', '比勒陀利亚'],
      韩国: ['釜山', '首尔'],
      牙买加: ['金斯敦'],
      日本: ['新泻', '大阪', '札幌', '东京', '函馆'],
    },
    {
      Abjasia: ['Sujumi'],
      Australia: ['Canberra', 'Sídney'],
      Austria: ['Viena', 'Salzburgo'],
      Azerbaiyán: ['Bakú'],
      Albania: ['Tirana'],
      Argelia: ['Argel', 'Annaba'],
      Angola: ['Luanda'],
      Argentina: ['Buenos Aires'],
      Armenia: ['Guiumri', 'Ereván'],
      Afganistán: ['Kabul', 'Mazar-e Sharif'],
      Bangladés: ['Daca', 'Chittagong'],
      Baréin: ['Manama'],
      Bielorrusia: ['Brest', 'Minsk'],
      Bélgica: ['Bruselas'],
      Benín: ['Cotonú'],
      Bulgaria: ['Sofía'],
      Bolivia: ['La Paz'],
      'Bosnia y Herzegovina': ['Sarajevo'],
      Botsuana: ['Gaborone'],
      Brasil: ['Brasilia', 'Río de Janeiro', 'São Paulo'],
      Brunéi: ['Bandar Seri Begawan'],
      Burundi: ['Buyumbura'],
      'Ciudad del Vaticano': ['Vaticano'],
      'Reino Unido': ['Londres', 'Edimburgo'],
      Hungría: ['Budapest', 'Debrecen'],
      Venezuela: ['Caracas'],
      Vietnam: ['Da Nang', 'Hanoi', 'Ciudad Ho Chi Minh'],
      Gabón: ['Libreville'],
      Guyana: ['Georgetown'],
      Ghana: ['Acra'],
      Guatemala: ['Ciudad de Guatemala'],
      Guinea: ['Conakri'],
      'Guinea-Bisáu': ['Bisáu'],
      Alemania: ['Berlín', 'Bonn'],
      Grecia: ['Atenas', 'Salónica'],
      Georgia: ['Tiflis'],
      Dinamarca: ['Copenhague'],
      Yibuti: ['Yibuti'],
      Egipto: ['Alejandría', 'El Cairo'],
      Zambia: ['Lusaka'],
      Zimbabue: ['Harare'],
      Israel: ['Tel Aviv', 'Haifa'],
      India: ['Calcuta', 'Bombay', 'Nueva Delhi', 'Chennai'],
      Indonesia: ['Yakarta'],
      Jordania: ['Ammán'],
      Irak: ['Bagdad', 'Basora', 'Erbil'],
      Irán: ['Isfahán', 'Rasht', 'Teherán'],
      Irlanda: ['Dublín'],
      Islandia: ['Reikiavik'],
      España: ['Barcelona', 'Madrid'],
      Italia: ['Génova', 'Milán', 'Palermo', 'Roma'],
      Yemen: ['Adén', 'Saná'],
      'Cabo Verde': ['Praia'],
      Kazajistán: ['Almaty', 'Astana', 'Oral', 'Oskemen'],
      Camboya: ['Nom Pen'],
      Camerún: ['Yaundé'],
      Canadá: ['Montreal', 'Ottawa', 'Toronto'],
      Catar: ['Doha'],
      Kenia: ['Nairobi'],
      Chipre: ['Limassol', 'Nicosia'],
      Kirguistán: ['Biskek', 'Osh'],
      China: ['Hong Kong', 'Cantón', 'Pekín', 'Shanghái', 'Shenyang'],
      'Corea del Norte': ['Pionyang', 'Chongjin'],
      Colombia: ['Bogotá'],
      'República Democrática del Congo': ['Kinshasa'],
      'República del Congo': ['Brazzaville'],
      'Costa Rica': ['San José'],
      'Costa de Marfil': ['Abiyán'],
      Cuba: ['La Habana'],
      Kuwait: ['Ciudad de Kuwait'],
      Laos: ['Vientián'],
      Letonia: ['Daugavpils', 'Liepaja', 'Riga'],
      Líbano: ['Beirut'],
      Libia: ['Trípoli'],
      Lituania: ['Vilna'],
      Luxemburgo: ['Luxemburgo'],
      Mauricio: ['Puerto Luis'],
      Mauritania: ['Nuakchot'],
      Madagascar: ['Antananarivo'],
      Malasia: ['Kuala Lumpur'],
      Malí: ['Bamako'],
      Malta: ['La Valeta'],
      Marruecos: ['Casablanca', 'Rabat'],
      México: ['Ciudad de México'],
      Mozambique: ['Maputo'],
      Moldavia: ['Chisináu'],
      Mongolia: ['Darkhan', 'Ulán Bator', 'Erdenet'],
      Myanmar: ['Yangón'],
      Namibia: ['Windhoek'],
      Nepal: ['Katmandú'],
      Nigeria: ['Abuya', 'Lagos'],
      'Países Bajos': ['La Haya'],
      Nicaragua: ['Managua'],
      'Nueva Zelanda': ['Wellington'],
      Noruega: ['Barentsburgo', 'Kirkenes', 'Oslo'],
      EAU: ['Abu Dabi', 'Dubái'],
      Omán: ['Mascate'],
      Pakistán: ['Islamabad', 'Karachi'],
      Palestina: ['Gaza'],
      Panamá: ['Ciudad de Panamá'],
      Perú: ['Lima'],
      Polonia: ['Varsovia', 'Gdansk', 'Cracovia', 'Poznan'],
      Portugal: ['Lisboa'],
      Ruanda: ['Kigali'],
      Rumanía: ['Bucarest', 'Constanza'],
      'Arabia Saudita': ['Yeda', 'Riad'],
      'Macedonia del Norte': ['Skopie'],
      Seychelles: ['Victoria'],
      Senegal: ['Dakar'],
      Serbia: ['Belgrado'],
      Singapur: ['Singapur'],
      Siria: ['Alepo', 'Damasco'],
      Eslovaquia: ['Bratislava'],
      Eslovenia: ['Liubliana'],
      Sudán: ['Jartum'],
      EEUU: ['Washington', 'Nueva York', 'Houston'],
      Tayikistán: ['Dusambé', 'Juyand'],
      Tailandia: ['Bangkok'],
      Taiwán: ['Taipéi'],
      Tanzania: ['Dar es Salaam'],
      Túnez: ['Túnez'],
      Turkmenistán: ['Asjabad', 'Turkmenbashi'],
      Turquía: ['Ankara', 'Antalya', 'Estambul', 'Trebisonda'],
      Uganda: ['Kampala'],
      Uzbekistán: ['Taskent'],
      Ucrania: ['Kiev', 'Leópolis', 'Odesa', 'Járkov'],
      Uruguay: ['Montevideo'],
      Filipinas: ['Manila'],
      Finlandia: ['Mariehamn', 'Helsinki'],
      Francia: ['Villefranche-sur-Mer', 'Marsella', 'París', 'Estrasburgo'],
      Croacia: ['Zagreb'],
      'República Centroafricana': ['Bangui'],
      Chad: ['Yamena'],
      Montenegro: ['Podgorica'],
      'República Checa': ['Praga'],
      Chile: ['Santiago'],
      Suiza: ['Berna', 'Ginebra'],
      Suecia: ['Estocolmo'],
      'Sri Lanka': ['Colombo'],
      Ecuador: ['Quito'],
      Eritrea: ['Asmara'],
      Estonia: ['Tallin'],
      Etiopía: ['Adís Abeba'],
      Sudáfrica: ['Ciudad del Cabo', 'Pretoria'],
      'Corea del Sur': ['Busán', 'Seúl'],
      Jamaica: ['Kingston'],
      Japón: ['Niigata', 'Osaka', 'Sapporo', 'Tokio', 'Hakodate'],
    },
  ],
  wantAnAppointment: [
    'Хотите, чтобы мы вас сами записали на приём в консульство?',
    'Would you like us to schedule an appointment for you at the consulate?',
    '您是否希望我们为您安排领事馆的预约？',
    '¿Le gustaría que le programáramos una cita en el consulado?',
  ],
  yes: ['Да', 'Yes', '是', 'Sí'],
  iDoItMyself: [
    'Нет, я запишусь сам(a)',
    'No, I will schedule it myself.',
    '不，我自己安排预约',
    'No, lo programaré yo mismo.',
  ],
  forward: ['Далее', 'Forward', '下一步', 'Siguiente'],
  payment: ['Оплата', 'Payment', '付款', 'Pago'],
  paymentText: [
    'Вы можете оплатить услугу сейчас или после консультации с юристом',
    'You can pay for the service now or after consulting with the lawyer',
    '您可以现在付款，或在与律师咨询后付款',
    'Puede pagar por el servicio ahora o después de consultar con el abogado',
  ],
  payLater: [
    'Оформить заявку, оплатить позже',
    'Submit an application, pay later',
    '提交申请，稍后付款',
    'Enviar una solicitud, pagar después',
  ],
  payNow: ['Оплатить сейчас', 'Pay now', '立即付款', 'Pagar ahora'],
  sendRequest: [
    'Отправить заявку',
    'Send request',
    '发送申请',
    'Enviar solicitud',
  ],
  saveRequest: [
    'Сохранить в черновики',
    'Save as draft',
    '保存为草稿',
    'Guardar como borrador',
  ],
  selectedPrice: [
    'Выбранный тариф:',
    'The selected tariff:',
    '选择的费率:',
    'La tarifa seleccionada:',
  ],
  withAppointment: [
    'С записью в консульство',
    'With appointment in consulate',
    '包含领事馆预约',
    'Con cita en el consulado',
  ],
  withoutAppointment: [
    'Без записи в консульство',
    'Without appointment in consulate',
    '不包含领事馆预约',
    'Sin cita en el consulado',
  ],
  amount: ['Сумма к оплате:', 'Amount to pay:', '支付金额:', 'Monto a pagar:'],
  paymentVariant: [
    'Выберите способ оплаты:',
    'Choose the payment method:',
    '选择支付方式:',
    'Elija el método de pago:',
  ],
  card: [
    'Международная банковская карта',
    'Pay with card',
    '国际银行卡支付',
    'Pagar con tarjeta',
  ],
  paymentDetail: [
    'Оплата российскими банковскими картами, с использованием системы PayPal или в криптовалюте на сайте не производится, но возможна по запросу.',
    'Payment by Russian bank cards, using PayPal, or in cryptocurrency is not available on the website, but can be arranged upon request.',
    '网站不支持俄罗斯银行卡、PayPal或加密货币支付，但可根据请求安排。',
    'El pago con tarjetas bancarias rusas, PayPal o criptomonedas no está disponible en el sitio, pero se puede arreglar bajo petición.',
  ],
  goToPayment: [
    'Перейти к оплате',
    'Proceed to payment',
    '前往付款',
    'Ir al pago',
  ],
  applicationSubmitted: [
    'Заявка успешно отправлена!',
    'The application has been successfully submitted!',
    '申请已成功提交！',
    '¡La solicitud se ha enviado con éxito!',
  ],
  applicationSubmittedText1: [
    'В ближайшее время наши юристы свяжутся с вами по указанному вами номеру телефона.',
    'Our lawyers will contact you soon at the phone number you provided.',
    '我们的律师将很快通过您提供的电话号码与您联系。',
    'Nuestros abogados se pondrán en contacto con usted pronto en el número de teléfono que proporcionó.',
  ],
  applicationSubmittedText2: [
    'Информация с логином и паролем к вашему личному кабинету отправлена на указанную вами почту.',
    'The information with the login and password to your personal account has been sent to the email you provided.',
    '登录信息和密码已发送到您提供的电子邮件。',
    'La información con el inicio de sesión y la contraseña de su cuenta personal se ha enviado al correo electrónico que proporcionó.',
  ],
  applicationSubmittedText3: [
    'Хорошего дня!',
    'Have a good day!',
    '祝您愉快！',
    '¡Que tenga un buen día!',
  ],
  perfect: ['Отлично!', 'Perfect!', '完美！', '¡Perfecto!'],
  autorizeToFollow: [
    'Авторизируйтесь для того, чтобы продолжить',
    'Log in to continue',
    '登录以继续',
    'Inicie sesión para continuar',
  ],
  nameISRequired: [
    'Укажите, пожалуйста, имя',
    'Name is required',
    '请填写姓名',
    'El nombre es obligatorio',
  ],
  phoneNumberIsRequired: [
    'Укажите телефон',
    'Phone number is required',
    '请填写电话',
    'El teléfono es obligatorio',
  ],
  invalidPhoneNumberFormat: [
    'Неверный формат телефона',
    'Invalid phone number format',
    '电话号码格式无效',
    'Formato de teléfono no válido',
  ],
  emailIsRequired: [
    'Укажите электронную почту',
    'Email is required',
    '请填写电子邮件',
    'El correo electrónico es obligatorio',
  ],
  invalidEmailAddress: [
    'Неверный адрес электронной почты',
    'Invalid email address',
    '电子邮件地址无效',
    'Dirección de correo electrónico no válida',
  ],
  required: ['Обязательное поле', 'Required', '必填字段', 'Campo obligatorio'],
  paymentMade1: ['Платеж был ', 'Payment was', '付款已', 'El pago fue'],
  paymentMade2: [
    'успешно выполнен.',
    'successfully made.',
    '成功完成。',
    'realizado con éxito.',
  ],
  paymentMade3: [
    'Спасибо за оплату наших услуг!',
    'Thank you for paying for our services!',
    '感谢您支付我们的服务费用！',
    '¡Gracias por pagar por nuestros servicios!',
  ],
}
export default vocabularyWorkflow

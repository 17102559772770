import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'
import vocabulary from './vocabulary'

export const passportVariants = [
  [
    'Нового поколения сроком на 10 лет',
    'Next generation with a duration of 10 years.',
    '新一代，有效期10年。',
    'Nueva generación con una duración de 10 años.',
  ],
  [
    'Старого образца сроком на 5 лет',
    'Old variant with a duration of 5 years.',
    '旧版，有效期5年。',
    'Antiguo modelo con una duración de 5 años.',
  ],
]

export const whoNeedsPassportOptions = [
  [
    'Мне (физ лицо старше 18 лет)',
    'Me (an individual over 18 years old)',
    '我（18岁以上的个人）',
    'Yo (un individuo mayor de 18 años)',
  ],
  [
    'Ребёнку младше 14 лет',
    'Child under 14 years old',
    '14岁以下的儿童',
    'Niño menor de 14 años',
  ],
  [
    'Ребёнку возрастом от 14 до 18 лет',
    'Child aged 14 to 18 years old',
    '14到18岁的儿童',
    'Niño de 14 a 18 años',
  ],
]

const binaryOptions = [vocabulary.yes, vocabulary.no]

const vocabularyPassport = {
  passport: [
    'загранпаспорт',
    'Passport (foreign)',
    '护照',
    'Pasaporte (extranjero)',
  ],
  info: ['Инфо', 'Info', '信息', 'Información'],
  documents: ['Документы', 'Documents', '文件', 'Documentos'],
  prices: ['Тарифы', 'Prices', '价格', 'Precios'],
  questions: ['Вопросы', 'Questions', '问题', 'Preguntas'],
  commonInformation: [
    'Общая информация',
    'Common Information',
    '常见信息',
    'Información común',
  ],
  requiredDocuments: [
    'Какие документы потребуются?',
    'What documents will be required?',
    '需要哪些文件？',
    '¿Qué documentos serán necesarios?',
  ],
  priceAndTariffs: [
    'ТАРИФЫ И ЦЕНЫ',
    'TARIFFS AND PRICES',
    '费用和价格',
    'TARIFAS Y PRECIOS',
  ],
  faq: [
    'Часто задаваемые вопросы',
    'Frequently Asked Questions',
    '常见问题',
    'Preguntas frecuentes',
  ],
  mainInf1: [
    'В консульстве или посольстве России возможно оформить Российский загранпаспорт сроком на 5 или 10 лет.',
    'At the consulate or embassy of Russia, it is possible to obtain a Russian passport valid for 5 or 10 years.',
    '在俄罗斯的领事馆或大使馆，可以申请有效期为5或10年的俄罗斯护照。',
    'En el consulado o embajada de Rusia, es posible obtener un pasaporte ruso válido por 5 o 10 años.',
  ],
  readDetails: [
    'Читать подробнее, чем отличаются',
    'Read more about how they differ',
    '阅读更多关于它们的区别',
    'Leer más sobre cómo se diferencian',
  ],
  mainInf2: [
    'На консульском портале в нужном формате мы подготовим за вас заявление на получение загранпаспорта, проконсультируем по перечню документов, которые необходимо взять с собой в консульство, при необходимости окажем помощь в записи на приём в консульство.',
    'On the consulate portal, we will prepare an application for obtaining a passport in the required format, provide consultation on the list of documents you have to bring to the consulate, and if necessary, assist you in scheduling an appointment at the consulate.',
    '在领事馆网站上，我们将为您准备申请护照的必要格式，提供有关您必须带到领事馆的文件列表的咨询，并在必要时协助您预约。',
    'En el portal consular, prepararemos una solicitud para obtener un pasaporte en el formato requerido, proporcionaremos consulta sobre la lista de documentos que debe llevar al consulado y, si es necesario, le ayudaremos a programar una cita en el consulado.',
  ],
  times: [
    'Сроки оформления',
    'Processing Times',
    '处理时间',
    'Tiempos de procesamiento',
  ],
  oficialProcessingTimes: [
    'Официальные сроки оформления паспорта с момента подачи документов в консульство:',
    'The official processing times for a passport from the moment documents are submitted to the consulate:',
    '护照从提交文件到领事馆之时的官方处理时间：',
    'Los tiempos de procesamiento oficiales para un pasaporte desde el momento en que se presentan los documentos al consulado:',
  ],
  oficialProcessingTimesList: [
    [
      '1 месяц — загранпаспорт старого образца (сроком на 5 лет) детям до 14 лет',
      '1 month - old-style passport (valid for 5 years) for children under 14',
      '1个月 — 旧版护照（有效期5年）适用于14岁以下儿童',
      '1 mes - pasaporte antiguo (válido por 5 años) para niños menores de 14 años',
    ],
    [
      '2 месяца — загранпаспорт старого образца (сроком на 5 лет) совершеннолетним',
      '2 months - old-style passport (valid for 5 years) for adults',
      '2个月 — 旧版护照（有效期5年）适用于成年人',
      '2 meses - pasaporte antiguo (válido por 5 años) para adultos',
    ],
    [
      '3 месяца — загранпаспорт нового поколения (сроком на 10 лет)',
      '3 months - new-generation passport (valid for 10 years)',
      '3个月 — 新一代护照（有效期10年）',
      '3 meses - pasaporte de nueva generación (válido por 10 años)',
    ],
  ],
  processingTimeText1: [
    'Однако на данный момент по независящим от консульств / посольств причинам сроки оформления загранпаспорта могут быть увеличены до полугода и более. Возможные задержки связаны с нерегулярным характером авиасообщения между странами.',
    'However, at the moment, due to reasons beyond the control of consulates/embassies, the processing time for a passport can be extended to six months or more. Possible delays are associated with the irregular nature of air travel between countries.',
    '然而，目前由于不受领事馆/大使馆控制的原因，护照的处理时间可能延长至六个月或更长时间。可能的延误与国家之间不规律的航班有关。',
    'Sin embargo, en este momento, debido a razones fuera del control de los consulados/embajadas, el tiempo de procesamiento de un pasaporte puede extenderse a seis meses o más. Los posibles retrasos están asociados con la naturaleza irregular del transporte aéreo entre países.',
  ],
  processingTimeText2: [
    'Данные сроки в свою очередь не учитывают время, которое может занять сам процесс записи на подачу документов. Поэтому рекомендуем начинать заниматься вопросом замены истекающего загранпаспорта заблаговременно.',
    'These deadlines do not take into account the time it may take to schedule an appointment to submit the documents. Therefore, we recommend starting the process of replacing an expiring passport well in advance.',
    '这些截止日期未考虑安排提交文件的预约可能需要的时间。因此，建议您提前开始护照更换的过程。',
    'Estos plazos no tienen en cuenta el tiempo que puede llevar programar una cita para presentar los documentos. Por lo tanto, recomendamos comenzar el proceso de reemplazo de un pasaporte que está a punto de caducar con suficiente antelación.',
  ],
  passportProcess: [
    'Процесс подачи на оформление загранпаспорта',
    'The process of applying for a foreign passport',
    '申请护照的过程',
    'El proceso de solicitud de un pasaporte extranjero',
  ],
  next: ['Далее', 'Next', '下一步', 'Siguiente'],
  necessaryDoc: [
    'Требуемые документы',
    'Necessary documents',
    '所需文件',
    'Documentos necesarios',
  ],
  necessaryDocText1: [
    'Комплекты документов, представляемые в консульство / посольство России на оформление паспортов также зависят от возраста заявителя и от имеющихся у него документов:',
    "The sets of documents submitted to the Russian consulate/embassy for a new passport also depend on the applicant's age and the documents they possess.",
    '提交给俄罗斯领事馆/大使馆的新护照所需的文件套件也取决于申请人的年龄和他们持有的文件。',
    'Los conjuntos de documentos presentados al consulado/embajada rusa para un nuevo pasaporte también dependen de la edad del solicitante y de los documentos que posee.',
  ],

  userTypes: [
    {
      userName: [
        'Лица старше 18 лет',
        'Individuals over 18 years old',
        '18岁以上个人',
        'Individuos mayores de 18 años',
      ],
      desc: [
        [
          'Заявление на оформление загранпаспорта',
          'Application for passport application',
          '护照申请表',
          'Solicitud de pasaporte',
        ],
        [
          'Загранпаспорт и/или внутренний паспорт РФ. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
          'Russian passport and/or Russian internal passport. If you have both passports, you need to bring two passports to the consulate appointment.',
          '俄罗斯护照和/或俄罗斯内部护照。如果您有两个护照，您需要在领事馆预约时携带两个护照。',
          'Pasaporte ruso y/o pasaporte interno ruso. Si tiene ambos pasaportes, debe traer dos pasaportes a la cita consular.',
        ],
        [
          'В случае смены ФИО, документ подтверждающий данный факт (свидетельство о заключении брака, перемены имени, о расторжении брака и т.д.). Если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык',
          'In case of a change in the full name, a document confirming this fact (marriage certificate, name change, divorce, etc.) is required. If the document is of foreign origin, it must be apostilled and notarized with a translation into Russian.',
          '如果更改全名，需要提供确认这一事实的文件（结婚证、改名证明、离婚证等）。如果文件是外国文件，则必须附有公证书和翻译成俄文的公证翻译。',
          'En caso de un cambio en el nombre completo, se requiere un documento que confirme este hecho (certificado de matrimonio, cambio de nombre, divorcio, etc.). Si el documento es de origen extranjero, debe estar apostillado y notariado con una traducción al ruso.',
        ],
        [
          'В случае если вы оформляете паспорт старого образца (сроком на 5 лет) вам необходимо заранее подготовить 4 фотографии 3,5 х 4,5 см',
          'If you are applying for an old-style passport (valid for 5 years), you need to prepare 4 photos sized 3.5 x 4.5 cm in advance.',
          '如果您申请旧版护照（有效期为5年），则需要提前准备4张3.5 x 4.5厘米的照片。',
          'Si solicita un pasaporte antiguo (válido por 5 años), debe preparar 4 fotos de 3.5 x 4.5 cm con anticipación.',
        ],
      ],
      ps: [
        'Смотреть требования к фотографии',
        'View photo requirements',
        '查看照片要求',
        'Ver requisitos de fotos',
      ],
    },

    {
      userName: [
        'Ребёнок младше 14 лет',
        'Child under 14 years old',
        '14岁以下儿童',
        'Niño menor de 14 años',
      ],
      desc: [
        [
          'Заявление на оформление загранпаспорта ребёнка, заполненное от имени родителя (иного законного представителя)',
          'Application for child passport application filled out on behalf of the parent (other legal representative)',
          '为孩子申请护照的申请表，由父母（其他法定代表人）填写',
          'Solicitud de pasaporte para niño completada en nombre del padre (otro representante legal)',
        ],
        [
          'Загранпаспорт и/или внутренний паспорт родителя (иного законного представителя)',
          'Passport and/or national passport of the parent (other legal representative)',
          '父母（其他法定代表人）的护照和/或国家护照',
          'Pasaporte y/o pasaporte nacional del padre (otro representante legal)',
        ],
        [
          'Загранпаспорт ребёнка (если это повторное оформление)',
          'Child passport (if it is a renewal)',
          '儿童护照（如果是续签）',
          'Pasaporte infantil (si es una renovación)',
        ],
        [
          'Свидетельство о рождении ребёнка, содержащее отметку (или вкладыш) о принадлежности ребёнка к гражданству РФ. Если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык',
          "Child's birth certificate containing a note (or insert) on the child's citizenship of the Russian Federation. If the document is of foreign origin, it must be apostilled and notarized with a translation into Russian.",
          '儿童的出生证明，包含有关儿童属于俄罗斯联邦国籍的注释（或插入）。如果文件是外国文件，则必须附有公证书和翻译成俄文的公证翻译。',
          'El certificado de nacimiento del niño que contenga una nota (o inserción) sobre la ciudadanía del niño de la Federación Rusa. Si el documento es de origen extranjero, debe estar apostillado y notariado con una traducción al ruso.',
        ],
        [
          'Иные документы, подтверждающие родство (если из свидетельства о рождении чётко не прослеживаются родственные отношения) заявителя и ребёнка или полномочия законного представителя',
          'Other documents confirming the relationship (if the birth certificate does not clearly show the relationship) between the applicant and the child or the authority of the legal representative',
          '其他文件，确认申请人与儿童之间的关系（如果出生证明未明确显示关系）或法定代表人的权威',
          'Otros documentos que confirmen la relación (si el certificado de nacimiento no muestra claramente la relación) entre el solicitante y el niño o la autoridad del representante legal.',
        ],
        [
          'В случае если вы оформляете паспорт старого образца (сроком на 5 лет) вам необходимо заранее подготовить 4 фотографии 3,5 х 4,5 см',
          'If you are applying for an old-style passport (valid for 5 years), you need to prepare 4 photos sized 3.5 x 4.5 cm in advance',
          '如果您申请旧版护照（有效期为5年），则需要提前准备4张3.5 x 4.5厘米的照片。',
          'Si solicita un pasaporte antiguo (válido por 5 años), debe preparar 4 fotos de 3.5 x 4.5 cm con anticipación.',
        ],
        [
          'Загранпаспорт для ребёнка можно оформлять начиная с его рождения, однако в связи с тем, что внешность ребенка быстро меняется, до 6 лет рекомендуется оформлять паспорт только старого образца сроком на 5 лет.',
          "A child's passport can be issued starting from their birth, however, due to the fact that a child's appearance changes quickly, it is recommended to issue only an old-style passport valid for 5 years until the age of 6.",
          '儿童护照可以从出生时开始办理，但由于儿童外貌变化迅速，建议在6岁之前只办理有效期为5年的旧版护照。',
          'El pasaporte del niño puede emitirse a partir de su nacimiento, sin embargo, debido a que la apariencia del niño cambia rápidamente, se recomienda emitir solo un pasaporte antiguo válido por 5 años hasta los 6 años.',
        ],
      ],
      comments: [
        [
          'Загранпаспорт для ребёнка можно оформлять начиная с его рождения, однако в связи с тем, что внешность ребенка быстро меняется, до 6 лет рекомендуется оформлять паспорт только старого образца сроком на 5 лет.',
          '',
          '儿童护照可以从出生时开始办理，但由于儿童外貌变化迅速，建议在6岁之前只办理有效期为5年的旧版护照。',
          'El pasaporte del niño puede emitirse a partir de su nacimiento, sin embargo, debido a que la apariencia del niño cambia rápidamente, se recomienda emitir solo un pasaporte antiguo válido por 5 años hasta los 6 años.',
        ],
        [
          'Присутствие ребёнка при подаче документов обязательно, при получении готового загранпаспорта — уже не требуется.',
          'The presence of the child is mandatory when submitting documents, it is not required when receiving the ready passport.',
          '提交文件时，儿童必须到场，领取护照时不必到场。',
          'La presencia del niño es obligatoria al presentar documentos, no se requiere al recibir el pasaporte listo.',
        ],
      ],
      ps: [
        'Смотреть требования к фотографии',
        'View photo requirements',
        '查看照片要求',
        'Ver requisitos de fotos',
      ],
    },
    {
      userName: [
        'Ребёнок возрастом от 14 до 18 лет',
        'Teenager aged 14 to 18 years old',
        '14至18岁青少年',
        'Adolescente de 14 a 18 años',
      ],
      desc: [
        [
          'Заявление на оформление загранпаспорта ребенка, заполненное от имени родителя (иного законного представителя)',
          'Application for child passport application filled out on behalf of the parent (other legal representative)',
          '为孩子申请护照的申请表，由父母（其他法定代表人）填写',
          'Solicitud de pasaporte para niño completada en nombre del padre (otro representante legal)',
        ],
        [
          'Загранпаспорт и/или внутренний паспорт родителя (иного законного представителя)',
          'Passport and/or national passport of the parent (other legal representative)',
          '父母（其他法定代表人）的护照和/或国家护照',
          'Pasaporte y/o pasaporte nacional del padre (otro representante legal)',
        ],
        [
          'Загранпаспорт и/или внутренний паспорт ребенка',
          'Passport and/or national passport of the child',
          '儿童的护照和/或国家护照',
          'Pasaporte y/o pasaporte nacional del niño',
        ],
        [
          'Документы, подтверждающие родство заявителя и ребенка (свидетельство о рождении, о браке, о перемене имени и другие) или полномочия законного представителя. Если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык',
          'Documents confirming the relationship between the applicant and the child (birth certificate, marriage certificate, name change certificate, and others) or the authority of the legal representative. If the document is of foreign origin, it must be apostilled and notarized with a translation into Russian',
          '确认申请人与儿童之间关系的文件（出生证明、结婚证明、改名证明等）或法定代表人的权威。如果文件是外国文件，则必须附有公证书和翻译成俄文的公证翻译。',
          'Documentos que confirmen la relación entre el solicitante y el niño (certificado de nacimiento, certificado de matrimonio, certificado de cambio de nombre y otros) o la autoridad del representante legal. Si el documento es de origen extranjero, debe estar apostillado y notariado con una traducción al ruso.',
        ],
        [
          'В случае если вы оформляете паспорт старого образца (сроком на 5 лет) вам необходимо заранее подготовить 4 фотографии 3,5 х 4,5 см.',
          'If you are applying for an old-style passport (valid for 5 years), you need to prepare 4 photos sized 3.5 x 4.5 cm in advance.',
          '如果您申请旧版护照（有效期为5年），则需要提前准备4张3.5 x 4.5厘米的照片。',
          'Si solicita un pasaporte antiguo (válido por 5 años), debe preparar 4 fotos de 3.5 x 4.5 cm con anticipación.',
        ],
      ],
      comments: [
        [
          'Присутствие ребенка и родителя обязательно как при подаче, так и при получении готового документа.',
          'The presence of the child and the parent is mandatory both when submitting the documents and when receiving the finished document.',
          '儿童和父母的出席在提交文件和领取完成文件时都是强制性的。',
          'La presencia del niño y del padre es obligatoria tanto al presentar los documentos como al recibir el documento terminado.',
        ],
      ],
      ps: [
        'Смотреть требования к фотографии',
        'View photo requirements',
        '查看照片要求',
        'Ver requisitos de fotos',
      ],
    },
  ],

  passportInfoText1: [
    'Оформить новый загранпаспорт в консульстве можно как на основании внутреннего паспорта РФ, так и действующего загранпаспорта.',
    'A new passport can be obtained at the consulate, based on both your Russian internal passport or a valid foreign passport',
    '可以根据俄罗斯内部护照或有效的外国护照在领事馆申请新护照。',
    'Se puede obtener un nuevo pasaporte en el consulado, basándose tanto en su pasaporte interno ruso como en un pasaporte extranjero válido.',
  ],
  passportInfoText2: [
    'В случае, когда отсутствуют оба документа, проводится проверка наличия у заявителя российского гражданства. Только после его подтверждения производится приём документов.',
    "In case both documents are absent, a check is conducted to verify the applicant's Russian citizenship. Only after its confirmation the documents are accepted",
    '如果两个文件都缺失，将对申请人的俄罗斯国籍进行核查。只有在确认后才接受文件。',
    'En caso de que ambos documentos estén ausentes, se realiza una verificación para comprobar la ciudadanía rusa del solicitante. Solo después de su confirmación se aceptan los documentos.',
  ],
  passportInfoTextBlue1: [
    'Подробнее о проверке на гражданство',
    'More details about the citizenship verification process.',
    '有关国籍验证流程的更多详细信息。',
    'Más detalles sobre el proceso de verificación de ciudadanía.',
  ],
  passportInfoText3: [
    'При необходимости в срочном возвращении в Россию заявителю может быть оформлено свидетельство на возвращение.',
    'If an urgent return to Russia is required, the applicant may be issued a return certificate.',
    '如果需要紧急返回俄罗斯，申请人可以获得回国证书。',
    'Si se requiere un regreso urgente a Rusia, se puede emitir un certificado de regreso al solicitante.',
  ],
  passportInfoTextBlue2: [
    'Подробнее о Свидетельстве на возвращение',
    'More details about the Return Certificate.',
    '有关回国证书的更多详细信息。',
    'Más detalles sobre el Certificado de Regreso.',
  ],
  cvInfo: [
    'Сведения о трудовой деятельности',
    'Information about employment history.',
    '关于工作经历的信息。',
    'Información sobre el historial laboral.',
  ],
  cvInfoText: [
    'Для составления заявления на оформление загранпаспорта помимо вышеперечисленных документов, нам потребуются от вас сведения о работе и учёбе за последние 10 лет с указанием следующей информации:',
    'To prepare the application for a passport, in addition to the listed documents, we will also need information about your employment and education for the last 10 years, including the following details:',
    '为了准备护照申请，除了列出的文件外，我们还需要您在过去10年中的工作和教育信息，包括以下详细信息：',
    'Para preparar la solicitud de un pasaporte, además de los documentos enumerados, también necesitaremos información sobre su empleo y educación durante los últimos 10 años, incluyendo los siguientes detalles:',
  ],
  cvList: [
    [
      'период работы / учёбы',
      'period of work / study',
      '工作/学习时间',
      'período de trabajo / estudio',
    ],
    [
      'полное наименование организации',
      'full name of the organization',
      '组织的全名',
      'nombre completo de la organización',
    ],
    ['должность', 'position', '职位', 'posición'],
    [
      'адрес организации',
      'organization address',
      '组织地址',
      'dirección de la organización',
    ],
  ],
  readText: [
    'Читать, как правильно указывать',
    'To read, how to correctly specify.',
    '阅读，如何正确说明。',
    'Leer, cómo especificar correctamente.',
  ],
  back: ['Назад', 'Back', '返回', 'Atrás'],

  priceWithoutAppointment: [
    {
      header: 'Без записи в консульстве',
      list: [
        'заполним заявление на оформление загранпаспорта на консульском портале',
        'проконсультируем по перечню документов для оформления',
        'проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '40',
    },
    {
      header: 'Without an appointment at the consulate',
      list: [
        'we will fill out the application for a foreign passport on the consulate portal.',
        'we will provide consultation on the list of required documents.',
        'we will advise you on scheduling an appointment at the consulate on your own.',
      ],
      value: '40',
    },
    {
      header: '在领事馆没有预约',
      list: [
        '我们将在领事馆门户网站上填写护照申请。',
        '我们将提供所需文件清单的咨询。',
        '我们将为您提供关于如何自行预约的建议。',
      ],
      value: '40',
    },
    {
      header: 'Sin cita en el consulado',
      list: [
        'llenaremos la solicitud para el pasaporte en el portal del consulado.',
        'le proporcionaremos consulta sobre la lista de documentos requeridos.',
        'le asesoraremos sobre cómo programar una cita en el consulado por su cuenta.',
      ],
      value: '40',
    },
  ],
  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'заполним заявление на оформление загранпаспорта на консульском портале',
        'проконсультируем по перечню документов для оформления',
        'запишем вас на приём в консульство',
      ],
      value: '80',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'we will fill out the application for a passport on the consulate portal.',
        'we will provide consultation on the list of documents required for processing.',
        'we will advise you on scheduling an appointment at the consulate on your own.',
      ],
      value: '80',
    },
    {
      header: '在领事馆预约',
      list: [
        '我们将在领事馆门户网站上填写护照申请。',
        '我们将提供有关处理所需文件清单的咨询。',
        '我们将为您安排在领事馆的预约。',
      ],
      value: '80',
    },
    {
      header: 'Con cita en el consulado',
      list: [
        'llenaremos la solicitud para un pasaporte en el portal del consulado.',
        'le proporcionaremos consulta sobre la lista de documentos requeridos para el procesamiento.',
        'le agendaremos una cita en el consulado.',
      ],
      value: '80',
    },
  ],
  priceWithoutAppointment1: [
    {
      header: 'С записью в консульство',
      list: [
        'заполним заявление на оформление загранпаспорта на консульском портале',
        'проконсультируем по перечню документов для оформления',
        'проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '40',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'we will fill out the application for a passport on the consulate portal.',
        'we will provide consultation on the list of documents required for processing.',
        'we will provide consultation on the process of scheduling an appointment at the consulate independently.',
      ],
      value: '40',
    },
    {
      header: '在领事馆没有预约',
      list: [
        '我们将在领事馆门户网站上填写护照申请。',
        '我们将提供所需文件清单的咨询。',
        '我们将为您提供关于如何自行预约的建议。',
      ],
      value: '40',
    },
    {
      header: 'Sin cita en el consulado',
      list: [
        'llenaremos la solicitud para el pasaporte en el portal del consulado.',
        'le proporcionaremos consulta sobre la lista de documentos requeridos.',
        'le proporcionaremos consulta sobre el proceso de programación de una cita en el consulado de forma independiente.',
      ],
      value: '40',
    },
  ],
  priceWithAppointment1: [
    {
      header: 'С записью в консульство',
      list: [
        'заполним заявление на оформление загранпаспорта на консульском портале',
        'проконсультируем по перечню документов для оформления',
        'запишем вас на приём в консульство',
      ],
      value: '80',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'we will fill out the application for a passport on the consular portal.',
        'we will specify the list of documents required for submission according to your situation.',
        'we will provide consultation on the list of documents required for processing.',
        'we will schedule an appointment for you at the consulate.',
      ],
      value: '80',
    },
    {
      header: '在领事馆预约',
      list: [
        '我们将在领事馆门户网站上填写护照申请。',
        '我们将根据您的情况具体说明所需提交的文件清单。',
        '我们将提供处理所需文件清单的咨询。',
        '我们将为您在领事馆安排预约。',
      ],
      value: '80',
    },
    {
      header: 'Con cita en el consulado',
      list: [
        'llenaremos la solicitud para un pasaporte en el portal del consulado.',
        'especificaremos la lista de documentos necesarios para presentar según su situación.',
        'le proporcionaremos consulta sobre la lista de documentos requeridos para el procesamiento.',
        'le agendaremos una cita en el consulado.',
      ],
      value: '80',
    },
  ],

  priceText1: [
    'Оплатить услугу возможно как во время оформления заявки на сайте, так и после консультации с юристом.',
    'You can pay for the service either during the application process on the website or after consultation with the lawyer.',
  ],
  priceText2: [
    'Мы принимаем к оплате на сайте международные банковские карты, Apple Pay и Google Pay. Оплата российскими банковскими картами, с использованием системы PayPal или в криптовалюте на сайте не производится, но возможна по запросу.',
    'We accept international bank cards, Apple Pay, and Google Pay for payment on the website. Payment with Russian bank cards, using the PayPal, or in cryptocurrency is not processed on the website, but is possible upon request.',
  ],

  faqDesc: [
    [
      {
        question: 'Могу ли я в консульстве поменять внутренний паспорт РФ?',
        answer:
          'Нет, внутренний паспорт РФ можно оформить и получить только на территории России при личном присутствии',
      },
      {
        question:
          'Чем отличаются между собой загранпаспорта сроком на 5 и на 10 лет?',
        answer:
          'Количеством доступных страниц для виз и штампов, степенью защиты, которая влияет на скорость прохождения контроля. В паспорт старого образца можно вписывать детей. Паспорт нового образца можно оформить в качестве второго в дополнение к уже имеющемуся. Подробнее читать здесь',
      },
      {
        question:
          'Можно ли вместо оформления загранпаспорта ребёнку просто вписать его в свой?',
        answer:
          'Нет, согласно Административному регламенту ФМС, вступившему в силу 16 апреля 2010 года, внесение сведений о детях осуществляется только для подтверждения родства, но не позволяет детям выезжать за границу по паспорту родителей.',
      },
      {
        question: 'Как вписать ребёнка в свой загранпаспорт?',
        answer:
          'Детей можно вписать только в загранпаспорт старого образца сроком на 5 лет при оформлении, либо уже в готовый. Для этого дополнительно потребуются фотографии ребёнка и свидетельство о рождении.',
      },
      {
        question:
          'Можно ли оформить второй загранпаспорт в дополнение к действующему?',
        answer:
          'Да, но вторым загранпаспортом может быть только паспорт нового образца сроком на 10 лет.',
      },
      {
        question:
          'Что происходит с моим старым паспортом при подаче документов на новый загранпаспорт РФ?',
        answer:
          'Ранее выданный паспорт остаётся у вас на руках при подаче документов на новый загранпаспорт. В случае подачи на замену старого, он аннулируется после получения нового. В случае оформления дополнительного загранпаспорта, он остаётся действующим.',
      },
      {
        question:
          'Как оформить новый загранпаспорт РФ, если у меня истёк срок действия старого паспорта и нет внутреннего паспорта?',
        answer:
          'При отсутствии действующего загранпаспорта и внутреннего паспорта требуется проверка принадлежности к гражданству РФ в ближайшем консульстве/посольстве. Заявление на новый паспорт не принимается до завершения проверки принадлежности заявителя к российскому гражданству',
      },
      {
        question:
          'Требуется ли военный билет для получения заграничного паспорта РФ?',
        answer:
          'Нет, для оформления и получения загранпаспорта не требуется ни военного билета, ни справки из военкомата, ни приписного свидетельства.',
      },
      {
        question:
          'У меня нет внутреннего паспорта РФ и скоро истекает срок загранпаспорта. Могу ли я оформить в консульстве новый загранпаспорт?',
        answer:
          'Да, гражданин России, находящийся за рубежом, может оформить новый загранпаспорт на основании действующего загранпаспорта РФ',
      },
      {
        question:
          'У меня нет Российского загранпаспорта, но есть внутренний паспорт РФ. Могу ли я подать на оформление Российского загранпаспорта?',
        answer:
          'Да, по внутреннему паспорту РФ также возможно оформить загранпаспорт',
      },
      {
        question:
          'Я в настоящий момент проживаю на территории Украины и у меня скоро заканчивается срок действия загранпаспорта РФ. Где я могу его поменять?',
        answer:
          'На настоящий момент, российские граждане, проживающие в Украине, могут оформить российский загранпаспорт только на территории граничащих с Украиной государств (либо на территории других государств), в которых функционируют Российские консульства: Польша, Беларусь и т.д.',
      },
    ],
    [
      {
        question: 'Can I change my Russian internal passport at the consulate?',
        answer:
          'No, the Russian internal passport can only be processed and obtained on the territory of Russia in person.',
      },
      {
        question:
          'What is the difference between the 5-year and the 10-year passport?',
        answer:
          'The number of available pages for visas and stamps, the degree of protection, which affects the speed of passing control. In the old-style passport, children can be included. The new-style passport can be obtained as a second one in addition to the existing one. Read more here',
      },
      {
        question:
          'Can I simply include my child in my passport instead of applying for a separate one for them?',
        answer:
          "No, according to the Administrative Regulations of the FMS, which entered into force on April 16, 2010, the inclusion of children's information is only for the purpose of confirming kinship, but it does not allow children to travel abroad using their parents' passport.",
      },
      {
        question: 'How can I include my child in my passport?',
        answer:
          'Children can only be included in the old-style passport for 5 years during the application process or after it has been issued. For this, additional photos of the child and a birth certificate will be required.',
      },
      {
        question:
          'Can I apply for a second passport in addition to the one I already have?',
        answer:
          'Yes, but the second passport can only be a new-style passport valid for 10 years.',
      },
      {
        question:
          'What happens to my old passport when I apply for a new Russian passport?',
        answer:
          'The previously issued passport remains with you when applying for a new passport. In the case of applying for a replacement of the old one, it is invalidated after receiving the new one. In the case of obtaining an additional passport, it remains valid.',
      },
      {
        question:
          'How can I apply for a new Russian passport if my old one has expired and I do not have an internal passport?',
        answer:
          "In the absence of a valid foreign passport and internal passport, it is necessary to verify the Russian citizenship at the nearest consulate/embassy. The application for a new passport is not accepted until the verification of the applicant's Russian citizenship is completed.",
      },
      {
        question: 'Is a military ID required to obtain a Russian passport?',
        answer:
          'No, neither a military ID nor a certificate from the military enlistment office nor a military service registration certificate is required to apply for and receive a passport.',
      },
      {
        question:
          'I do not have an internal Russian passport, and my foreign passport will soon expire. Can I apply for a new foreign passport at the consulate?',
        answer:
          'Yes, a Russian citizen located abroad can obtain a new foreign passport based on the valid foreign passport of the Russian Federation.',
      },
      {
        question:
          'I do not have an internal Russian passport, but I have an internal passport. Can I apply for a Russian passport?',
        answer:
          'Yes, a Russian foreign passport can also be obtained based on the internal passport.',
      },
      {
        question:
          'I currently reside in Ukraine and my Russian passport is about to expire. Where can I replace it?',
        answer:
          'At present, the Russian citizens residing in Ukraine can only obtain a Russian passport on the territory of neighboring states bordering Ukraine (or in other states) where Russian consulates operate: Poland, Belarus, etc.',
      },
    ],
    [
      {
        question: '我可以在领事馆更换我的俄罗斯内部护照吗？',
        answer: '不可以，俄罗斯内部护照只能在俄罗斯境内亲自办理和领取。',
      },
      {
        question: '5年和10年护照有什么区别？',
        answer:
          '可用的签证和印章页面数量、保护程度影响通关速度。在旧版护照中，可以包含儿童。新版护照可以作为第二本护照办理，作为现有护照的补充。更多信息请点击这里',
      },
      {
        question:
          '我可以仅仅把我的孩子包括在我的护照中，而不是为他们申请单独的护照吗？',
        answer:
          '不可以，根据2010年4月16日生效的FMS行政法规，儿童信息的包含仅用于确认亲属关系，但不允许儿童使用父母的护照出国旅行。',
      },
      {
        question: '如何将我的孩子加入我的护照？',
        answer:
          '儿童只能在申请过程中或护照已发放后将其加入旧版护照中。为此，还需要儿童的照片和出生证明。',
      },
      {
        question: '我可以申请第二本护照，作为我已有护照的补充吗？',
        answer: '可以，但第二本护照只能是有效期为10年的新版护照。',
      },
      {
        question: '申请新的俄罗斯护照时，我的旧护照会发生什么？',
        answer:
          '在申请新护照时，先前发放的护照将保留在您手中。如果申请更换旧护照，旧护照将在收到新护照后作废。如果申请额外护照，旧护照将保持有效。',
      },
      {
        question:
          '如果我的旧护照过期，而我没有内部护照，如何申请新的俄罗斯护照？',
        answer:
          '在没有有效的外国护照和内部护照的情况下，必须在最近的领事馆/大使馆验证俄罗斯国籍。在完成对申请人俄罗斯国籍的验证之前，不接受新的护照申请。',
      },
      {
        question: '申请俄罗斯护照是否需要军人证？',
        answer:
          '不需要，申请和领取护照不需要军人证、兵役登记办公室的证明或兵役登记证。',
      },
      {
        question:
          '我没有俄罗斯内部护照，而我的外国护照即将过期。我可以在领事馆申请新的外国护照吗？',
        answer:
          '可以，位于国外的俄罗斯公民可以基于有效的俄罗斯联邦外国护照申请新的外国护照。',
      },
      {
        question:
          '我没有俄罗斯内部护照，但我有内部护照。我可以申请俄罗斯护照吗？',
        answer: '可以，俄罗斯的外国护照也可以根据内部护照申请。',
      },
      {
        question:
          '我目前居住在乌克兰，我的俄罗斯护照即将过期。我可以在哪里更换它？',
        answer:
          '目前，居住在乌克兰的俄罗斯公民只能在与乌克兰接壤的邻国（或其他国家）境内的俄罗斯领事馆办理俄罗斯护照：波兰、白俄罗斯等。',
      },
    ],
    [
      {
        question: '¿Puedo cambiar mi pasaporte interno ruso en el consulado?',
        answer:
          'No, el pasaporte interno ruso solo puede ser procesado y obtenido en el territorio de Rusia en persona.',
      },
      {
        question:
          '¿Cuál es la diferencia entre el pasaporte de 5 años y el de 10 años?',
        answer:
          'El número de páginas disponibles para visados y sellos, el grado de protección, que afecta la velocidad del control. En el pasaporte de estilo antiguo se pueden incluir niños. El pasaporte de estilo nuevo se puede obtener como segundo pasaporte además del existente. Lea más aquí.',
      },
      {
        question:
          '¿Puedo simplemente incluir a mi hijo en mi pasaporte en lugar de solicitar uno separado para él?',
        answer:
          'No, según el Reglamento Administrativo del FMS, que entró en vigor el 16 de abril de 2010, la inclusión de información sobre los niños es solo para confirmar el parentesco, pero no permite que los niños viajen al extranjero utilizando el pasaporte de sus padres.',
      },
      {
        question: '¿Cómo puedo incluir a mi hijo en mi pasaporte?',
        answer:
          'Los niños solo se pueden incluir en el pasaporte de estilo antiguo por 5 años durante el proceso de solicitud o después de que se haya emitido. Para esto, se requieren fotos adicionales del niño y un certificado de nacimiento.',
      },
      {
        question:
          '¿Puedo solicitar un segundo pasaporte además del que ya tengo?',
        answer:
          'Sí, pero el segundo pasaporte solo puede ser un pasaporte de estilo nuevo válido por 10 años.',
      },
      {
        question:
          '¿Qué sucede con mi antiguo pasaporte cuando solicito un nuevo pasaporte ruso?',
        answer:
          'El pasaporte emitido anteriormente permanece con usted al solicitar un nuevo pasaporte. En caso de solicitar un reemplazo del antiguo, se invalida después de recibir el nuevo. En caso de obtener un pasaporte adicional, permanece válido.',
      },
      {
        question:
          '¿Cómo puedo solicitar un nuevo pasaporte ruso si mi antiguo ha expirado y no tengo un pasaporte interno?',
        answer:
          'En ausencia de un pasaporte extranjero válido y un pasaporte interno, es necesario verificar la ciudadanía rusa en el consulado o embajada más cercana. No se acepta la solicitud de un nuevo pasaporte hasta que se complete la verificación de la ciudadanía rusa del solicitante.',
      },
      {
        question:
          '¿Se requiere un carnet militar para obtener un pasaporte ruso?',
        answer:
          'No, no se requiere ni un carnet militar ni un certificado de la oficina de reclutamiento militar ni un certificado de registro militar para solicitar y recibir un pasaporte.',
      },
      {
        question:
          'No tengo un pasaporte interno ruso y mi pasaporte extranjero está a punto de expirar. ¿Puedo solicitar un nuevo pasaporte extranjero en el consulado?',
        answer:
          'Sí, un ciudadano ruso ubicado en el extranjero puede obtener un nuevo pasaporte extranjero basado en el pasaporte extranjero válido de la Federación Rusa.',
      },
      {
        question:
          'No tengo un pasaporte interno ruso, pero tengo un pasaporte interno. ¿Puedo solicitar un pasaporte ruso?',
        answer:
          'Sí, también se puede obtener un pasaporte extranjero ruso basado en el pasaporte interno.',
      },
      {
        question:
          'Actualmente resido en Ucrania y mi pasaporte ruso está a punto de expirar. ¿Dónde puedo reemplazarlo?',
        answer:
          'En la actualidad, los ciudadanos rusos que residen en Ucrania solo pueden obtener un pasaporte ruso en el territorio de estados vecinos que limitan con Ucrania (o en otros estados) donde operan consulados rusos: Polonia, Bielorrusia, etc.',
      },
    ],
  ],
  faqList: [
    {
      userName: [
        'Могу ли я в консульстве поменять внутренний паспорт РФ?',
        'Can I change my Russian internal passport at the consulate?',
        '我可以在领事馆更换我的俄罗斯内部护照吗？',
        '¿Puedo cambiar mi pasaporte interno ruso en el consulado?',
      ],
      desc: [
        [
          'Нет, внутренний паспорт РФ можно оформить и получить только на территории России при личном присутствии',
          'No, the Russian internal passport can only be processed and obtained on the territory of Russia in person.',
          '不，可以在俄罗斯境内亲自办理和领取俄罗斯内部护照。',
          'No, el pasaporte interno ruso solo se puede tramitar y obtener en el territorio de Rusia en persona.',
        ],
      ],
    },
    {
      userName: [
        'Чем отличаются между собой загранпаспорта сроком на 5 и на 10 лет?',
        'What is the difference between a 5-year and a 10-year passport?',
        '5年和10年护照有什么区别？',
        '¿Cuál es la diferencia entre un pasaporte de 5 años y uno de 10 años?',
      ],
      desc: [
        [
          'Количеством доступных страниц для виз и штампов, степенью защиты, которая влияет на скорость прохождения контроля. В паспорт старого образца можно вписывать детей. Паспорт нового образца можно оформить в качестве второго в дополнение к уже имеющемуся.',
          'The number of available pages for visas and stamps, the degree of protection, which affects the speed of passing control. In the old-style passport, children can be included. A new-style passport can be obtained as a second one in addition to the existing one.',
          '签证和印章可用页面的数量，保护程度影响通关速度。在旧版护照中可以加入儿童。新版护照可以作为第二本护照申请，补充现有护照。',
          'El número de páginas disponibles para visados y sellos, el grado de protección, que afecta la velocidad de control. En el pasaporte de estilo antiguo, se pueden incluir los niños. Se puede obtener un pasaporte de estilo nuevo como segundo pasaporte además del existente.',
        ],
      ],
      link: {
        to: '/passport?open=passportDifferences',
        text: [
          'Подробнее читать здесь',
          'Read more here',
          '在这里阅读更多信息',
          'Leer más aquí',
        ],
      },
    },
    {
      userName: [
        'Можно ли вместо оформления загранпаспорта ребёнку просто вписать его в свой?',
        'Can I simply include my child in my passport instead of applying for a separate one for them?',
        '我可以直接把我的孩子加入我的护照，而不是为他们申请单独的护照吗？',
        '¿Puedo simplemente incluir a mi hijo en mi pasaporte en lugar de solicitar uno separado para él?',
      ],
      desc: [
        [
          'Нет, согласно Административному регламенту ФМС, вступившему в силу 16 апреля 2010 года, внесение сведений о детях осуществляется только для подтверждения родства, но не позволяет детям выезжать за границу по паспорту родителей.',
          "No, according to the Administrative Regulations of the FMS, which entered into force on April 16, 2010, the inclusion of children's information is only for the purpose of confirming kinship, but it does not allow children to travel abroad using their parents' passport.",
          '不，根据2010年4月16日生效的FMS行政法规，儿童信息的加入仅用于确认亲属关系，但不允许儿童使用父母的护照出境。',
          'No, de acuerdo con el Reglamento Administrativo del FMS, que entró en vigor el 16 de abril de 2010, la inclusión de la información de los niños es solo para confirmar el parentesco, pero no permite que los niños viajen al extranjero con el pasaporte de sus padres.',
        ],
      ],
    },
    {
      userName: [
        'Как вписать ребёнка в свой загранпаспорт?',
        'How can I include my child in my passport?',
        '我该如何将我的孩子加入我的护照？',
        '¿Cómo puedo incluir a mi hijo en mi pasaporte?',
      ],
      desc: [
        [
          'Детей можно вписать только в загранпаспорт старого образца сроком на 5 лет при оформлении, либо уже в готовый. Для этого дополнительно потребуются фотографии ребёнка и свидетельство о рождении.',
          'Children can only be included in the old-style passport for 5 years during the application process or after it has been issued. For this, additional photos of the child and a birth certificate will be required.',
          '儿童只能在申请过程中或在护照发放后加入5年旧版护照。为此，还需要儿童的照片和出生证明。',
          'Los niños solo pueden ser incluidos en el pasaporte de estilo antiguo por 5 años durante el proceso de solicitud o después de su emisión. Para ello, se requerirán fotos adicionales del niño y un certificado de nacimiento.',
        ],
      ],
    },
    {
      userName: [
        'Можно ли оформить второй загранпаспорт в дополнение к действующему?',
        'Can I apply for a second passport in addition to the one I already have?',
        '我可以申请第二本护照作为现有护照的补充吗？',
        '¿Puedo solicitar un segundo pasaporte además del que ya tengo?',
      ],
      desc: [
        [
          'Да, но вторым загранпаспортом может быть только паспорт нового образца сроком на 10 лет.',
          'Yes, but the second passport can only be a new-style passport valid for 10 years.',
          '是的，但第二本护照只能是有效期10年的新版护照。',
          'Sí, pero el segundo pasaporte solo puede ser un pasaporte de nuevo estilo válido por 10 años.',
        ],
      ],
    },
    {
      userName: [
        'Что происходит с моим старым паспортом при подаче документов на новый загранпаспорт РФ?',
        'What happens to my old passport when I apply for a new Russian passport?',
        '我申请新的俄罗斯护照时，我的旧护照会怎样？',
        '¿Qué pasa con mi viejo pasaporte cuando solicito un nuevo pasaporte ruso?',
      ],
      desc: [
        [
          'Ранее выданный паспорт остаётся у вас на руках при подаче документов на новый загранпаспорт. В случае подачи на замену старого, он аннулируется после получения нового. В случае оформления дополнительного загранпаспорта, он остаётся действующим.',
          'The previously issued passport remains with you when applying for a new passport. In the case of applying for a replacement for the old one, it is invalidated after receiving the new one. In the case of obtaining an additional passport, it remains valid.',
          '在申请新护照时，之前发放的护照将保留在您手中。如果申请替换旧护照，则在收到新护照后将被作废。如果申请额外护照，则它仍然有效。',
          'El pasaporte emitido anteriormente permanece con usted cuando solicita un nuevo pasaporte. En el caso de solicitar un reemplazo del antiguo, se anula después de recibir el nuevo. En el caso de obtener un pasaporte adicional, sigue siendo válido.',
        ],
      ],
    },
    {
      userName: [
        'Как оформить новый загранпаспорт РФ, если у меня истёк срок действия старого паспорта и нет внутреннего паспорта?',
        'How can I apply for a new Russian passport if my old one has expired and I do not have an internal passport?',
        '如果我旧护照已过期且没有内部护照，如何申请新的俄罗斯护照？',
        '¿Cómo puedo solicitar un nuevo pasaporte ruso si mi viejo ha expirado y no tengo un pasaporte interno?',
      ],
      desc: [
        [
          'При отсутствии действующего загранпаспорта и внутреннего паспорта требуется проверка принадлежности к гражданству РФ в ближайшем консульстве/посольстве. Заявление на новый паспорт не принимается до завершения проверки принадлежности заявителя к российскому гражданству',
          "In the absence of a valid foreign passport and internal passport, it is necessary to verify the Russian citizenship at the nearest consulate/embassy. The application for a new passport is not accepted until the verification of the applicant's affiliation with Russian citizenship is completed.",
          '在没有有效的外国护照和内部护照的情况下，必须在最近的领事馆/大使馆验证俄罗斯国籍。在完成申请人国籍的验证之前，无法提交新护照的申请。',
          'En ausencia de un pasaporte extranjero válido y de un pasaporte interno, es necesario verificar la ciudadanía rusa en el consulado/embajada más cercano. La solicitud de un nuevo pasaporte no se acepta hasta que se complete la verificación de la afiliación del solicitante a la ciudadanía rusa.',
        ],
      ],
    },
    {
      userName: [
        'Требуется ли военный билет для получения заграничного паспорта РФ?',
        'Is a military ID required to obtain a Russian passport?',
        '申请俄罗斯护照需要军人身份证吗？',
        '¿Se requiere una identificación militar para obtener un pasaporte ruso?',
      ],
      desc: [
        [
          'Нет, для оформления и получения загранпаспорта не требуется ни военного билета, ни справки из военкомата, ни приписного свидетельства.',
          'No, neither a military ID nor a certificate from the military enlistment office nor a draft certificate is required to apply for and receive a passport.',
          '不，申请和领取护照不需要军人身份证、军方登记处的证明或征兵证明。',
          'No, no se requiere identificación militar, certificado de la oficina de reclutamiento ni certificado de reclutamiento para solicitar y recibir un pasaporte.',
        ],
      ],
    },
    {
      userName: [
        'У меня нет внутреннего паспорта РФ и скоро истекает срок загранпаспорта. Могу ли я оформить в консульстве новый загранпаспорт?',
        'I do not have a Russian passport, but I have an internal passport. Can I apply for a Russian passport?',
        '我没有俄罗斯护照，但有内部护照。我可以申请俄罗斯护照吗？',
        'No tengo un pasaporte ruso, pero tengo un pasaporte interno. ¿Puedo solicitar un pasaporte ruso?',
      ],
      desc: [
        [
          'Да, гражданин России, находящийся за рубежом, может оформить новый загранпаспорт на основании действующего загранпаспорта РФ',
          'Yes, a Russian passport can also be obtained based on the internal passport.',
          '是的，俄罗斯公民在国外也可以根据内部护照申请新的护照。',
          'Sí, un ciudadano ruso en el extranjero también puede obtener un nuevo pasaporte basado en el pasaporte interno.',
        ],
      ],
    },
    {
      userName: [
        'У меня нет Российского загранпаспорта, но есть внутренний паспорт РФ. Могу ли я подать на оформление Российского загранпаспорта?',
        'I do not have a Russian international passport, but I have a Russian internal passport. Can I apply for a Russian international passport?',
        '我没有俄罗斯国际护照，但我有俄罗斯内部护照。我可以申请俄罗斯国际护照吗？',
        'No tengo un pasaporte internacional ruso, pero tengo un pasaporte interno ruso. ¿Puedo solicitar un pasaporte internacional ruso?',
      ],
      desc: [
        [
          'Да, по внутреннему паспорту РФ также возможно оформить загранпаспорт',
          'Yes, it is also possible to obtain an international passport using the Russian internal passport.',
          '是的，使用俄罗斯内部护照也可以申请国际护照。',
          'Sí, también es posible obtener un pasaporte internacional utilizando el pasaporte interno ruso.',
        ],
      ],
    },
    {
      userName: [
        'Я в настоящий момент проживаю на территории Украины и у меня скоро заканчивается срок действия загранпаспорта РФ. Где я могу его поменять?',
        'I currently reside in Ukraine and my Russian passport is about to expire. Where can I replace it?',
        '我目前住在乌克兰，我的俄罗斯护照即将到期。我在哪里可以更换？',
        'Actualmente resido en Ucrania y mi pasaporte ruso está a punto de caducar. ¿Dónde puedo cambiarlo?',
      ],
      desc: [
        [
          'На настоящий момент, российские граждане, проживающие в Украине, могут оформить российский загранпаспорт только на территории граничащих с Украиной государств (либо на территории других государств), в которых функционируют Российские консульства: Польша, Беларусь и т.д.',
          'At present, Russian citizens residing in Ukraine can only obtain a Russian passport on the territory of neighboring states bordering Ukraine (or in other states) where Russian consulates operate: Poland, Belarus, etc.',
          '目前，居住在乌克兰的俄罗斯公民只能在与乌克兰接壤的国家（或其他国家）获得俄罗斯护照，在这些国家中，俄罗斯领事馆正常运作：波兰、白俄罗斯等。',
          'En la actualidad, los ciudadanos rusos que residen en Ucrania solo pueden obtener un pasaporte ruso en el territorio de los estados vecinos que limitan con Ucrania (o en otros estados) donde operan los consulados rusos: Polonia, Bielorrusia, etc.',
        ],
      ],
    },
  ],

  orderDescription: [
    [
      {
        headerText: 'Оформление заявки',
        cardText:
          'Опишите вашу ситуацию. Направьте нам требуемый пакет документов и сведения о трудовом стаже. Это возможно сделать как на этапе оформления заявки на сайте, так и после консультации с юристом.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Заполнение заявления на оформление загранпаспорта',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали, и заполнит онлайн-заявление на портале КД МИД России. Вы получите QR-код, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Подача документов на оформление',
        cardText:
          'В день записи приходите в консульство с пакетом документов, QR-кодом и распечатанным заявлением для подачи на оформление загранпаспорта',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submission of an Application',
        cardText:
          'Describe your situation. Send us the required package of documents and information about your work experience. This can be done both at the stage of submitting an application on the website and after consulting with a lawyer.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Filling Out a Passport Application',
        cardText:
          'Our lawyer will contact you, discuss the details, and fill out the online application on the portal of the Consular Department of the Ministry of Foreign Affairs of Russia. You will receive a QR code that you have to take with you to the consulate. The service completion time is 2 business days from the date of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Scheduling an appointment at the Consulate',
        cardText:
          'Schedule an appointment at the consulate on your own or with our assistance (according to the selected tariff).',
        cardImage: calendarImage,
      },
      {
        headerText: 'Submission of Documents',
        cardText:
          'On the day of the appointment, arrive to the consulate with the package of documents, QR code, and printed application for submitting your passport application.',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: '申请提交',
        cardText:
          '描述您的情况。向我们发送所需的文件包和有关您的工作经验的信息。这可以在网站提交申请阶段完成，也可以在咨询律师后完成。',
        cardImage: checkBoxImage,
      },
      {
        headerText: '填写护照申请',
        cardText:
          '我们的律师会与您联系，讨论细节，并在俄罗斯外交部领事部门的门户网站上填写在线申请。您将收到一个二维码，必须带到领事馆。服务完成时间为付款之日起2个工作日。',
        cardImage: documentBrown,
      },
      {
        headerText: '预约领事馆接待',
        cardText:
          '您可以自己预约领事馆，或者在我们的帮助下预约（根据所选的费用）。',
        cardImage: calendarImage,
      },
      {
        headerText: '提交文件',
        cardText: '在约定日期，携带文件包、二维码和打印的护照申请前往领事馆。',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Presentación de una Solicitud',
        cardText:
          'Describa su situación. Envíenos el paquete de documentos requerido y la información sobre su experiencia laboral. Esto se puede hacer tanto en la etapa de presentación de una solicitud en el sitio web como después de consultar con un abogado.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Rellenar una Solicitud de Pasaporte',
        cardText:
          'Nuestro abogado se pondrá en contacto con usted, discutirá los detalles y completará la solicitud en línea en el portal del Departamento Consular del Ministerio de Relaciones Exteriores de Rusia. Recibirá un código QR que deberá llevar con usted al consulado. El tiempo de finalización del servicio es de 2 días hábiles a partir de la fecha de pago.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Programar una Cita en el Consulado',
        cardText:
          'Programe una cita en el consulado por su cuenta o con nuestra asistencia (de acuerdo con la tarifa seleccionada).',
        cardImage: calendarImage,
      },
      {
        headerText: 'Presentación de Documentos',
        cardText:
          'El día de la cita, llegue al consulado con el paquete de documentos, el código QR y la solicitud impresa para presentar su solicitud de pasaporte.',
        cardImage: stampImage,
      },
    ],
  ],

  whoNeedsPassport: [
    'Кому нужен загранпаспорт?',
    'Who needs a passport?',
    '谁需要护照？',
    '¿Quién necesita un pasaporte?',
  ],
  toWhom: [
    'Кому оформляется',
    'To whom is it issued?',
    '给谁发放？',
    '¿A quién se emite?',
  ],
  passportVariant: [
    'Какой вид загранпаспорта вы хотите оформить?',
    'What type of passport would you like to apply for?',
    '您想申请哪种类型的护照？',
    '¿Qué tipo de pasaporte le gustaría solicitar?',
  ],
  readDifference: [
    'Читать, чем отличаются',
    'Read how they differ',
    '阅读它们的区别',
    'Lea cómo se diferencian',
  ],
  doYouHavePassport: [
    'Есть ли у вас действующий загранпаспорт РФ?',
    'Do you have a valid Russian passport?',
    '您有有效的俄罗斯护照吗？',
    '¿Tiene un pasaporte ruso válido?',
  ],
  doYouHavePassport1: [
    'Есть ли действующий внутренний паспорт РФ?',
    'Do you have a valid Russian internal passport?',
    '您有有效的俄罗斯内部护照吗？',
    '¿Tiene un pasaporte interno ruso válido?',
  ],
  yes: ['Да', 'Yes', '是', 'Sí'],
  no: ['Нет', 'No', '不是', 'No'],
  reasonOfPassportIssue: [
    'По какой причине оформляется загранпаспорт?',
    'For what reason is a foreign passport issued?',
    '申请护照的原因是什么？',
    '¿Por qué se emite un pasaporte extranjero?',
  ],
  additionalPassport: [
    'В дополнение к уже имеющемуся',
    'In addition to the existing one',
    '除了已有的护照',
    'Además del existente',
  ],
  isExpired: [
    'Истекает срок действия',
    'The expiration date is approaching.',
    '到期日临近',
    'La fecha de expiración se acerca.',
  ],
  familyNameChangeMarriage: [
    'Смена фамилии в связи с регистрацией или расторжением брака',
    'Change of surname due to marriage registration or divorce.',
    '因结婚登记或离婚而更改姓氏。',
    'Cambio de apellido debido a la inscripción de matrimonio o divorcio.',
  ],
  familyNameChange: [
    'Смена ФИО вследствие специальной процедуры ЗАГС',
    "Change of full name as a result of a special procedure by the Registrar's Office.",
    '因登记机关的特殊程序而更改全名。',
    'Cambio de nombre completo como resultado de un procedimiento especial de la Oficina del Registro Civil.',
  ],
  citizenshipConfirmation: [
    'Подтверждение наличия гражданства РФ',
    'Confirmation of Russian citizenship.',
    '确认拥有俄罗斯公民身份。',
    'Confirmación de la ciudadanía rusa.',
  ],
  citizenshipConfirmationText: [
    'Для подачи на оформление загранпаспорта вам нужно предоставить документы, содержащие отметку (или вкладыш) с указанием гражданства РФ.',
    'To apply for the issuance of a passport, you need to provide documents containing a stamp (or insert) indicating Russian citizenship.',
    '申请护照时，您需要提供带有表明俄罗斯国籍的印章（或插页）的文件。',
    'Para solicitar la emisión de un pasaporte, debe presentar documentos que contengan un sello (o inserto) que indique la ciudadanía rusa.',
  ],
  iHaveIt: [
    'У меня есть такой документ',
    'I have this document',
    '我有这个文件',
    'Tengo este documento',
  ],
  citizenshipConfirmationText1: [
    'Перед подачей документов на оформление загранпаспорта вам нужно пройти процедуру проверки на гражданство РФ.',
    'Before submitting documents for obtaining a foreign passport, you need to undergo a procedure to verify Russian citizenship.',
    '在提交护照申请文件之前，您需要进行俄罗斯国籍验证程序。',
    'Antes de presentar documentos para obtener un pasaporte extranjero, debe someterse a un procedimiento para verificar la ciudadanía rusa.',
  ],
  readDetailsConfirmation: [
    'Читать подробнее о процедуре',
    'Read more about the procedure.',
    '阅读有关该程序的更多信息',
    'Lea más sobre el procedimiento.',
  ],
  alreadyConfirmed: [
    'Я прошел проверку на гражданство',
    'I have undergone citizenship verification.',
    '我已通过国籍验证。',
    'He pasado la verificación de ciudadanía.',
  ],
  exitFromCitizenship: [
    'Выход из гражданства РФ',
    'Renouncing the Russian citizenship',
    '退出俄罗斯国籍',
    'Renuncia a la ciudadanía rusa',
  ],
  photoRequirements: [
    {
      type: 'title',
      text: [
        'Требования к фотографии',
        'Photo Requirements',
        '照片要求',
        'Requisitos de foto',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Для загранпаспорта нового образца (на 10 лет) сфотографируют прямо в консульстве. Приносить фото с собой не нужно. Фото будет чёрно-белым.',
        'For a new type of passport (for 10 years), the photo will be taken directly at the consulate. Bringing a photo with you is not necessary. The photo will be black and white.',
        '对于新式护照（10年）将直接在领事馆拍照。无需自带照片。照片将是黑白的。',
        'Para un nuevo tipo de pasaporte (por 10 años), la foto se tomará directamente en el consulado. No es necesario traer una foto. La foto será en blanco y negro.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Для загранпаспорта старого образца (на 5 лет) нужно сделать фотографии самостоятельно и принести на приём в консульство.',
        'For an old type of passport (for 5 years), you need to take the photos yourself and bring them to the consulate.',
        '对于旧式护照（5年），您需要自己拍照并带到领事馆。',
        'Para un viejo tipo de pasaporte (por 5 años), debe tomar las fotos usted mismo y llevarlas al consulado.',
      ],
    },
    {
      type: 'list',
      items: [
        [
          'количество: 4 штуки',
          'quantity: 4 pieces',
          '数量：4个',
          'cantidad: 4 piezas',
        ],
        [
          'цветное или чёрно-белое',
          'color or black and white',
          '彩色或黑白',
          'color o blanco y negro',
        ],
        [
          'размер: 35x45 мм',
          'size: 35x45 mm',
          '尺寸：35x45毫米',
          'tamaño: 35x45 mm',
        ],
        [
          'овал лица занимает 70-80% от фото',
          'face oval occupies 70-80% of the photo',
          '脸部椭圆占照片的70-80%',
          'el óvalo de la cara ocupa el 70-80% de la foto',
        ],
        [
          'лицо смотрит на камеру, глаза должны быть открытыми, а волосы не должны заслонять их',
          'face looks at the camera, eyes should be open, and hair should not cover them',
          '面部看向相机，眼睛应睁开，头发不应遮挡',
          'la cara mira a la cámara, los ojos deben estar abiertos y el cabello no debe cubrirlos',
        ],
        [
          'выражение лица нейтральное',
          'neutral facial expression',
          '面部表情中立',
          'expresión facial neutral',
        ],
        [
          'всё лицо чёткое, в фокусе',
          'entire face clear, in focus',
          '整个面部清晰，聚焦',
          'todo el rostro claro, enfocado',
        ],
        [
          'фон светлый, ровный, без полос, пятен и изображения посторонних предметов и теней',
          'background light, even, without stripes, spots, or images of foreign objects and shadows',
          '背景应明亮均匀，没有条纹、斑点和外物及阴影的图像',
          'fondo claro, uniforme, sin rayas, manchas o imágenes de objetos extraños y sombras',
        ],
        [
          'фото соответствует возрасту',
          'photo matches the age',
          '照片符合年龄要求',
          'la foto corresponde a la edad',
        ],
        [
          'если религия обязывает покрывать голову, овал лица должно быть чётко видно. В иных случаях головных уборов быть не должно',
          'if religion requires covering the head, the oval of the face should be clearly visible. In other cases, headwear should not be present',
          '如果宗教要求遮盖头部，则面部轮廓应清晰可见。在其他情况下，不应佩戴头饰',
          'si la religión requiere cubrir la cabeza, el óvalo de la cara debe ser claramente visible. En otros casos, no debe haber tocados',
        ],
        [
          'не допускается фотографирование в очках с затемнёнными стёклами и (или) в форменной одежде',
          'photography in glasses with tinted lenses and (or) in uniform clothing is not allowed',
          '不允许佩戴有色镜片眼镜和（或）穿着制服拍照',
          'no se permite fotografiar con gafas de lentes tintados y/o con ropa de uniforme',
        ],
      ],
    },
  ],

  workflow: {
    whoNeedsPassport: {
      title: [
        'Кому нужен загранпаспорт?',
        'Who needs a foreign passport?',
        '谁需要护照？',
        '¿Quién necesita un pasaporte?',
      ],
      options: whoNeedsPassportOptions,
    },
    passportVariant: {
      title: [
        'Какой вид загранпаспорта вы хотите оформить?',
        'What type of foreign passport do you want to apply for?',
        '您想申请哪种类型的护照？',
        '¿Qué tipo de pasaporte le gustaría solicitar?',
      ],
      options: passportVariants,
      link: [
        'Читать, чем отличаются',
        'Read how they differ',
        '阅读它们的区别',
        'Lea cómo se diferencian',
      ],
    },
    childHasPassport: {
      title: [
        'Есть ли у ребенка на данный момент другой действующий загранпаспорт РФ?',
        'Does the child currently have another valid Russian foreign passport?',
        '孩子目前是否持有其他有效的俄罗斯护照？',
        '¿El niño tiene actualmente otro pasaporte ruso válido?',
      ],
      options: binaryOptions,
    },
    adultHasPassport: {
      title: {
        hasForeignPassport: [
          'Есть ли другой действующий загранпаспорт РФ?',
          'Is there another valid Russian foreign passport?',
          '是否持有其他有效的俄罗斯护照？',
          '¿Hay otro pasaporte ruso válido?',
        ],
        hasInternalPassport: [
          'Есть ли действующий внутренний паспорт РФ?',
          'Is there a valid internal Russian passport?',
          '是否持有有效的俄罗斯内部护照？',
          '¿Hay un pasaporte interno ruso válido?',
        ],
      },
      options: binaryOptions,
    },
    reasonOfPassportIssue: {
      title: [
        'По какой причине оформляется загранпаспорт?',
        'For what reason is the foreign passport issued?',
        '申请护照的原因是什么？',
        '¿Por qué se emite un pasaporte extranjero?',
      ],
      optionsHasValidPassport: [
        [
          'В дополнение к уже имеющемуся',
          'In addition to the existing one',
          '除了已有的护照',
          'Además del existente',
        ],
        [
          'Истекает срок действия',
          'The validity period is expiring',
          '有效期即将到期',
          'La fecha de expiración se acerca.',
        ],
        [
          'Смена фамилии в связи с регистрацией или расторжением брака',
          'Change of surname due to registration or dissolution of marriage',
          '因结婚登记或离婚而更改姓氏',
          'Cambio de apellido debido a la inscripción de matrimonio o divorcio.',
        ],
        [
          'Смена ФИО вследствие специальной процедуры ЗАГС',
          'Change of name due to a special registry office procedure',
          '因登记机关的特殊程序而更改全名',
          'Cambio de nombre completo como resultado de un procedimiento especial de la Oficina del Registro Civil.',
        ],
      ],
      optionsHasNoValidPassport: [
        [
          'Первичное оформление',
          'Initial issue',
          '首次办理',
          'Emisión inicial',
        ],
        [
          'Истек срок действия',
          'The validity period has expired',
          '有效期已过',
          'El período de validez ha expirado',
        ],
        [
          'Паспорт был утерян / украден',
          'Passport was lost / stolen',
          '护照丢失/被盗',
          'El pasaporte fue perdido/robado',
        ],
      ],
    },
    citizenshipConfirmation: {
      title: [
        'Подтверждение наличия гражданства РФ',
        'Confirmation of Russian citizenship',
        '确认拥有俄罗斯公民身份',
        'Confirmación de la ciudadanía rusa',
      ],
      variants: {
        initialIssue: {
          description: [
            'Для подачи на оформление загранпаспорта вам нужно предоставить документы, содержащие отметку (или вкладыш) с указанием гражданства РФ.',
            'To apply for a foreign passport, you need to provide documents containing a mark (or insert) indicating Russian citizenship.',
            '申请护照时，您需要提供带有表明俄罗斯国籍的印章（或插页）的文件。',
            'Para solicitar la emisión de un pasaporte, debe presentar documentos que contengan un sello (o inserto) que indique la ciudadanía rusa.',
          ],
          checkbox: [
            'У меня есть такой документ',
            'I have such a document',
            '我有这个文件',
            'Tengo este documento',
          ],
        },
        other: {
          description: [
            'Перед подачей документов на оформление загранпаспорта вам нужно пройти процедуру проверки на гражданство РФ.',
            'Before submitting documents for a foreign passport, you need to undergo the procedure for checking Russian citizenship.',
            '在提交护照申请文件之前，您需要进行俄罗斯国籍验证程序。',
            'Antes de presentar documentos para obtener un pasaporte extranjero, debe someterse a un procedimiento para verificar la ciudadanía rusa.',
          ],
          checkbox: [
            'Я прошел проверку на гражданство',
            'I have passed the citizenship check',
            '我已经通过国籍验证',
            'He pasado la verificación de ciudadanía.',
          ],
        },
      },
    },
  },

  differencesBetweenOldAndNew: [
    {
      type: 'title',
      text: [
        'Чем отличаются между собой загранпаспорта сроком на 5 и на 10 лет?',
        'What is the difference between 5-year and 10-year passports?',
        '5年和10年护照有什么区别？',
        '¿Cuál es la diferencia entre los pasaportes de 5 y 10 años?',
      ],
    },
    {
      type: 'list',
      title: ['Срок действия:', 'Validity:', '有效期:', 'Validez:'],
      items: [
        [
          'нового образца — 10 лет',
          'new generation - 10 years',
          '新式护照 - 10年',
          'nuevo modelo - 10 años',
        ],
        [
          'старого образца — 5 лет',
          'old generation - 5 years',
          '旧式护照 - 5年',
          'antiguo modelo - 5 años',
        ],
      ],
    },
    {
      type: 'list',
      title: [
        'Количество страниц (доступное пространство под визы и отметки контроля):',
        'Number of pages (available space for visas and control marks):',
        '页数（可用于签证和控制标记的可用空间）：',
        'Número de páginas (espacio disponible para visas y marcas de control):',
      ],
      items: [
        [
          'нового образца — 46 страниц',
          'new generation - 46 pages',
          '新式护照 - 46页',
          'nuevo modelo - 46 páginas',
        ],
        [
          'старого образца — 36 страниц',
          'old generation - 36 pages',
          '旧式护照 - 36页',
          'antiguo modelo - 36 páginas',
        ],
      ],
    },
    {
      type: 'list',
      title: [
        'Степень защиты:',
        'Protection level:',
        '保护级别:',
        'Nivel de protección:',
      ],
      items: [
        [
          'в паспорта нового образца монтируется микрочип с цветным цифровым изображением лица и папиллярных узоров 2 пальцев рук. Паспорт надёжно защищён от подделки и позволяет быстрее проходить погранконтроль',
          'The new passport contains a microchip with a color digital image of the face and papillary patterns of 2 fingers. The passport is reliably protected from counterfeiting and allows for faster border control.',
          '新护照中包含一个微芯片，带有彩色面部数字图像和两个手指的指纹图案。护照可靠地防止伪造，并可以更快通过边境检查。',
          'El nuevo pasaporte contiene un microchip con una imagen digital en color de la cara y patrones papilares de 2 dedos. El pasaporte está protegido de forma fiable contra la falsificación y permite un control fronterizo más rápido.',
        ],
        [
          'в паспортах старого поколения биометрических данных нет',
          'Old generation passports do not contain biometric data',
          '旧式护照不含生物识别数据。',
          'Los pasaportes de la vieja generación no contienen datos biométricos.',
        ],
      ],
    },
    {
      type: 'list',
      title: [
        'Фото на паспорт:',
        'Passport photo:',
        '护照照片：',
        'Foto de pasaporte:',
      ],
      items: [
        [
          'на паспорт нового образца вас сфотографируют на специальном оборудовании в консульстве, дополнительно приносить с собой фотографии не нужно',
          'The new passport contains a microchip with a color digital image of the face and papillary patterns of 2 fingers. The passport is reliably protected from counterfeiting and allows for faster border control.',
          '新护照将使用特殊设备在领事馆拍照，无需额外带照片。',
          'El nuevo pasaporte se tomará con equipo especial en el consulado, no es necesario traer fotos adicionales.',
        ],
        [
          'для паспорта старого образца нужно принести фото с собой',
          'Old generation passports do not contain biometric data',
          '申请旧护照需自备照片。',
          'Para el pasaporte de la vieja generación, es necesario llevar fotos.',
        ],
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Вписывать ребёнка можно только в паспорта старого образца.',
        'A child can only be entered into old-style passports.',
        '只能将孩子记录在旧式护照中。',
        'Solo se puede incluir a un niño en los pasaportes antiguos.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Дополнительно к уже имеющемуся можно оформить только загранпаспорт нового образца.',
        'In addition to the one you already have, you can only apply for a new-style passport.',
        '除了您已有的护照外，您只能申请新式护照。',
        'Además del que ya tiene, solo puede solicitar un nuevo pasaporte.',
      ],
    },
  ],

  workActivityTips: [
    {
      type: 'title',
      text: [
        'Как указывать данные о работе и учёбе',
        'How to provide information about work and education',
        '如何提供工作和学习信息',
        'Cómo proporcionar información sobre trabajo y educación',
      ],
    },
    {
      type: 'list',
      items: [
        [
          'Укажите все сведения о трудовой деятельности, учёбе и службе за последние 10 лет',
          'Provide all information about employment, education, and service for the last 10 years',
          '提供过去10年的所有就业、教育和服务信息',
          'Proporcione toda la información sobre empleo, educación y servicio de los últimos 10 años.',
        ],
        [
          'Если вы работали, но не вносили сведения в трудовую книжку, их нужно указать по трудовому договору',
          'If you worked but did not enter the information in the work book, you need to provide it according to the employment contract',
          '如果您工作但没有在工作簿中输入信息，则需要根据劳动合同提供该信息',
          'Si trabajó pero no ingresó la información en el libro de trabajo, debe proporcionarla de acuerdo con el contrato de trabajo.',
        ],
        [
          'Если компания находится не в России, напишите страну и адрес русскими буквами',
          'If the company is not located in Russia, write the country and address in Russian letters',
          '如果公司不在俄罗斯，请用俄文写国家和地址',
          'Si la empresa no se encuentra en Rusia, escriba el país y la dirección en letras rusas.',
        ],
        [
          'Для одной организации указывайте все должности и их период. Например: 11.2020–05.2021 — кассир, 05.2021–12.2021 — бухгалтер',
          'For one organization, list all positions and their period. For example: 11.2020–05.2021 — cashier, 05.2021–12.2021 — accountant',
          '对于一个组织，列出所有职位及其任期。例如：11.2020–05.2021 — 收银员，05.2021–12.2021 — 会计',
          'Para una organización, enumere todos los puestos y su período. Por ejemplo: 11.2020–05.2021 — cajero, 05.2021–12.2021 — contador.',
        ],
        [
          'Название должности указывается русскими буквами и в переводе на русский язык',
          'The job title is indicated in Russian letters and translated into Russian',
          '职位名称用俄文和翻译写出',
          'El título del trabajo se indica en letras rusas y se traduce al ruso.',
        ],
        [
          'Если работали в нескольких местах по совместительству, укажите все места работы',
          'If you worked in several places part-time, list all workplaces',
          '如果您在几个地方兼职工作，请列出所有工作地点',
          'Si trabajó en varios lugares a tiempo parcial, enumere todos los lugares de trabajo.',
        ],
        [
          'Если работали и учились одновременно, укажите и место работы, и место учёбы',
          'If you worked and studied at the same time, indicate both the place of work and the place of study',
          '如果您同时工作和学习，请注明工作地点和学习地点',
          'Si trabajó y estudió al mismo tiempo, indique tanto el lugar de trabajo como el lugar de estudio.',
        ],
        [
          'Если находились на длительном больничном, в декрете или отпуске без сохранения заработной платы, укажите место работы, службы или учёбы в это время',
          'If you were on a long sick leave, maternity leave, or unpaid leave, indicate the place of work, service, or study during this time',
          '如果您长时间请病假、产假或无薪假期，请在此期间注明工作、服务或学习的地点',
          'Si estuvo de baja por enfermedad, licencia de maternidad o licencia sin goce de sueldo durante mucho tiempo, indique el lugar de trabajo, servicio o estudio durante ese tiempo.',
        ],
      ],
    },
    {
      type: 'list',
      title: [
        'Где искать информацию:',
        'Where to find information:',
        '在哪里查找信息：',
        'Dónde encontrar información:',
      ],
      items: [
        [
          'ваша трудовая книжка',
          'your work book',
          '您的工作簿',
          'su libro de trabajo',
        ],
        [
          'сайт налоговой (содержит сведения о компании и её адрес)',
          'tax website (contains information about the company and its address)',
          '税务网站（包含公司及其地址信息）',
          'sitio web de impuestos (contiene información sobre la empresa y su dirección)',
        ],
        [
          'справка из ПФР (можно заказать через госуслуги)',
          'certificate from the Pension Fund (can be ordered through public services)',
          '来自养老金基金的证明（可以通过公共服务申请）',
          'certificado del Fondo de Pensiones (se puede solicitar a través de servicios públicos)',
        ],
      ],
    },
  ],
}

export default vocabularyPassport

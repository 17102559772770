const vocabularyConsulting = {
  consulting: ['Консультационные услуги', 'Consulting'],
  text1: [
    'Вы можете запросить персональную консультацию по вопросам подготовки документов, получения справок и прочим услугам в Российских зарубежных консульских учреждениях.',
    'You can request personal advice on issues of preparing documents, obtaining certificates and other services at the Russian foreign consular offices.',
    '您可以请求个人咨询有关准备文件、获取证书和在俄罗斯海外领事馆提供的其他服务的问题。',
    'Puede solicitar asesoría personalizada sobre cuestiones de preparación de documentos, obtención de certificados y otros servicios en las oficinas consulares rusas en el extranjero.',
  ],
  text2: [
    'Консультация платная. Стоимость услуги будет рассчитана в соответствии с запросом.',
    'Consultation is subject to a fee. The price will be calculated according to your request.',
    '咨询是收费的。费用将根据您的请求计算。',
    'La consulta tiene un costo. El precio se calculará según su solicitud.',
  ],
  workflow: {
    requestDetails: {
      title: [
        'Опишите своими словами вашу проблему',
        'Describe your problem in your own words',
        '用自己的话描述您的问题',
        'Describa su problema con sus propias palabras',
      ],
      additionalInfo: [
        'Опишите вашу ситуацию',
        'Describe your situation',
        '描述您的情况',
        'Describa su situación',
      ],
    },
  },
}

export default vocabularyConsulting

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { createPaymentRandom } from 'api/workflow'
import StripeRandom from './components/Workflow/StripeRandom'
import leftArrow from '../images/icon/arrow-left.png'
import crossClose from '../images/icon/cross-close.png'
import { useEffect, useState } from 'react'
import { Button, TextField, Box, Typography } from '@mui/material'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'
import { useNavigate } from 'react-router-dom'

import PaymentMade from './components/Workflow/PaymentMade'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const PaymentRandom = () => {
  const [clientSecret, setClientSecret] = useState(null)
  const [secondPart, setSecondPart] = useState(false)
  const [open, setOpen] = useState(true)
  const [thirdPart, setThirdPart] = useState(false)
  const navigate = useNavigate()
  const [price, setPrice] = useState(0)
  const handleThirdPartOpen = () => {
    setThirdPart(!thirdPart)
  }
  const handleClose = () => {
    setOpen(false)
    navigate('/')
  }

  const initPayment = async () => {
    const secret = await createPaymentRandom({
      price: parseInt(price),
      random: true,
    })
    console.log(secret)
    setClientSecret(secret)
    setSecondPart(true)
  }

  return (
    <DialogModalWindow open={open}>
      {!thirdPart ? (
        <>
          <Box sx={{ mx: 4, width: '100%', height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '76px',
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
                <img src={leftArrow} alt='left arrow' width={'24px'} />
              </Box>

              <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
                <img src={crossClose} alt='cross' width={'24px'} />
              </Box>
            </Box>
            <Typography variant='h4' component='div'>
              RANDOM SUM PAYMENT
            </Typography>
            <Box sx={{ width: '100%', my: 6 }}>
              {secondPart ? (
                <>
                  <Typography
                    sx={{ textTransform: 'uppercase' }}
                    element='div'
                    variant='h5'
                    color='secondary'
                  >
                    to pay :{' '}
                    {clientSecret.amount / 100 + ' ' + clientSecret.currency}
                  </Typography>
                  <Elements
                    stripe={stripePromise}
                    options={{ clientSecret: clientSecret.client_secret }}
                  >
                    <StripeRandom handleThirdPartOpen={handleThirdPartOpen} />
                  </Elements>
                </>
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    m: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '400px',
                  }}
                >
                  <TextField
                    value={price}
                    sx={{ width: '100%', mb: 6 }}
                    onChange={event => {
                      setPrice(event.target.value)
                    }}
                    label='Amount in USD'
                    variant='outlined'
                    margin='normal'
                    type='number'
                  />
                  <Button
                    onClick={initPayment}
                    variant='contained'
                    color='primary'
                  >
                    Pay
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <PaymentMade handleClose={handleClose} />
      )}
    </DialogModalWindow>
  )
}

export default PaymentRandom

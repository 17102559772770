import { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import vocabulary from '../../vocabulary/vocabulary'
import Layout599 from '../Layouts/Layout599'
import GlobalContext from '../../GlobalContext'
import Typography from '@mui/material/Typography'
import { Box, Card } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import vocabularyFaq from 'vocabulary/vocabularyFaq'
import vocabularyPowerOfAttorney from 'vocabulary/vocabularyPowerOfAttorney'
import arrowRightImg from '../../images/arrow-black-right.png'
import vocabularyConsent from 'vocabulary/vocabularyConsent'
import AccordionCustom from 'pages/components/AccordionCustom'
import vocabularyCertificates from 'vocabulary/vocabularyCertificates'
import vocabularyLegalEntities from 'vocabulary/vocabularyLegalEntities'
import vocabularyCitizenship from 'vocabulary/vocabularyCitizenship'
import vocabularyZags from 'vocabulary/vocabularyZags'
import vocabularyOther from 'vocabulary/vocabularyOther'
import vocabularyPassport from 'vocabulary/vocabularyPassport'
import { useNavigate } from 'react-router-dom'

export default function FaqPage599() {
  const { langSelected } = useContext(GlobalContext)
  const [pageActive, setPageActive] = useState(0)
  const [menuActive, setMenuActive] = useState(null)
  const [headerMenu, setHeaderMenu] = useState('')
  const theme = useTheme()

  console.log(menuActive, 'menu active')
  const navigate = useNavigate()

  const handleClick = (index, header) => {
    switch (index) {
      case 0:
        navigate('/about')
        break

      case 1:
        setMenuActive(vocabularyPowerOfAttorney.faqList)
        break
      case 2:
        setMenuActive(vocabularyPassport.faqList)
        break
      case 3:
        setMenuActive(vocabularyConsent.faq)
        break
      case 4:
        setMenuActive(vocabularyCertificates.faq)
        break
      case 5:
        setMenuActive(vocabularyLegalEntities.faq)
        break
      case 6:
        setMenuActive(vocabularyCitizenship.faq)
        break
      case 7:
        setMenuActive(vocabularyZags.faq)
        break
      case 8:
        setMenuActive(vocabularyOther.faq)
        break

      default:
    }

    setHeaderMenu(header)
    setPageActive(index)
  }

  return (
    <Layout599 theme={theme} menu='true'>
      <Typography variant='h1Small' component='p' sx={{ mx: 2, mt: 10.5 }}>
        {vocabularyFaq.faq[langSelected]}
      </Typography>

      {pageActive === 0 ? (
        <Box sx={{ mx: 2, mb: 6.5 }}>
          <Box sx={{ mb: '48px' }}></Box>
          <Typography variant='titleLarge' component='p' sx={{ mb: 4 }}>
            {vocabularyFaq.text1[langSelected]}
          </Typography>

          {vocabularyFaq.typesOfFaq?.map((val, index) => {
            if (val) {
              return (
                <Box
                  key={uuidv4()}
                  sx={{
                    borderTop:
                      !index > 0
                        ? '1px solid ' + theme.palette.primary.outlineVariant
                        : 'none',
                    borderBottom:
                      '1px solid ' + theme.palette.primary.outlineVariant,
                    backgroundColor: theme.palette.primary.surface,
                    width: '100%',
                    height: '48px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    handleClick(index, val[langSelected])
                  }}
                >
                  <Typography variant='labelLarge'>
                    {val[langSelected]}
                  </Typography>

                  <Box>
                    <img
                      src={arrowRightImg}
                      alt='logo'
                      width={20}
                      style={{ marginTop: '4px', marginRight: '16px' }}
                    />
                  </Box>
                </Box>
              )
            }
          })}
        </Box>
      ) : (
        <Box sx={{ mt: '56px', mx: '16px', mb: 6.5 }}>
          <Typography variant='h2Small' component='p' sx={{ mb: 4 }}>
            {headerMenu}
          </Typography>

          {menuActive.map((val, index) => {
            return val ? (
              <AccordionCustom
                key={uuidv4()}
                index={index}
                val={val}
                langSelected={langSelected}
                faq={true}
              />
            ) : null
          })}

          <Button
            variant='outlined'
            size='medium'
            sx={{
              width: '328px',
              borderColor: 'black',
              height: '40px',
              mx: 'auto',
              mt: 6.5,
              mb: 2,
            }}
            color='blueButton'
            onClick={() => {
              navigate(`${window.location.pathname}?open=chat`)
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabulary.ask[langSelected]}
            </Typography>
          </Button>

          <Card
            onClick={() => {
              setPageActive(0)
              setMenuActive(null)
              setHeaderMenu('')
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
            sx={{
              mt: '32px',
              mb: '50px',
              px: 2,
              pt: '8px',
              height: 70,
              borderRadius: '12px',
              flexGrow: 1,
              backgroundColor: theme.palette.primary.surfaceContainer,
            }}
            variant='outlined'
          >
            <Typography
              variant='labelSmall'
              component='div'
              sx={{ color: theme.palette.primary.onSurfaceVariant }}
            >
              &larr; {vocabularyPassport.back[langSelected]}
            </Typography>
            <Typography variant='titleSmall'>
              {vocabularyPassport.faq[langSelected]}
            </Typography>
          </Card>
        </Box>
      )}
    </Layout599>
  )
}

import * as React from 'react'
import GlobalContext from '../../../GlobalContext'
import CountrySelection from '../Workflow/CountrySelection.js'
import DialogPoaWorkflow3 from './DialogPoaFlow/DialogPoaWorkflow3.js'
import DialogPoaWorkflow4 from './DialogPoaFlow/DialogPoaWorkflow4.js'
import DialogPoaWorkflow5 from './DialogPoaFlow/DialogPoaWorkflow5.js'
import DialogPoaWorkflow6 from './DialogPoaFlow/DialogPoaWorkflow6.js'
// Don't need step with documents now, will be added in future
// import DialogPoaWorkflow8 from './DialogPoaFlow/DialogPoaWorkflow8.js'
import Payment from '../Workflow/Payment'
import RequestSuccessSent from '../Workflow/RequestSuccessSent.js'
import DialogPoaWorkflow2 from './DialogPoaFlow/DialogPoaWorkflow2.js'
import DialogPoaWorkflow0 from './DialogPoaFlow/DialogPoaWorkflow0.js'
import vocabularyPowerOfAttorney from 'vocabulary/vocabularyPowerOfAttorney'
import CheckoutForm from '../Workflow/CheckoutForm'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'
import GetUserDetails from '../Workflow/GetUsersDetails'

export default function DialogPoa() {
  const { state } = React.useContext(GlobalContext)
  const open = state.dialogService.section === 'poa'

  const price = {
    withAppointment: vocabularyPowerOfAttorney.priceWithAppointment[1].value,
    withoutAppointment:
      vocabularyPowerOfAttorney.priceWithoutAppointment[1].value,
  }

  return (
    <DialogModalWindow open={open}>
      {state.dialogService.step === 0 ? <DialogPoaWorkflow0 /> : null}
      {state.dialogService.step === 1 ? <DialogPoaWorkflow2 /> : null}
      {state.dialogService.step === 2 ? (
        <CountrySelection
          nextStep={(values, { service }) => {
            return service === 'Revocation of Power of Attorney' ? 8 : 3
          }}
        />
      ) : null}
      {state.dialogService.step === 3 ? <DialogPoaWorkflow3 /> : null}
      {state.dialogService.step === 4 ? <DialogPoaWorkflow4 /> : null}
      {state.dialogService.step === 5 ? <DialogPoaWorkflow5 /> : null}
      {state.dialogService.step === 6 ? <DialogPoaWorkflow6 /> : null}
      {state.dialogService.step === 7 ? <GetUserDetails /> : null}
      {/* {state.dialogService.step === 8 ? <DialogPoaWorkflow8 /> : null} */}
      {state.dialogService.step === 8 ? <Payment price={price} /> : null}
      {state.dialogService.step === 9 ? <CheckoutForm price={price} /> : null}
      {state.dialogService.step === 10 ? <RequestSuccessSent /> : null}
    </DialogModalWindow>
  )
}

import { Buildings, FileText, Bank, Gavel } from '@phosphor-icons/react'
import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'

export const serviceTypes = [
  ['Доверенность', 'Power of Attorney', '授权书', 'Poder notarial'],
  [
    'Согласие от супруга',
    'Spousal Consent',
    '配偶同意书',
    'Consentimiento del cónyuge',
  ],
  ['Заявление в налоговую', 'Tax Application', '税务申请', 'Solicitud fiscal'],
]

const powerOfAttorneyTypes = [
  ['Генеральная', 'General', '一般', 'General'],
  [
    'На покупку или продажу доли ООО',
    'For Buying or Selling a Share of an LLC',
    '购买或出售有限责任公司股份的授权书',
    'Para la compra o venta de una participación de una LLC',
  ],
  [
    'На представительство в суде',
    'For Representation in Court',
    '出庭代表',
    'Para representación en el tribunal',
  ],
  [
    'На представительство в госорганах',
    'For Representation in Government Agencies',
    '在政府机构中的代表',
    'Para representación en organismos gubernamentales',
  ],
]

const consentTypes = [
  [
    'От супруга на покупку доли в ООО',
    'From Spouse to Buy Shares',
    '配偶购买股份的同意书',
    'De cónyuge para comprar acciones',
  ],
  [
    'От супруга на продажу доли в ООО',
    'From Spouse to Sell Shares',
    '配偶出售股份的同意书',
    'De cónyuge para vender acciones',
  ],
  [
    'От супруга на изменение доли в ООО',
    'From Spouse to Change Shares',
    '配偶变更股份的同意书',
    'De cónyuge para cambiar acciones',
  ],
  [
    'От супруга на дарение доли в ООО',
    'From Spouse to Give Shares',
    '配偶赠与股份的同意书',
    'De cónyuge para donar acciones',
  ],
  [
    'От супруга на залог доли в ООО',
    'From Spouse to Deduct Property',
    '配偶抵押股份的同意书',
    'De cónyuge para deducir la propiedad',
  ],
]

const applicationTypes = [
  [
    'На регистрацию юридического лица',
    'For Registration of a Legal Entity',
    '注册法人',
    'Para la inscripción de una entidad jurídica',
  ],
  [
    'На внесение изменений',
    'For Making Changes',
    '更改申请',
    'Para hacer cambios',
  ],
  [
    'На реорганизацию юридического лица',
    'For Reorganization of a Legal Entity',
    '重组法人',
    'Para la reorganización de una entidad jurídica',
  ],
]

const vocabularyLegalEntities = {
  headerText: [
    'Документы для юридических лиц',
    'Documents for legal entities',
    '法人文件',
    'Documentos para entidades jurídicas',
  ],
  infoText1: [
    'Находясь за рубежом и имея при этом бизнес в России, для взаимодействия с госорганами внутри страны вам может потребоваться оформить, например, доверенность на вашего представителя в РФ или заявление в налоговую.',
    'Being abroad and having a business in Russia, in order to interact with government agencies within the country, you may need to issue, for example, a Power of Attorney for your representative in the Russian Federation or an application to the tax office.',
    '在海外且在俄罗斯有业务，为与国内政府机构互动，您可能需要出具例如在俄罗斯联邦的代表的授权书或提交税务申请。',
    'Estando en el extranjero y teniendo un negocio en Rusia, para interactuar con las agencias gubernamentales dentro del país, es posible que necesite emitir, por ejemplo, un poder para su representante en la Federación Rusa o una solicitud a la oficina tributaria.',
  ],
  infoText2: [
    'Такие документы можно получить в ближайшем к вам Российском консульстве. Однако сотрудники консульства не занимаются составлением текстов, только заверяют заранее подготовленные в специальном формате проекты документов.',
    'Such documents can be obtained from the Russian consulate closest to you. However, consulate staff are not involved in drafting texts; they only certify prepared draft documents in a special format.',
    '此类文件可以在离您最近的俄罗斯领事馆获得。然而，领事馆工作人员不负责起草文本；他们只认证已经准备好的特殊格式文件草稿。',
    'Tales documentos se pueden obtener en el consulado ruso más cercano a usted. Sin embargo, el personal del consulado no participa en la redacción de textos; solo certifican los borradores de documentos preparados en un formato especial.',
  ],
  infoText3: [
    'Мы поможем вам подготовить такой проект документа и разместим его в нужном формате на консульском портале, а также проконсультируем по поводу записи на приём в консульство или запишем вас сами.',
    'We will help you prepare such a draft document and upload it in the required format to the consulate portal, and also advise you on making an appointment at the consulate or will make an appointment for you.',
    '我们将帮助您准备这样的文件草稿并将其上传到领事门户所需的格式，并为您提供关于预约领事馆的建议或直接为您预约。',
    'Le ayudaremos a preparar dicho borrador de documento y a cargarlo en el formato requerido en el portal consular, además de asesorarle sobre cómo hacer una cita en el consulado o hacer una cita por usted.',
  ],
  typesOfDocuments: [
    {
      section: [
        'Доверенности:',
        'Consular certificates',
        '授权书',
        'Certificados consulares',
      ],
      types: [
        {
          id: 0,
          service: serviceTypes[0][1],
          additionalServiceType: powerOfAttorneyTypes[0][1],
          text: ['Генеральная', 'General', '一般', 'General'],
          icon: <FileText size={24} weight='light' />,
        },
        {
          id: 1,
          service: serviceTypes[0][1],
          additionalServiceType: powerOfAttorneyTypes[1][1],
          text: [
            'На покупку или продажу доли ООО',
            'For the purchase or sale of a share in an LLC',
            '购买或出售有限责任公司股份',
            'Para la compra o venta de una participación en una LLC',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
        {
          id: 2,
          service: serviceTypes[0][1],
          additionalServiceType: powerOfAttorneyTypes[2][1],
          text: [
            'На представительство в суде',
            'For representation in court',
            '法院代表',
            'Para representación en el tribunal',
          ],
          icon: <Gavel size={24} weight='light' />,
        },
        {
          id: 3,
          service: serviceTypes[0][1],
          additionalServiceType: powerOfAttorneyTypes[3][1],
          text: [
            'На представительство в госорганах',
            'For representation in government agencies',
            '政府机构的代表',
            'Para representación en organismos gubernamentales',
          ],
          icon: <Bank size={24} weight='light' />,
        },
      ],
    },
    {
      section: ['Согласия:', 'Consents:', '同意书', 'Consentimientos:'],
      types: [
        {
          id: 4,
          service: serviceTypes[1][1],
          text: [
            'Супруга на сделку с долей ООО',
            'Consent of a spouse for a transaction with a share of an LLC',
            '配偶对有限责任公司股份交易的同意',
            'Consentimiento del cónyuge para una transacción con una participación de una LLC',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
      ],
    },
    {
      section: [
        'Заявления в налоговую:',
        'Applications to the tax office:',
        '税务申请:',
        'Solicitudes a la oficina de impuestos:',
      ],
      types: [
        {
          id: 5,
          service: serviceTypes[2][1],
          additionalServiceType: applicationTypes[0][1],
          text: [
            'На регистрацию юридического лица',
            'For registration of a legal entity',
            '注册法人',
            'Para el registro de una entidad jurídica',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
        {
          id: 6,
          service: serviceTypes[2][1],
          additionalServiceType: applicationTypes[1][1],
          text: [
            'На внесение изменений',
            'For making changes',
            '更改申请',
            'Para hacer cambios',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
        {
          id: 7,
          service: serviceTypes[2][1],
          additionalServiceType: applicationTypes[2][1],
          text: [
            'На реорганизацию юридического лица',
            'For reorganization of a legal entity',
            '重组法人',
            'Para la reorganización de una entidad jurídica',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
      ],
    },
  ],

  orderDescription: [
    [
      {
        headerText: 'Оформление заявки',
        cardText:
          'Опишите, что должно содержаться в документе. Вы можете направить нам требуемые документы как одновременно с заявкой, так и напрямую юристу после консультации с ним',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Подготовка текста документа',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали, подготовит проект документа и разместит его на портале КД МИД России. Вы получите номер размещения, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Получение готового документа',
        cardText:
          'В день записи приходите в консульство с паспортом и номером размещения для заверения подготовленного документа',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submitting an application',
        cardText:
          'Describe for what purposes the certificate is required. You can send us the required documents either simultaneously with the application or directly to the lawyer after consulting with him.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparation of Document Text',
        cardText:
          'Our lawyer will contact you, discuss the details, prepare a draft document and post it on the CD portal of the Russian Foreign Ministry. You will receive an accommodation number, which you will need to take with you to the consulate. The service completion time is 2 business days from the date of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Appointment Scheduling at the Consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance (according to the selected tariff)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Receipt of the Ready Document',
        cardText:
          'On the day of registration, come to the consulate with your passport and accommodation number to certify the prepared document',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: '提交申请',
        cardText:
          '描述证明的用途。您可以随申请一并发送所需文件，或在咨询后直接发送给律师。',
        cardImage: checkBoxImage,
      },
      {
        headerText: '准备文本文档',
        cardText:
          '我们的律师将与您联系，讨论详情，准备草稿并将其发布在俄罗斯外交部的CD门户网站上。您将收到一个编号，需随身带到领事馆。服务完成时间为付款后的2个工作日。',
        cardImage: documentBrown,
      },
      {
        headerText: '在领事馆预约',
        cardText: '您可以自己预约领事馆的会面，或根据所选套餐获得我们的协助',
        cardImage: calendarImage,
      },
      {
        headerText: '接收准备好的文档',
        cardText:
          '在约定的日期，携带您的护照和编号前往领事馆，以认证准备好的文件',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Presentación de solicitud',
        cardText:
          'Describa los propósitos del certificado. Puede enviarnos los documentos requeridos junto con la solicitud o directamente al abogado después de la consulta.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparación del texto del documento',
        cardText:
          'Nuestro abogado se pondrá en contacto con usted, discutirá los detalles, preparará un borrador y lo publicará en el portal CD del Ministerio de Relaciones Exteriores de Rusia. Recibirá un número de ubicación que deberá llevar al consulado. El tiempo de finalización del servicio es de 2 días hábiles desde la fecha de pago.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Programación de cita en el consulado',
        cardText:
          'Programe una cita en el consulado usted mismo o con nuestra asistencia (según la tarifa seleccionada)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Recepción del documento listo',
        cardText:
          'El día de la cita, acuda al consulado con su pasaporte y número de ubicación para certificar el documento preparado',
        cardImage: stampImage,
      },
    ],
  ],

  infoText4: [
    'При удостоверении доверенности (заявления) от имени юридического лица или от имени его представителя консульский работник обязан проверить их правоспособность.',
    'When certifying a Power of Attorney (application) on behalf of a legal entity or on behalf of its representative, the consular officer is obliged to check their legal capacity.',
    '在以法人或其代表名义认证授权书（申请）时，领事官员有义务核查其法律能力。',
    'Al certificar un poder (solicitud) en nombre de una entidad legal o en nombre de su representante, el funcionario consular está obligado a verificar su capacidad legal.',
  ],
  infoText5: [
    'Базовый пакет документов, который для этих целей потребуется предоставить в консульство:',
    'A basic package of documents that will need to be submitted to the consulate for these purposes:',
    '为此目的需要提交给领事馆的基本文件包：',
    'Un paquete básico de documentos que deberá ser presentado en el consulado para estos fines:',
  ],
  userTypes: [
    {
      userName: [
        'Юридические лица',
        'Legal entities',
        '法人',
        'Entidades legales',
      ],
      desc: [
        [
          'Выписка из ЕГРЮЛ',
          'Extract from the Unified State Register of Legal Entities',
          '统一国家法人登记册摘录',
          'Extracto del Registro Estatal Unificado de Entidades Legales',
        ],
        [
          'Свидетельство ОГРН, ИНН, КПП',
          'Certificate of OGRN, INN, KPP',
          'OGRN, INN, KPP 证书',
          'Certificado de OGRN, INN, KPP',
        ],
        [
          'Решение и приказ о назначении директора',
          'Decision and order on the appointment of a director',
          '任命董事的决定和命令',
          'Decisión y orden sobre el nombramiento de un director',
        ],
        [
          'Паспортные данные директора',
          "Director's passport details",
          '董事护照信息',
          'Detalles del pasaporte del director',
        ],
      ],
    },
    {
      userName: [
        'Учредитель (участник, акционер)',
        'Founder (participant, shareholder)',
        '创始人（参与者，股东）',
        'Fundador (participante, accionista)',
      ],
      desc: [
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ представителя (учредителя, участника, акционера)',
          'Internal passport of the Russian Federation and/or international passport of the Russian Federation of the representative (founder, participant, shareholder)',
          '俄罗斯联邦的内部护照和/或国际护照（创始人、参与者、股东的代表）',
          'Pasaporte interno de la Federación Rusa y/o pasaporte internacional de la Federación Rusa del representante (fundador, participante, accionista)',
        ],
        [
          'СНИЛС (если имеется)',
          'SNILS (if available)',
          'SNILS (如果有)',
          'SNILS (si está disponible)',
        ],
        [
          'Устав Организации и его изменения, если имели место быть',
          'The Charter of the Organization and its changes, if any',
          '组织章程及其变更（如果有）',
          'Estatutos de la Organización y sus cambios, si los hubiera',
        ],
        [
          'Выписка из ЕГРЮЛ',
          'Extract from the Unified State Register of Legal Entities',
          '统一国家法人登记册摘录',
          'Extracto del Registro Estatal Unificado de Entidades Legales',
        ],
      ],
    },
  ],
  infoText6: [
    'Для оформления нотариальных документов приоритет отдаётся внутреннему паспорту. В случае, если внутренний паспорт не оформлялся или просрочен — потребуется действующий загранпаспорт. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
    'For the preparation of notarial documents, priority is given to the internal passport. If your internal passport has not been issued or is expired, you will need a valid international passport. If you have both passports, then you need to take two passports to your appointment at the consulate.',
    '在准备公证文件时，以内部护照为优先。如果内部护照未签发或已过期，则需要有效的国际护照。如果同时持有两本护照，则需带两本护照到领事馆。',
    'Para la preparación de documentos notariales, se da prioridad al pasaporte interno. Si su pasaporte interno no ha sido emitido o está caducado, necesitará un pasaporte internacional válido. Si tiene ambos pasaportes, debe llevar ambos a su cita en el consulado.',
  ],
  infoText7: [
    'Все документы предоставляются в подлинниках + копии, заверенные юридическим лицом.',
    'All documents are provided in originals + copies certified by a legal entity.',
    '所有文件需提供原件+法人认证的副本。',
    'Todos los documentos se presentan en originales + copias certificadas por una entidad legal.',
  ],
  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '60',
    },
    {
      header: 'Without appointment at the consulate',
      list: [
        'Prepare document draft',
        'Submit document on the consulate portal',
        'Consult on the list of documents required for notarization',
        'Provide consultation on scheduling an appointment at the consulate independently',
      ],
      value: '60',
    },
    {
      header: '无需预约领事馆',
      list: [
        '准备文件草案',
        '在领事馆门户上提交文件',
        '咨询所需公证文件清单',
        '提供预约领事馆的独立咨询服务',
      ],
      value: '60',
    },
    {
      header: 'Sin cita en el consulado',
      list: [
        'Preparar borrador del documento',
        'Presentar documento en el portal del consulado',
        'Asesorar sobre los documentos requeridos para la notarización',
        'Asesorar sobre la programación independiente de la cita en el consulado',
      ],
      value: '60',
    },
  ],
  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Запишем вас на приём в консульство',
      ],
      value: '80',
    },
    {
      header: 'With appointment at the consulate',
      list: [
        'Prepare document draft',
        'Submit document on the consulate portal',
        'Consult on the list of documents required for notarization',
        'Schedule an appointment for you at the consulate',
      ],
      value: '80',
    },
    {
      header: '需要预约领事馆',
      list: [
        '准备文件草案',
        '在领事馆门户上提交文件',
        '咨询所需公证文件清单',
        '为您预约领事馆时间',
      ],
      value: '80',
    },
    {
      header: 'Con cita en el consulado',
      list: [
        'Preparar borrador del documento',
        'Presentar documento en el portal del consulado',
        'Asesorar sobre los documentos requeridos para la notarización',
        'Programar una cita para usted en el consulado',
      ],
      value: '80',
    },
  ],
  faqMobile: [
    {
      userName: [
        'Можно ли в доверенности указать несколько доверенных лиц?',
        'Is it possible to specify several authorized persons in a Power of Attorney?',
        '在授权书中可以指定多名授权人吗？',
        '¿Es posible especificar varias personas autorizadas en un poder?',
      ],
      desc: [
        [
          'Да, доверенности могут быть оформлены от имени одного или нескольких лиц, на имя одного или нескольких лиц.',
          'Yes, a Power of Attorney can be issued on behalf of one or several persons, to the name of one or several persons.',
          '是的，授权书可以以一个或多个人的名义发出，给一个或多个人。',
          'Sí, un poder puede emitirse en nombre de una o varias personas, para el nombre de una o varias personas.',
        ],
      ],
    },
    {
      userName: [
        'Возможно ли в Российском консульстве оформить доверенность для представления интересов в Казахстане или Белоруссии?',
        'Is it possible to issue a Power of Attorney at the Russian Consulate for representation of interests in Kazakhstan or Belarus?',
        '在俄罗斯领事馆可以为在哈萨克斯坦或白俄罗斯的利益代表签发授权书吗？',
        '¿Es posible emitir un poder en el consulado ruso para representar intereses en Kazajistán o Bielorrusia?',
      ],
      desc: [
        [
          'Да. В соответствии с Конвенцией о правовой помощи, участниками которой являются Россия и Казахстан, Беларусь, Таджикистан, Грузия и др., доверенность, оформленная в Консульстве России, принимается госорганами и учреждениями этих стран, без дополнительного удостоверения (апостиля)',
          'Yes. In accordance with the Convention on Legal Assistance, of which Russia, Kazakhstan, Belarus, Tajikistan, Georgia, and others are participants, a Power of Attorney issued at the Russian Consulate is accepted by the government bodies and institutions of these countries without additional certification (apostille).',
          '是的。根据法律援助公约（俄罗斯、哈萨克斯坦、白俄罗斯、塔吉克斯坦、格鲁吉亚等是参与国），在俄罗斯领事馆签发的授权书被这些国家的政府机构和机构接受，无需额外认证（认证书）。',
          'Sí. De acuerdo con la Convención sobre Asistencia Jurídica, de la cual Rusia, Kazajistán, Bielorrusia, Tayikistán, Georgia y otros son participantes, un poder emitido en el Consulado ruso es aceptado por los organismos e instituciones gubernamentales de estos países sin certificación adicional (apostilla).',
        ],
      ],
    },
    {
      userName: [
        'На какой максимальный срок можно оформить доверенность?',
        'What is the maximum duration for which a Power of Attorney can be issued?',
        '授权书可以签发的最长时间是多久？',
        '¿Cuál es la duración máxima para la que se puede emitir un poder?',
      ],
      desc: [
        [
          'Вы вправе оформить доверенность с любым сроком действия. Вместе c тем в доверенности должен быть указан конкретный срок её действия, удостоверение «бессрочных» доверенностей не допускается.',
          'You are entitled to issue a Power of Attorney for any duration. However, the Power of Attorney must specify a specific duration of its validity; certification of "indefinite" Powers of Attorney is not allowed.',
          '您可以签发任何期限的授权书。然而，授权书必须指定有效期；不允许签发“无限期”授权书。',
          'Tiene derecho a emitir un poder por cualquier duración. Sin embargo, el poder debe especificar una duración específica de su validez; no se permite la certificación de poderes "indefinidos".',
        ],
      ],
    },
    {
      userName: [
        'Когда требуется согласие супруга на сделку?',
        'When is the consent of the spouse required for a transaction?',
        '在什么情况下交易需要配偶的同意？',
        '¿Cuándo se requiere el consentimiento del cónyuge para una transacción?',
      ],
      desc: [
        [
          'Согласие требуется в каждом случае, когда речь идёт о совместно нажитом в браке имуществе.',
          'Consent is required in every case when it comes to property acquired jointly during marriage.',
          '当涉及婚姻期间共同拥有的财产时，每种情况都需要同意。',
          'El consentimiento es necesario en cada caso cuando se trata de bienes adquiridos en matrimonio.',
        ],
        [
          'К нему не относятся объекты, приобретённые одним из супругов до брака, полученные в дар или по наследству.',
          'This does not apply to objects acquired by one of the spouses before marriage, received as a gift, or through inheritance.',
          '这不适用于婚前一方配偶获得的财产，或作为礼物或继承而获得的财产。',
          'Esto no se aplica a objetos adquiridos por uno de los cónyuges antes del matrimonio, recibidos como regalo o a través de herencia.',
        ],
      ],
    },
  ],
  faqDesc: [
    [
      {
        question: 'Можно ли в доверенности указать несколько доверенных лиц?',
        answer:
          'Да, доверенности могут быть оформлены от имени одного или нескольких лиц, на имя одного или нескольких лиц.',
      },
      {
        question:
          'Возможно ли в Российском консульстве оформить доверенность для представления интересов в Казахстане или Белоруссии?',
        answer:
          'Да. В соответствии с Конвенцией о правовой помощи, участниками которой являются Россия и Казахстан, Беларусь, Таджикистан, Грузия и др., доверенность, оформленная в Консульстве России, принимается госорганами и учреждениями этих стран, без дополнительного удостоверения (апостиля)',
      },
      {
        question: 'На какой максимальный срок можно оформить доверенность?',
        answer:
          'Вы вправе оформить доверенность с любым сроком действия. Вместе c тем в доверенности должен быть указан конкретный срок её действия, удостоверение «бессрочных» доверенностей не допускается.',
      },
      {
        question: 'Когда требуется согласие супруга на сделку?',
        answer:
          'К нему не относятся объекты, приобретённые одним из супругов до брака, полученные в дар или по наследству.',
        answer2:
          'К нему не относятся объекты, приобретённые одним из супругов до брака, полученные в дар или по наследству.',
      },
    ],
    [
      {
        question:
          'Is it possible to specify several authorized persons in a Power of Attorney?',
        answer:
          'Yes, a Power of Attorney can be issued on behalf of one or several persons, to the name of one or several persons.',
      },
      {
        question:
          'Is it possible to issue a Power of Attorney at the Russian Consulate for representation of interests in Kazakhstan or Belarus?',
        answer:
          'Yes. In accordance with the Convention on Legal Assistance, of which Russia, Kazakhstan, Belarus, Tajikistan, Georgia, and others are participants, a Power of Attorney issued at the Russian Consulate is accepted by the government bodies and institutions of these countries without additional certification (apostille).',
      },
      {
        question:
          'What is the maximum duration for which a Power of Attorney can be issued?',
        answer:
          'You are entitled to issue a Power of Attorney for any duration. However, the Power of Attorney must specify a specific duration of its validity; certification of "indefinite" Powers of Attorney is not allowed.',
      },
      {
        question:
          'When is the consent of the spouse required for a transaction?',
        answer:
          'This does not apply to objects acquired by one of the spouses before marriage, received as a gift, or through inheritance.',
        answer2:
          'This does not apply to objects acquired by one of the spouses before marriage, received as a gift, or through inheritance.',
      },
    ],
    [
      {
        question: '是否可以在授权书中指定多个授权人？',
        answer:
          '是的，授权书可以以一个人或几个人的名义签发，授予一个人或几个人。',
      },
      {
        question:
          '在俄罗斯领事馆是否可以签发授权书，以代表在哈萨克斯坦或白俄罗斯的利益？',
        answer:
          '是的。根据俄罗斯、哈萨克斯坦、白俄罗斯、塔吉克斯坦、格鲁吉亚等国家参与的法律援助公约，在俄罗斯领事馆签发的授权书将被这些国家的政府机构和组织接受，无需额外的认证（公证）。',
      },
      {
        question: '授权书可以签发的最长期限是什么？',
        answer:
          '您有权签发任何期限的授权书。然而，授权书必须指定其有效期的具体期限；不允许认证“无限期”授权书。',
      },
      {
        question: '在什么情况下需要配偶同意进行交易？',
        answer:
          '这不适用于在婚前由一方配偶获得的财产、赠与或通过继承获得的财产。',
        answer2:
          '这不适用于在婚前由一方配偶获得的财产、赠与或通过继承获得的财产。',
      },
    ],
    [
      {
        question:
          '¿Es posible especificar varias personas autorizadas en un poder notarial?',
        answer:
          'Sí, un poder notarial puede ser emitido en nombre de una o varias personas, a nombre de una o varias personas.',
      },
      {
        question:
          '¿Es posible emitir un poder notarial en el consulado ruso para la representación de intereses en Kazajistán o Bielorrusia?',
        answer:
          'Sí. De acuerdo con la Convención sobre Asistencia Legal, de la cual Rusia, Kazajistán, Bielorrusia, Tayikistán, Georgia y otros son participantes, un poder notarial emitido en el consulado ruso es aceptado por los organismos gubernamentales e instituciones de estos países sin certificación adicional (apostilla).',
      },
      {
        question:
          '¿Cuál es la duración máxima para la que se puede emitir un poder notarial?',
        answer:
          'Tienes derecho a emitir un poder notarial por cualquier duración. Sin embargo, el poder notarial debe especificar una duración concreta de su validez; no se permite la certificación de poderes notariales "indefinidos".',
      },
      {
        question:
          '¿Cuándo se requiere el consentimiento del cónyuge para una transacción?',
        answer:
          'Esto no se aplica a los objetos adquiridos por uno de los cónyuges antes del matrimonio, recibidos como regalo o a través de herencia.',
        answer2:
          'Esto no se aplica a los objetos adquiridos por uno de los cónyuges antes del matrimonio, recibidos como regalo o a través de herencia.',
      },
    ],
  ],
  interactuation: [
    [
      {
        type: 'title',
        text: [
          'Генеральная доверенность',
          'General Power of Attorney.',
          '一般授权书',
          'Poder General.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Общая (генеральная) доверенность позволяет совершать любые по характеру действия перед любыми третьими лицами.',
          'A general Power of Attorney allows performing any actions of any nature before any third parties.',
          '一般授权书允许在任何情况下对任何第三方进行任何行为。',
          'Un poder general permite realizar cualquier acción de cualquier naturaleza ante terceros.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Общая доверенность даёт представителю возможность совершать разнообразные сделки по управлению имуществом, например:',
          'A general Power of Attorney gives the representative the ability to perform various transactions for managing property, such as:',
          '一般授权书使代表能够进行各种管理财产的交易，例如：',
          'Un poder general permite al representante realizar diversas transacciones para la gestión de propiedades, como:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'осуществлять всю административно-хозяйственную и оперативно-торговую деятельность компании',
            'to carry out all administrative, economic, and operational-trading activities of the company',
            '开展公司的所有行政、经济和运营贸易活动',
            'realizar todas las actividades administrativas, económicas y comerciales de la empresa',
          ],
          [
            'управлять делами, имуществом и средствами компании, в частности, заключать договора, сделки, подписывать акты, расчётно-денежные документы',
            'to manage the affairs, property, and funds of the company, in particular, to conclude contracts, transactions, sign acts, and financial documents',
            '管理公司的事务、财产和资金，特别是签订合同、交易、签署文件和财务文件',
            'gestionar los asuntos, propiedades y fondos de la empresa, en particular, celebrar contratos, transacciones, firmar actos y documentos financieros',
          ],
          [
            'представлять интересы во всех государственных учреждениях, предприятиях и в суде',
            'to represent interests in all government institutions, enterprises, and in court',
            '在所有政府机构、企业和法庭上代表利益',
            'representar intereses en todas las instituciones gubernamentales, empresas y en los tribunales',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Доверенность на покупку или продажу доли общества',
          'Power of attorney for the purchase or sale of a share in a company.',
          '授权书用于购买或出售公司股份',
          'Poder para la compra o venta de una participación en una empresa.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Какие полномочия может включать:',
          'What powers it may include:',
          '可能包括哪些权力：',
          'Qué poderes puede incluir:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'представлять интересы доверителя в различных госучреждениях, подавать заявления и запрашивать от его имени документы',
            'to represent the interests of the principal in various government institutions, submit applications, and request documents on their behalf',
            '在各种政府机构中代表委托人的利益，提交申请并代表其请求文件',
            'representar los intereses del mandante en diversas instituciones gubernamentales, presentar solicitudes y solicitar documentos en su nombre',
          ],
          [
            'проводить от его имени переговоры и решать все возникающие спорные вопросы',
            'to conduct negotiations on their behalf and resolve all arising disputes',
            '代表其进行谈判并解决所有出现的争议',
            'realizar negociaciones en su nombre y resolver todas las disputas que surjan',
          ],
          [
            'заключить и подписать от его имени договор на продажу доли в уставном капитале общества',
            "to conclude and sign on their behalf a contract for the sale of a share in the company's authorized capital",
            '代表其签署销售公司注册资本股份的合同',
            'firmar en su nombre un contrato para la venta de una participación en el capital autorizado de la empresa',
          ],
          [
            'получить по данному договору деньги',
            'to receive money under this contract',
            '根据此合同收取款项',
            'recibir dinero bajo este contrato',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Дополнительно потребуются:',
          'Additionally required:',
          '此外，还需要：',
          'Además se requiere:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'СНИЛС (если имеется)',
            'SNILS (if available)',
            'СНИЛС（如有）',
            'SNILS (si está disponible)',
          ],
          [
            'Согласия супруга на продажу доли общества (если юридическое лицо было создано или приобретено в браке). Можно оформить одновременно с доверенностью.',
            "Spouse's consent to the sale of the share in the company (if the legal entity was created or acquired during the marriage). It can be issued simultaneously with the Power of Attorney.",
            '配偶同意出售公司股份（如果法人是在婚姻中创建或获得的）。可以与授权书同时发放。',
            'Consentimiento del cónyuge para la venta de la participación en la empresa (si la entidad jurídica fue creada o adquirida durante el matrimonio). Puede emitirse simultáneamente con el poder notarial.',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Доверенность на представительство в суде',
          'Power of Attorney for Representation in Court.',
          '授权书用于在法庭上代表',
          'Poder para representación en el tribunal.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Данный вид доверенности позволяет представлять интересы в суде:',
          'This type of Power of Attorney allows representing interests in court:',
          '这种授权书允许在法庭上代表利益：',
          'Este tipo de poder permite representar intereses en el tribunal:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'по гражданским делам',
            'in civil cases',
            '在民事案件中',
            'en casos civiles',
          ],
          [
            'по уголовным делам',
            'in criminal cases',
            '在刑事案件中',
            'en casos penales',
          ],
          [
            'по административным делам',
            'in administrative cases',
            '在行政案件中',
            'en casos administrativos',
          ],
          [
            'по делам, рассматриваемым арбитражным судом (полномочия: отзыв и подписание искового заявления, заключение мирового соглашения и другие)',
            'in cases considered by the arbitration court (powers: withdrawal and signing of the claim statement, conclusion of a settlement agreement, and others)',
            '在仲裁法院审理的案件中（权力：撤回和签署索赔声明、达成和解协议等）',
            'en casos considerados por el tribunal de arbitraje (poderes: retiro y firma de la declaración de reclamación, conclusión de un acuerdo de conciliación, entre otros)',
          ],
          [
            'в службе судебных приставов (полномочия: обжалование действий или бездействия судебных приставов, обжалование постановлений и другие)',
            'in the bailiff service (powers: appealing the actions or inactions of bailiffs, appealing rulings, and others)',
            '在法警服务中（权力：对法警的行为或不作为提出上诉、对裁决提出上诉等）',
            'en el servicio de alguaciles (poderes: apelar las acciones o inacciones de los alguaciles, apelar fallos y otros)',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Дополнительно потребуются:',
          'Additionally required:',
          '此外，还需要：',
          'Además se requiere:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'СНИЛС (если имеются)',
            'SNILS (if available)',
            'СНИЛС（如有）',
            'SNILS (si está disponible)',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Доверенность на представительство в госорганах России',
          'Power of Attorney for Representation in Government Agencies of Russia.',
          '授权书用于在俄罗斯政府机构代表',
          'Poder para representación en agencias gubernamentales de Rusia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Какие полномочия может включать:',
          'What powers it may include:',
          '可能包括哪些权力：',
          'Qué poderes puede incluir:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'действовать от имени доверителя в государственных ведомствах, организациях и службах: ГИБДД, ФССП, ЗАГС, страховых компаниях, Социальном фонде, суде, правоохранительных органах, банках и так далее',
            'to act on behalf of the principal in government departments, organizations, and services: traffic police, bailiff service, civil registry office, insurance companies, Social Fund, court, law enforcement agencies, banks, and so on',
            '代表委托人在政府部门、组织和服务中行事：交通警察、法警服务、民事登记处、保险公司、社会基金、法庭、执法机关、银行等',
            'actuar en nombre del mandante en departamentos, organizaciones y servicios gubernamentales: policía de tráfico, servicio de alguaciles, registro civil, compañías de seguros, Fondo Social, tribunal, agencias de aplicación de la ley, bancos, etc.',
          ],
          [
            'подавать заявления о государственной регистрации прав или сделок',
            'to submit applications for state registration of rights or transactions',
            '提交有关权利或交易的国家注册申请',
            'presentar solicitudes para el registro estatal de derechos o transacciones',
          ],
          [
            'распоряжаться зарегистрированными в государственных реестрах правами',
            'to manage rights registered in state registries',
            '管理登记在国家登记册中的权利',
            'gestionar derechos registrados en registros estatales',
          ],
          [
            'представлять интересы ИП в налоговой инспекции',
            'to represent the interests of individual entrepreneurs in the tax inspection',
            '在税务局代表个体经营者的利益',
            'representar los intereses de los emprendedores individuales ante la inspección fiscal',
          ],
          [
            'представлять интересы в исполнительном производстве',
            'to represent interests in enforcement proceedings',
            '在强制执行程序中代表利益',
            'representar intereses en procedimientos de ejecución',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Дополнительно потребуются:',
          'Additionally required:',
          '此外，还需要：',
          'Además se requiere:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'СНИЛС (если имеются)',
            'SNILS (if available)',
            'СНИЛС（如有）',
            'SNILS (si está disponible)',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Согласие супруга на покупку / продажу / дарение / залог / изменение доли в ООО',
          "Spouse's consent for the purchase / sale / gift / pledge / change of share in LLC.",
          '配偶同意购买/出售/赠与/抵押/变更在有限责任公司的股份',
          'Consentimiento del cónyuge para la compra / venta / donación / prenda / cambio de participación en LLC.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, предоставляемый во всех случаях, когда доля в ООО приобретается в браке на общие деньги.',
          'A document provided in all cases when a share in an LLC is acquired during marriage with joint funds.',
          '在婚姻中以共同资金获得有限责任公司股份时提供的文件。',
          'Un documento proporcionado en todos los casos cuando se adquiere una participación en una LLC durante el matrimonio con fondos conjuntos.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Согласие не требуется, если доля:',
          'Consent is not required if the share:',
          '如果股份：则不需要同意',
          'No se requiere consentimiento si la participación:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'куплена до вступления в брак',
            'was purchased before marriage',
            '是在婚前购买的',
            'se compró antes del matrimonio',
          ],
          [
            'подарена или досталась в наследство одному из супругов',
            'was given as a gift or inherited by one of the spouses',
            '赠予或遗赠给一方配偶',
            'fue dada como regalo o heredada por uno de los cónyuges',
          ],
          [
            'принадлежит только одному супругу на основании брачного договора или договора о разделе имущества',
            'belongs to only one spouse based on a marriage contract or property division agreement',
            '根据婚姻合同或财产分割协议只归一方配偶所有',
            'pertenece solo a un cónyuge basado en un contrato matrimonial o acuerdo de división de bienes',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Для оформления потребуются:',
          'The following documents are required for application:',
          '申请所需文件：',
          'Los siguientes documentos son necesarios para la solicitud:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'внутренние и/или заграничные паспорта РФ супругов (приоритет отдаётся внутреннему паспорту)',
            'internal and/or foreign passports of the spouses (priority is given to the internal passport)',
            '配偶的内外护照（优先考虑内护照）',
            'pasaportes internos y/o extranjeros de los cónyuges (se da prioridad al pasaporte interno)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
            '海外居住地址（如果没有在俄罗斯的登记）',
            'dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
          ],
          [
            'свидетельство о браке или его расторжении (если уже в разводе)',
            'marriage certificate or its dissolution (if already divorced)',
            '结婚证明或其解除证明（如果已经离婚）',
            'certificado de matrimonio o su disolución (si ya está divorciado)',
          ],
          [
            'наименование, адрес, ОГРН и ИНН компании, информация о размере доли',
            'name, address, OGRN, and TIN of the company, information about the share size',
            '公司名称、地址、OGRН和ИНН，股份大小信息',
            'nombre, dirección, OGRN e INN de la empresa, información sobre el tamaño de la participación',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление в налоговую на регистрацию юридического лица',
          'Application to the tax office for registration of a legal entity.',
          '向税务局申请注册法人',
          'Solicitud a la oficina de impuestos para el registro de una entidad legal.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Применяется форма Р11001.',
          'Form P11001 is used.',
          '使用表格Р11001。',
          'Se utiliza el formulario P11001.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Используется для регистрации нового юридического лица.',
          'It is used for the registration of a new legal entity.',
          '用于注册新的法人。',
          'Se utiliza para el registro de una nueva entidad legal.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление в налоговую на внесение изменений',
          'Application to the tax office for making changes.',
          '向税务局申请变更',
          'Solicitud a la oficina de impuestos para realizar cambios.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Применяется форма Р13014.',
          'Form P13014 is used.',
          '使用表格Р13014。',
          'Se utiliza el formulario P13014.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Используется для регистрации следующих изменений:',
          'It is used for registering the following changes:',
          '用于注册以下变更：',
          'Se utiliza para registrar los siguientes cambios:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'в ЕГРЮЛ (наименование, адрес, размер уставного капитала, филиалы, виды деятельности)',
            'in the Unified State Register of Legal Entities (name, address, size of authorized capital, branches, types of activity)',
            '在法人统一注册册（名称、地址、注册资本大小、分支机构、经营范围）',
            'en el Registro Estatal Unificado de Entidades Legales (nombre, dirección, tamaño del capital autorizado, sucursales, tipos de actividad)',
          ],
          [
            'в уставе компании',
            "in the company's charter",
            '在公司的章程中',
            'en los estatutos de la empresa',
          ],
          [
            'смена руководителя',
            'change of director',
            '更换负责人',
            'cambio de director',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление в налоговую на реорганизацию юридического лица',
          'Application to the tax office for reorganization of a legal entity.',
          '向税务局申请法人重组',
          'Solicitud a la oficina de impuestos para la reorganización de una entidad legal.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Применяется форма Р12016.',
          'Form P12016 is used.',
          '使用表格Р12016。',
          'Se utiliza el formulario P12016.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Используется для регистрации реорганизации юридического лица при:',
          'It is used for registering the reorganization of a legal entity in the case of:',
          '用于注册法人重组情况：',
          'Se utiliza para registrar la reorganización de una entidad legal en el caso de:',
        ],
      },
      {
        type: 'list',
        items: [
          ['слиянии', 'merger', '合并', 'fusión'],
          ['присоединении', 'accession', '加入', 'acceso'],
          ['разделении', 'division', '分割', 'división'],
          ['выделении', 'separation', '分离', 'separación'],
          ['преобразовании', 'transformation', '转变', 'transformación'],
        ],
      },
    ],
  ],
  workflow: {
    requestDocumentType: {
      title: [
        ['Какой документ вам необходим?', 'What document do you need?'],
        ['你需要什么文件？', 'What document do you need?'],
        ['¿Qué documento necesitas?', 'What document do you need?'],
      ],
      options: [...serviceTypes, ['Не знаю', 'Not sure', '不知道', 'No sé']],
      additionalInfo: [
        [
          'Опишите своими словами, какой документ требуется',
          'Describe in your own words, what is required',
        ],
        [
          '用你自己的话描述所需的文件',
          'Describe in your own words, what is required',
        ],
        [
          'Describe con tus propias palabras qué documento se requiere',
          'Describe in your own words, what is required',
        ],
      ],
    },
    requestPoaType: {
      title: [
        [
          'Какая доверенность вам необходима?',
          'What Power of Attorney do you need?',
        ],
        ['你需要什么授权书？', 'What Power of Attorney do you need?'],
        [
          '¿Qué poder notarial necesitas?',
          'What Power of Attorney do you need?',
        ],
      ],
      options: [...powerOfAttorneyTypes, ['Другая', 'Other', '其他', 'Otro']],
      additionalInfo: [
        [
          'Опишите своими словами, какой документ требуется',
          'Describe in your own words, what is required',
        ],
        [
          '用你自己的话描述所需的文件',
          'Describe in your own words, what is required',
        ],
        [
          'Describe con tus propias palabras qué documento se requiere',
          'Describe in your own words, what is required',
        ],
      ],
    },
    requestApplicationType: {
      title: [
        ['Какое заявление вам необходимо?', 'What application do you need?'],
        ['你需要什么申请？', 'What application do you need?'],
        ['¿Qué solicitud necesitas?', 'What application do you need?'],
      ],
      options: [...applicationTypes, ['Другое', 'Other', '其他', 'Otro']],
      additionalInfo: [
        [
          'Опишите своими словами, какой документ требуется',
          'Describe in your own words, what is required',
        ],
        [
          '用你自己的话描述所需的文件',
          'Describe in your own words, what is required',
        ],
        [
          'Describe con tus propias palabras qué documento se requiere',
          'Describe in your own words, what is required',
        ],
      ],
    },
    requestConsentType: {
      title: [
        ['Какое согласие вам необходимо?', 'What type of consent do you need?'],
        ['你需要什么类型的同意？', 'What type of consent do you need?'],
        [
          '¿Qué tipo de consentimiento necesitas?',
          'What type of consent do you need?',
        ],
      ],
      selectPlaceholder: [
        ['Тип согласия', 'Type of consent'],
        ['同意类型', 'Type of consent'],
        ['Tipo de consentimiento', 'Type of consent'],
      ],
      options: [
        ...consentTypes,
        ['Другой документ', 'Other', '其他文件', 'Otro'],
      ],
      additionalInfo: [
        [
          'Или опишите своими словами, на что должно быть выдано согласие',
          'Or describe in your own words, what should be given as consent',
        ],
        [
          '或者用你自己的话描述应该给予什么同意',
          'Or describe in your own words, what should be given as consent',
        ],
        [
          'O describe con tus propias palabras qué debe ser dado como consentimiento',
          'Or describe in your own words, what should be given as consent',
        ],
      ],
    },
  },
}

export default vocabularyLegalEntities

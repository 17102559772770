import { useContext, useState } from 'react'
import { ToggleButton, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import vocabulary from '../../vocabulary/vocabulary'
import Layout947 from '../Layouts/Layout947'
import GlobalContext from '../../GlobalContext'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import vocabularyFaq from 'vocabulary/vocabularyFaq'
import vocabularyPowerOfAttorney from 'vocabulary/vocabularyPowerOfAttorney'
import arrowRightImg from '../../images/arrow-black-right.png'
import vocabularyConsent from 'vocabulary/vocabularyConsent'
import AccordionCustom from 'pages/components/AccordionCustom'
import vocabularyCertificates from 'vocabulary/vocabularyCertificates'
import vocabularyLegalEntities from 'vocabulary/vocabularyLegalEntities'
import vocabularyCitizenship from 'vocabulary/vocabularyCitizenship'
import vocabularyZags from 'vocabulary/vocabularyZags'
import vocabularyOther from 'vocabulary/vocabularyOther'
import vocabularyPassport from 'vocabulary/vocabularyPassport'
import { useNavigate } from 'react-router-dom'

export default function FaqPage947() {
  const { langSelected } = useContext(GlobalContext)
  const [pageActive, setPageActive] = useState(0)
  const [menuActive, setMenuActive] = useState(null)
  const [headerMenu, setHeaderMenu] = useState('')
  const theme = useTheme()

  const navigate = useNavigate()

  const handleClick = (index, header) => {
    switch (index) {
      case 0:
        navigate('/about')
        break

      case 1:
        setMenuActive(vocabularyPowerOfAttorney.faqList)
        break
      case 2:
        setMenuActive(vocabularyPassport.faqList)
        break
      case 3:
        setMenuActive(vocabularyConsent.faq)
        break
      case 4:
        setMenuActive(vocabularyCertificates.faq)
        break
      case 5:
        setMenuActive(vocabularyLegalEntities.faq)
        break
      case 6:
        setMenuActive(vocabularyCitizenship.faq)
        break
      case 7:
        setMenuActive(vocabularyZags.faq)
        break
      case 8:
        setMenuActive(vocabularyOther.faq)
        break

      default:
    }

    setHeaderMenu(header)
    setPageActive(index)
  }

  console.log(pageActive)

  return (
    <Layout947 theme={theme} menu='true'>
      <Box sx={{ display: 'flex', flexDirection: 'row', m: 5 }}>
        <Box sx={{ width: 468 }}>
          <Typography variant='h1Small' component='p' sx={{ ml: 2 }}>
            {vocabularyFaq.faq[langSelected]}
          </Typography>

          {pageActive === 0 ? (
            <Box sx={{ mx: 2, mb: 6.5 }}>
              <Box sx={{ mb: '48px' }}></Box>
              <Typography variant='titleLarge' component='p' sx={{ mb: 4 }}>
                {vocabularyFaq.text1[langSelected]}
              </Typography>

              {vocabularyFaq.typesOfFaq.map((val, index) => {
                if (val) {
                  return (
                    <Box
                      key={uuidv4()}
                      sx={{
                        borderTop:
                          !index > 0
                            ? '1px solid ' +
                              theme.palette.primary.outlineVariant
                            : 'none',
                        borderBottom:
                          '1px solid ' + theme.palette.primary.outlineVariant,
                        backgroundColor: theme.palette.primary.surface,
                        width: '100%',
                        height: '48px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleClick(index, val[langSelected])
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {vocabularyFaq.icons[index]}
                        <Typography
                          variant='labelLarge'
                          component='p'
                          sx={{ ml: 1 }}
                        >
                          {val[langSelected]}
                        </Typography>
                      </Box>
                      <Box>
                        <img
                          src={arrowRightImg}
                          alt='logo'
                          width={20}
                          style={{ marginTop: '4px', marginRight: '16px' }}
                        />
                      </Box>
                    </Box>
                  )
                }
              })}
            </Box>
          ) : (
            <Box sx={{ mt: '56px', mx: '16px', mb: 6.5 }}>
              <Typography variant='h2Small' component='p' sx={{ mb: 4 }}>
                {headerMenu}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  maxWidth: 1,
                  flexDirection: 'column',
                  mr: 1,
                }}
              >
                {menuActive.map((val, index) => {
                  return (
                    <AccordionCustom
                      key={uuidv4()}
                      index={index}
                      val={val}
                      langSelected={langSelected}
                      faq={true}
                    />
                  )
                })}
              </Box>

              <Button
                variant='outlined'
                size='medium'
                sx={{
                  width: 260,
                  borderColor: 'black',
                  height: '40px',
                  mt: 6.5,
                  mb: 2,
                }}
                color='blueButton'
                onClick={() => {
                  navigate(`${window.location.pathname}?open=chat`)
                }}
              >
                <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                  {vocabulary.ask[langSelected]}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
        <Box>
          {pageActive !== 0 ? (
            <Box
              sx={{ mx: 2, mb: 6.5, display: 'flex', flexDirection: 'column' }}
            >
              <Typography variant='titleLarge' component='p' sx={{ mb: 4 }}>
                {vocabularyFaq.text1[langSelected]}
              </Typography>

              {vocabularyFaq.typesOfFaq.map((val, index) => {
                return val ? (
                  <ToggleButton
                    key={uuidv4()}
                    value={index}
                    variant='text'
                    selected={index === pageActive}
                    size='medium'
                    sx={{
                      border: 'none',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                    onClick={() => {
                      handleClick(index, val[langSelected])
                    }}
                  >
                    <Typography
                      component='p'
                      color='primary'
                      variant='labelLarge'
                      sx={{ textTransform: 'none' }}
                    >
                      {val[langSelected]}
                    </Typography>
                  </ToggleButton>
                ) : null
              })}
            </Box>
          ) : null}
        </Box>
      </Box>
    </Layout947>
  )
}

import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'

export const serviceTypes = [
  ['Пройти проверку на гражданство', 'Pass citizenship check'],
  ['Оформить гражданство РФ', 'Obtain Russian citizenship'],
  ['Выйти из гражданства РФ', 'Renounce Russian citizenship'],
]

const vocabularyCitizenship = {
  citizenship: ['Гражданство', 'Citizenship', '国籍', 'Ciudadanía'],
  infoText1: [
    'В консульстве или посольстве России возможно пройти проверку на наличие гражданства РФ, которая требуется при утрате или истечении срока действия обоих паспортов, оформить гражданство некоторым категориям граждан, либо пройти процедуру выхода из гражданства.',
    'At the Russian consulate or embassy, ​​it is possible to undergo a check of the Russian citizenship, which is required if both passports are lost or expired, to obtain citizenship for certain categories of citizens, or to go through the procedure for renouncing citizenship.',
    '在俄罗斯领事馆或大使馆，可以进行俄罗斯国籍检查，如果护照丢失或过期，或者为某些类别的公民办理国籍，或进行放弃国籍的程序。',
    'En el consulado o embajada de Rusia, es posible someterse a una verificación de la ciudadanía rusa, que se requiere si ambos pasaportes se pierden o caducan, para obtener la ciudadanía de ciertas categorías de ciudadanos o para pasar por el procedimiento de renuncia a la ciudadanía.',
  ],
  infoText2: [
    'Мы будем рады помочь вам на каждом этапе взаимодействия с консульством: подготовим за вас заявление (и опросный лист, если требуется) в нужном формате на консульском портале, проконсультируем по вашей ситуации и перечню документов, которые необходимо будет взять с собой, при необходимости окажем помощь в записи на приём в консульство.',
    'We will be happy to help you at every stage of interaction with the consulate: we will prepare an application for you (and a questionnaire, if required) in the required format on the consulate portal, we will advise you on your situation and the list of documents that you will have to take with you, and if necessary, we will provide assistance in making an appointment at the consulate.',
    '我们将很高兴在与领事馆互动的每个阶段为您提供帮助：我们将为您准备申请（如果需要，还包括问卷），并在领事馆门户网站上以所需格式提交。我们还将为您的情况和所需文件清单提供建议，并在必要时为您安排领事馆预约提供帮助。',
    'Estaremos encantados de ayudarle en cada etapa de la interacción con el consulado: prepararemos una solicitud para usted (y un cuestionario, si es necesario) en el formato requerido en el portal del consulado, le asesoraremos sobre su situación y la lista de documentos que tendrá que llevar consigo, y si es necesario, le ayudaremos a concertar una cita en el consulado.',
  ],
  checkCitizenship: [
    'Проверка наличия гражданства РФ',
    'Checking the Russian citizenship',
    '检查俄罗斯国籍',
    'Verificación de la ciudadanía rusa',
  ],
  infoText3: [
    'В случае одновременной утери (кражи) или истечении срока внутреннего и заграничного паспортов, для оформления новых документов необходимо пройти процедуру проверки принадлежности к гражданству РФ.',
    'In case of the simultaneous loss (theft) or expiration of internal and foreign passports, in order to issue new documents, it is necessary to go through the procedure of verifying the citizenship of the Russian Federation.',
    '如果同时丢失（被盗）或国内和国际护照到期，为了办理新证件，必须进行俄罗斯国籍验证程序。',
    'En caso de la pérdida simultánea (robo) o expiración de los pasaportes internos y extranjeros, para emitir nuevos documentos, es necesario pasar por el procedimiento de verificación de la ciudadanía de la Federación Rusa.',
  ],
  infoText4: [
    'Для этого требуется выяснить обстоятельства приобретения российского гражданства и указание конкретной статьи, в соответствии с которой заявитель является гражданином РФ.',
    'To do this, you need to find out the circumstances of acquiring Russian citizenship and indicate the specific article in accordance with which the applicant is a citizen of the Russian Federation.',
    '为此，您需要了解获得俄罗斯国籍的情况，并注明申请人是根据哪一条法律成为俄罗斯公民的。',
    'Para ello, es necesario averiguar las circunstancias de adquisición de la ciudadanía rusa e indicar el artículo específico según el cual el solicitante es ciudadano de la Federación Rusa.',
  ],
  infoText5: [
    'Сроки рассмотрения запроса не регламентированы и зависят от способа и места приобретения гражданства.',
    'The timeframe for satisfying a request is not regulated and depends on the method and place of acquisition of citizenship.',
    '请求的处理时间没有规定，取决于获得国籍的方法和地点。',
    'El plazo para satisfacer una solicitud no está regulado y depende del método y lugar de adquisición de la ciudadanía.',
  ],
  receivingCitizenship: [
    'Получение гражданства РФ',
    'Obtaining the Russian citizenship',
    '获得俄罗斯国籍',
    'Obtener la ciudadanía rusa',
  ],
  infoText6: [
    'Оформить гражданство РФ в большинстве случаев возможно только в органах МВД на территории России. Тем не менее есть ряд случаев, когда это возможно сделать в консульстве или посольстве.',
    'In most cases, obtaining the Russian citizenship is possible only at the Ministry of Internal Affairs on the Russian territory. However, there are a number of cases when this can be done at a consulate or embassy.',
    '在大多数情况下，只有在俄罗斯境内的内政部才能获得俄罗斯国籍。然而，在某些情况下，这可以在领事馆或大使馆办理。',
    'En la mayoría de los casos, la obtención de la ciudadanía rusa solo es posible en el Ministerio del Interior en territorio ruso. Sin embargo, hay una serie de casos en los que esto se puede hacer en un consulado o embajada.',
  ],
  userTypes: [
    {
      userName: ['Детям', 'For children', '儿童', 'Para niños'],
      desc: [
        [
          'Если один из родителей (опекунов, усыновителей) является гражданином РФ, то и ребёнок имеет право на получение гражданства России.',
          'If one of the parents (guardians, adoptive parents) is a citizen of the Russian Federation, then the child also has the right to receive Russian citizenship.',
          '如果父母（监护人、养父母）之一是俄罗斯公民，那么孩子也有权获得俄罗斯国籍。',
          'Si uno de los padres (tutores, padres adoptivos) es ciudadano de la Federación Rusa, entonces el niño también tiene derecho a obtener la ciudadanía rusa.',
        ],
        [
          'В зависимости от места рождения ребёнка, гражданства второго родителя и наличия / отсутствия у ребёнка гражданства другой страны он может получить гражданство РФ по рождению или быть принят в него, что влияет на сроки рассмотрения заявления.',
          'Depending on the child’s place of birth, the citizenship of the second parent and the presence/absence of citizenship of another country, the child may obtain Russian citizenship by birth or be admitted to it, which affects the time frame for consideration of the application.',
          '根据孩子的出生地、第二个父母的国籍以及孩子是否拥有其他国家的国籍，孩子可以通过出生获得俄罗斯国籍，或被接纳为俄罗斯公民，这会影响申请的审理时间。',
          'Dependiendo del lugar de nacimiento del niño, la ciudadanía del segundo progenitor y la presencia/ausencia de ciudadanía de otro país, el niño puede obtener la ciudadanía rusa por nacimiento o ser admitido en ella, lo que afecta el plazo de consideración de la solicitud.',
        ],
        [
          'В некоторых случаях подать заявление об оформлении гражданства ребёнку можно одновременно с заявлениями об оформлении свидетельства о рождении и первичного пятилетнего заграничного паспорта ребёнку. Потребуется только одна запись на приём.',
          'In some cases, you can submit an application for citizenship for a child at the same time as an application for a birth certificate and an initial five-year foreign passport for the child. Only one appointment will be required.',
          '在某些情况下，您可以在为孩子申请国籍的同时申请出生证明和初次五年期国际护照。只需一次预约。',
          'En algunos casos, puede presentar una solicitud de ciudadanía para un niño al mismo tiempo que una solicitud de certificado de nacimiento y un pasaporte extranjero inicial de cinco años para el niño. Solo se requerirá una cita.',
        ],
        [
          'Решение о приёме в гражданство РФ ребёнка в возрасте до 14 лет принимается Посольством на месте. Решение о приёме в гражданство ребёнка в возрасте от 14 до 18 лет принимается только после предварительного согласования в России. Срок рассмотрения такого заявления — до 6 месяцев.',
          'The decision to grant Russian citizenship to a child under the age of 14 is made by the Embassy on the spot. The decision to grant citizenship to a child between the ages of 14 and 18 is made only after prior approval in Russia. The period for consideration of such an application is up to 6 months.',
          '14岁以下儿童的俄罗斯国籍授予决定由大使馆当场作出。14至18岁儿童的国籍授予决定仅在俄罗斯事先批准后作出。此类申请的处理时间最多为6个月。',
          'La decisión de otorgar la ciudadanía rusa a un niño menor de 14 años la toma la Embajada en el lugar. La decisión de otorgar la ciudadanía a un niño entre 14 y 18 años solo se toma después de una aprobación previa en Rusia. El período para considerar dicha solicitud es de hasta 6 meses.',
        ],
      ],
    },
  ],
  userTypes1: [
    {
      userName: [
        'Бывшим гражданам СССР',
        'Former citizens of the USSR',
        '前苏联公民',
        'Antiguos ciudadanos de la URSS',
      ],
      headerDesc: [
        [
          'Граждане бывшего СССР имеют право на получения гражданства России, если:',
          'Citizens of the former USSR have the right to receive Russian citizenship if:',
          '前苏联公民有权获得俄罗斯国籍，如果：',
          'Los ciudadanos de la antigua URSS tienen derecho a recibir la ciudadanía rusa si:',
        ],
      ],
      desc: [
        [
          'на момент 06.02.1992 г. они постоянно проживали на территории России и могут документированно подтвердить данный факт',
          'at the time of 02/06/1992 they were permanently residing on the territory of Russia and can document this fact',
          '截至1992年2月6日，他们一直居住在俄罗斯领土，并能提供证明。',
          'a la fecha del 06/02/1992 residían permanentemente en el territorio de Rusia y pueden documentar este hecho',
        ],
        [
          'они проживали и проживают в государствах, входивших в состав СССР, и не получили гражданство этих государств (есть временные ограничения действия закона: до 26.10.2024)',
          'they lived and live in states that were part of the USSR, and did not receive citizenship of these states (there are temporary restrictions on the validity of the law: until October 26, 2024)',
          '他们曾经并且现在居住在前苏联成员国，并且没有获得这些国家的国籍（该法律的有效期有时间限制：截止到2024年10月26日）',
          'vivían y viven en los estados que formaban parte de la URSS, y no recibieron la ciudadanía de esos estados (hay restricciones temporales en la validez de la ley: hasta el 26 de octubre de 2024)',
        ],
        [
          'имеют статус ветерана Великой Отечественной войны',
          'have the status of a veteran of the Great World War II',
          '拥有伟大卫国战争老兵身份',
          'tener el estatus de veterano de la Gran Guerra Patria',
        ],
      ],
    },
  ],
  citizenshipExit: [
    'Выход из гражданства РФ',
    'Renouncing the Russian citizenship',
    '放弃俄罗斯国籍',
    'Renunciar a la ciudadanía rusa',
  ],
  infoText7: [
    'Гражданин имеет право отказаться от гражданства России для себя и своих детей в случае, если выполнены следующие условия:',
    'A citizen has the right to renounce the Russian citizenship for himself and their children if the following conditions are met:',
    '如果满足以下条件，公民有权为自己及其子女放弃俄罗斯国籍：',
    'Un ciudadano tiene derecho a renunciar a la ciudadanía rusa para sí mismo y sus hijos si se cumplen las siguientes condiciones:',
  ],
  citizenshipExitList: [
    [
      'у него имеется действующий загранпаспорт гражданина РФ',
      'he/she has a valid foreign passport of a citizen of the Russian Federation',
      '他/她持有有效的俄罗斯联邦公民国际护照',
      'él/ella tiene un pasaporte extranjero válido de un ciudadano de la Federación Rusa',
    ],
    [
      'он находится в стране консульства легально, имеет гражданство этой страны или гарантии предоставления ему иного гражданства в случае выхода из гражданства России',
      'he/she is in the country of the consulate legally, has citizenship of this country or guarantees that he/she will be granted another citizenship in the event of renunciation of the Russian citizenship',
      '他/她合法居住在领事馆所在国家，拥有该国国籍或在放弃俄罗斯国籍后有保证获得其他国籍。',
      'él/ella se encuentra en el país del consulado legalmente, tiene la ciudadanía de ese país o garantías de que se le concederá otra ciudadanía en caso de renuncia a la ciudadanía rusa',
    ],
    [
      'не имеет задолженности по уплате налогов в РФ',
      'has no tax arrears in the Russian Federation',
      '没有俄罗斯联邦的税务欠款',
      'no tiene deudas tributarias en la Federación Rusa',
    ],
    [
      'не имеет неоконченного исполнительного производства в РФ',
      'does not have pending enforcement proceedings in the Russian Federation',
      '没有俄罗斯联邦的未决执行程序',
      'no tiene procedimientos de ejecución pendientes en la Federación Rusa',
    ],
    [
      'не имеет оснований для признания гражданина не прошедшим военную службу по призыву, не имея на то законных оснований (для мужчин от 18 до 30 лет)',
      'there are no grounds for recognizing a citizen as having not completed military service by conscription, without having legal grounds for this (for men from 18 to 30 years old)',
      '没有法律依据认为公民未履行兵役（适用于18至30岁的男性）',
      'no hay motivos para reconocer a un ciudadano como no haber completado el servicio militar por conscripción, sin tener motivos legales para ello (para hombres de 18 a 30 años)',
    ],
  ],
  infoText8: [
    'Базовые комплекты документов, представляемые в консульство / посольство России по вопросам гражданства:',
    'Basic sets of documents submitted to the Russian consulate/embassy on citizenship issues:',
    '向俄罗斯领事馆/大使馆提交的公民身份问题的基本文件清单：',
    'Conjuntos básicos de documentos presentados al consulado/embajada de Rusia sobre cuestiones de ciudadanía:',
  ],

  userTypes2: [
    {
      userName: [
        'Все категории граждан',
        'All categories of citizens',
        '所有公民类别',
        'Todas las categorías de ciudadanos',
      ],
      headerDesc: [
        [
          'Заявление и опросный лист',
          'Application and questionnaire',
          '申请表和问卷',
          'Solicitud y cuestionario',
        ],
      ],
      desc: [
        [
          'Оригинал и/или копия свидетельства о рождении, браке, разводе, перемены имени (при наличии)',
          'Original and/or copy of birth, marriage, divorce, name change certificate (if available)',
          '出生、结婚、离婚或更名证明的原件和/或复印件（如有）',
          'Original y/o copia del certificado de nacimiento, matrimonio, divorcio o cambio de nombre (si está disponible)',
        ],
        [
          'Иностранный документ, удостоверяющий личность (если имеется второе гражданство или ВНЖ)',
          'Foreign identification document (if you have a second citizenship or residence permit)',
          '外国身份证明文件（如果您拥有第二国籍或居留许可）',
          'Documento de identificación extranjero (si tiene una segunda ciudadanía o un permiso de residencia)',
        ],
        [
          'Фотография 3,5 × 4,5 см',
          'Photo 3.5x4.5 cm',
          '3.5×4.5厘米的照片',
          'Foto de 3,5x4,5 cm',
        ],
      ],
      ps: [
        'Смотреть требования к фотографии',
        'View photo requirements',
        '查看照片要求',
        'Ver requisitos de la foto',
      ],
    },
  ],
  userTypes3: [
    {
      userName: ['Дети', 'Children', '儿童', 'Niños'],
      desc: [
        [
          'Заявление от имени родителя (иного законного представителя), имеющего гражданство РФ',
          'Application on behalf of a parent (other legal representative) who has Russian citizenship',
          '以拥有俄罗斯国籍的父母（或其他法定代表人）的名义提交的申请',
          'Solicitud en nombre de un padre (u otro representante legal) que tenga la ciudadanía rusa',
        ],
        [
          'Загранпаспорт и/или внутренний паспорт родителя (иного законного представителя), имеющего гражданство РФ. Если имеются оба паспорта, необходимо взять оба паспорта.',
          'Foreign passport and/or internal passport of a parent (other legal representative) with Russian citizenship. If you have both passports, you must take both passports.',
          '持有俄罗斯国籍的父母（或其他法定代表人）的国际护照和/或内部护照。如果有两本护照，必须携带两本。',
          'Pasaporte extranjero y/o pasaporte interno de un padre (u otro representante legal) con ciudadanía rusa. Si tiene ambos pasaportes, debe llevar ambos.',
        ],
        [
          'Свидетельство о рождении или другой документ, подтверждающий полномочия законного представителя',
          'Birth certificate or other document confirming the authority of the legal representative',
          '出生证明或其他证明法定代表人权力的文件',
          'Certificado de nacimiento u otro documento que confirme la autoridad del representante legal',
        ],
        [
          'Паспорт ребёнка (если имеется)',
          "Child's passport (if available)",
          '儿童护照（如有）',
          'Pasaporte del niño (si está disponible)',
        ],
        [
          'Свидетельство о браке / разводе / перемене имени, если родитель менял ФИО',
          'Certificate of marriage/divorce/change of name, if the parent changed their full name',
          '结婚证/离婚证/改名证明，如果父母更改了姓名',
          'Certificado de matrimonio/divorcio/cambio de nombre, si el padre cambió su nombre completo',
        ],
        [
          'Документ, удостоверяющий личность и гражданство второго родителя (при наличии)',
          'Document proving the identity and citizenship of the second parent (if available)',
          '证明第二父母身份和国籍的文件（如有）',
          'Documento que acredite la identidad y la ciudadanía del segundo padre (si está disponible)',
        ],
        [
          'Документ, подтверждающий законные основания нахождения ребёнка в стране консульства (удостоверение личности другой страны, внж, виза или прописка)',
          'A document confirming the legal grounds for the child’s presence in the country of the consulate (identity card of another country, residence permit, visa or registration)',
          '确认孩子在领事馆所在国家合法身份的文件（其他国家身份证、居留许可、签证或登记）',
          'Documento que confirme los motivos legales de la presencia del niño en el país del consulado (cédula de otro país, permiso de residencia, visa o registro)',
        ],
      ],
      comments: [
        [
          'Согласие второго родителя, имеющего иностранное гражданство, не требуется.',
          'The consent of the second parent who has foreign citizenship is not required.',
          '不需要拥有外国国籍的第二父母的同意。',
          'No se requiere el consentimiento del segundo padre que tiene ciudadanía extranjera.',
        ],
        [
          'Любой иностранный документ должен быть легализован в стране выдачи документа, переведён на русский язык и заверен. Переводы заблаговременно заверяются непосредственно в консульстве или при подаче документов на оформление гражданства ребёнка (Порядок заверения переводов зависит от процедуры оформления гражданства и страны обращения).',
          'Any foreign document must be legalized in the country where the document was issued, translated into Russian and certified. Translations are certified in advance directly at the consulate or when submitting documents for registration of the child’s citizenship (The procedure for certification of translations depends on the procedure for obtaining citizenship and the country of application).',
          '任何外国文件必须在签发文件的国家合法化，并翻译成俄语并认证。翻译件需提前在领事馆或提交孩子国籍注册申请时认证（翻译认证程序取决于申请国籍的程序和申请国家）。',
          'Cualquier documento extranjero debe ser legalizado en el país donde se emitió el documento, traducido al ruso y certificado. Las traducciones se certifican con antelación directamente en el consulado o al presentar los documentos para el registro de la ciudadanía del niño (El procedimiento de certificación de traducciones depende del procedimiento de obtención de la ciudadanía y del país de aplicación).',
        ],
      ],
      ps: [
        'Смотреть требования к фотографии',
        'View photo requirements',
        '查看照片要求',
        'Ver requisitos de la foto',
      ],
    },
    {
      userName: [
        'Бывшие граждане СССР, проживавшие на начало 1992 года на территории РФ',
        'Former citizens of the USSR who lived on the territory of the Russian Federation at the beginning of 1992',
        '1992年初居住在俄罗斯联邦领土上的前苏联公民',
        'Antiguos ciudadanos de la URSS que vivían en el territorio de la Federación Rusa a principios de 1992',
      ],
      desc: [
        [
          'Заявление о признании гражданином',
          'Application for recognition as a citizen',
          '承认公民身份的申请',
          'Solicitud de reconocimiento como ciudadano',
        ],
        [
          'Документ, удостоверяющий личность. В случае отсутствия такого документа до подачи заявления о приёме в гражданство проводится установление личности заявителя',
          'Identity document. In the absence of such a document, before submitting an application for admission to citizenship, the identity of the applicant is established',
          '身份证明文件。如果没有此类文件，则在提交入籍申请之前将确定申请人的身份。',
          'Documento de identidad. En ausencia de dicho documento, antes de presentar una solicitud de admisión a la ciudadanía, se establece la identidad del solicitante.',
        ],
        [
          'Документы, подтверждающие наличие или отсутствие гражданства',
          'Documents confirming the presence or absence of citizenship',
          '证明有无国籍的文件',
          'Documentos que confirmen la presencia o ausencia de ciudadanía',
        ],
        [
          'Документ или сведения о месте жительства',
          'Document or information about place of residence',
          '住所文件或信息',
          'Documento o información sobre el lugar de residencia',
        ],
        [
          'Свидетельство о рождении (при наличии)',
          'Birth certificate (if available)',
          '出生证明（如有）',
          'Certificado de nacimiento (si está disponible)',
        ],
        [
          'Свидетельство о браке / разводе / смене имени (если имеется) при смене ФИО',
          'Certificate of marriage/divorce/change of name (if available) when changing your full name',
          '结婚证/离婚证/改名证明（如有）',
          'Certificado de matrimonio/divorcio/cambio de nombre (si está disponible) al cambiar su nombre completo',
        ],
        [
          'Документ, подтверждающий законное нахождение в стране консульства',
          'Document confirming legal presence in the country of the consulate',
          '确认在领事馆所在国家合法身份的文件',
          'Documento que confirme la presencia legal en el país del consulado',
        ],
        [
          'Документ, подтверждающий наличие в прошлом гражданства СССР',
          'A document confirming previous USSR citizenship',
          '确认过去有苏联国籍的文件',
          'Un documento que confirme la ciudadanía anterior de la URSS',
        ],
        [
          'Документ, подтверждающий проживание в РФ по состоянию на 06.02.1992',
          'Document confirming residence in the Russian Federation as of 02/06/1992',
          '确认截至1992年2月6日居住在俄罗斯联邦的文件',
          'Documento que confirme la residencia en la Federación Rusa al 06/02/1992',
        ],
        [
          '3 фотографии 3,5 × 4,5 см',
          '3 photos 3.5x4.5 cm',
          '3张3.5×4.5厘米的照片',
          '3 fotos 3.5x4.5 cm',
        ],
      ],
      ps: [
        'Смотреть требования к фотографии',
        'View photo requirements',
        '查看照片要求',
        'Ver requisitos de la foto',
      ],
    },
    {
      userName: [
        'Бывшие граждане СССР, проживавшие на начало 1992 года и по сей день на территории других республик, но не получившие новое гражданство',
        'Former citizens of the USSR who lived at the beginning of 1992 and to this day on the territory of other republics, but did not receive new citizenship',
        '1992年初至今居住在其他共和国领土上但未获得新国籍的前苏联公民',
        'Antiguos ciudadanos de la URSS que vivían a principios de 1992 y hasta el día de hoy en el territorio de otras repúblicas, pero no recibieron nueva ciudadanía',
      ],
      desc: [
        [
          'Заявление о приёме в гражданство',
          'Application for admission to citizenship',
          '入籍申请',
          'Solicitud de admisión a la ciudadanía',
        ],
        [
          'Документ, удостоверяющий личность лица без гражданства',
          'Document proving the identity of a stateless person',
          '证明无国籍者身份的文件',
          'Documento que acredite la identidad de una persona apátrida',
        ],
        [
          'Документ, подтверждающий отсутствие гражданства',
          'Document confirming the absence of citizenship',
          '确认无国籍的文件',
          'Documento que confirme la ausencia de ciudadanía',
        ],
        [
          'Документ или сведения о месте жительства',
          'Document or information about the place of residence',
          '住所文件或信息',
          'Documento o información sobre el lugar de residencia',
        ],
        [
          'Свидетельство о рождении (при наличии)',
          'Birth certificate (if available)',
          '出生证明（如有）',
          'Certificado de nacimiento (si está disponible)',
        ],
        [
          'Свидетельство о браке / разводе / смене имени (если имеется) при смене ФИО',
          'Marriage / divorce / name change certificate (if applicable) upon name change',
          '结婚/离婚/更名证明（如适用）',
          'Certificado de matrimonio / divorcio / cambio de nombre (si corresponde) tras el cambio de nombre',
        ],
        [
          'Документ, подтверждающий законное нахождение в стране консульства',
          "Document confirming legal residence in the consulate's country",
          '确认在领事馆所在国家合法居住的文件',
          'Documento que acredite la residencia legal en el país del consulado',
        ],
        [
          'Документ, подтверждающий наличие в прошлом гражданства СССР',
          'Document confirming previous citizenship of the USSR',
          '确认过去有苏联国籍的文件',
          'Documento que acredite la ciudadanía anterior de la URSS',
        ],
        [
          'Документ, подтверждающий владение русским языком (кроме лиц старше 70 лет и инвалидов 1 группы)',
          'Document confirming proficiency in the Russian language (except for persons over 70 years old and Group 1 disabled persons)',
          '确认俄语能力的文件（70岁以上人士和1级残疾人士除外）',
          'Documento que confirme la competencia en el idioma ruso (excepto para personas mayores de 70 años y personas discapacitadas del Grupo 1)',
        ],
        [
          '3 фотографии 3,5 × 4,5 см',
          '3 photographs 3.5 × 4.5 cm',
          '3张3.5×4.5厘米的照片',
          '3 fotos 3,5 × 4,5 cm',
        ],
      ],
      ps: [
        'Смотреть требования к фотографии',
        'View photo requirements',
        '查看照片要求',
        'Ver requisitos de la foto',
      ],
    },
  ],

  userTypes4: [
    {
      userName: [
        'Лица старше 18 лет',
        'Persons over 18 years of age',
        '18岁以上的人',
        'Personas mayores de 18 años',
      ],
      desc: [
        ['Заявление', 'Application', '申请', 'Solicitud'],
        [
          'Документ, удостоверяющий личность. В случае отсутствия такого документа до подачи заявления о приёме в гражданство проводится установление личности заявителя',
          'Identity document. In the absence of such a document, before submitting an application for admission to citizenship, the identity of the applicant is established',
          '身份证明文件。如果没有此类文件，则在提交入籍申请之前将确定申请人的身份。',
          'Documento de identidad. En ausencia de dicho documento, antes de presentar una solicitud de admisión a la ciudadanía, se establece la identidad del solicitante.',
        ],
        [
          'Загранпаспорт РФ, при наличии представляется также оригинал внутреннего паспорта РФ',
          'International passport of the Russian Federation, if available, the original internal passport of the Russian Federation must also be presented',
          '俄罗斯联邦的国际护照，如果有，还必须提供俄罗斯联邦的原始内部护照。',
          'Pasaporte internacional de la Federación Rusa; si está disponible, también se debe presentar el pasaporte interno original de la Federación Rusa.',
        ],
        [
          'Документ, подтверждающий место жительства',
          'Document confirming place of residence',
          '确认居住地的文件',
          'Documento que acredite el lugar de residencia',
        ],
        [
          'Свидетельство о браке / разводе / перемене имени, если была смена ФИО',
          'Certificate of marriage/divorce/change of name, if there was a change of name',
          '结婚/离婚/改名证明，如果有更名',
          'Certificado de matrimonio/divorcio/cambio de nombre, si hubo un cambio de nombre',
        ],
        [
          'Документ, подтверждающий законные основания нахождения в стране консульства',
          'A document confirming the legal grounds for staying in the country of the consulate',
          '确认在领事馆所在国家合法居住的文件',
          'Documento que confirme los motivos legales para permanecer en el país del consulado',
        ],
        [
          'Документ, подтверждающий наличие гражданства этой страны или гарантий предоставления заявителю иного гражданства в случае выхода из гражданства РФ',
          'A document confirming citizenship of this country or guarantees that the applicant will be granted another citizenship in the event of renunciation of Russian citizenship',
          '确认该国国籍的文件或保证在放弃俄罗斯国籍的情况下授予申请人其他国籍的保证',
          'Documento que acredite la ciudadanía de este país o garantías de que al solicitante se le concederá otra ciudadanía en caso de renuncia a la ciudadanía rusa',
        ],
        [
          'Подтверждение отсутствия задолженности по уплате налогов в РФ',
          'Confirmation of the absence of debt on payment of taxes in the Russian Federation',
          '确认没有在俄罗斯联邦欠税',
          'Confirmación de la ausencia de deudas en el pago de impuestos en la Federación Rusa',
        ],
        [
          'Подтверждение отсутствия неоконченного исполнительного производства в отношении заявителя',
          'Confirmation of the absence of pending enforcement proceedings against the applicant',
          '确认申请人没有未完成的执行程序',
          'Confirmación de la ausencia de procedimientos de ejecución pendientes contra el solicitante',
        ],
        [
          'Подтверждение отсутствия оснований для признания гражданина не прошедшим военную службу по призыву, не имея на то законных оснований (для мужчин от 18 до 30 лет)',
          'Confirmation of the absence of grounds for recognizing a citizen as having not completed military service by conscription without having legal grounds (for men from 18 to 30 years old)',
          '确认没有理由承认公民因没有合法理由而没有履行征兵义务（18至30岁的男性）',
          'Confirmación de la ausencia de motivos para reconocer a un ciudadano como no haber completado el servicio militar por conscripción sin tener motivos legales (para hombres de 18 a 30 años)',
        ],
        [
          '3 фотографии 3,5 × 4,5 см',
          '3 photos 3.5x4.5 cm',
          '3张3.5x4.5厘米的照片',
          '3 fotos 3.5x4.5 cm',
        ],
      ],
      ps: [
        'Смотреть требования к фотографии',
        'View photo requirements',
        '查看照片要求',
        'Ver requisitos de la foto',
      ],
    },
    {
      userName: ['Дети', 'Children', '儿童', 'Niños'],
      desc: [
        [
          'Заявление от имени родителя (иного законного представителя), имеющего гражданство РФ',
          'Application on behalf of a parent (other legal representative) who has Russian citizenship',
          '以拥有俄罗斯国籍的父母（或其他法定代表人）的名义提交的申请',
          'Solicitud en nombre de un padre (u otro representante legal) que tenga la ciudadanía rusa',
        ],
        [
          'Загранпаспорт и/или внутренний паспорт родителя (иного законного представителя), имеющего гражданство РФ. Если имеются оба паспорта, необходимо взять оба паспорта.',
          'Foreign passport and/or internal passport of a parent (other legal representative) with Russian citizenship. If you have both passports, you must take both passports.',
          '持有俄罗斯国籍的父母（或其他法定代表人）的国际护照和/或内部护照。如果有两本护照，必须携带两本。',
          'Pasaporte extranjero y/o pasaporte interno de un padre (u otro representante legal) con ciudadanía rusa. Si tiene ambos pasaportes, debe llevar ambos.',
        ],
        [
          'Свидетельство о рождении или другой документ, подтверждающий полномочия законного представителя',
          'Birth certificate or other document confirming the authority of the legal representative',
          '出生证明或其他证明法定代表人权力的文件',
          'Certificado de nacimiento u otro documento que confirme la autoridad del representante legal',
        ],
        [
          'Паспорт ребёнка (если имеется)',
          "Child's passport (if available)",
          '儿童护照（如有）',
          'Pasaporte del niño (si está disponible)',
        ],
        [
          'Свидетельство о браке / разводе / перемене имени, если родитель менял ФИО',
          'Certificate of marriage/divorce/change of name, if the parent changed their full name',
          '结婚证/离婚证/改名证明，如果父母更改了姓名',
          'Certificado de matrimonio/divorcio/cambio de nombre, si el padre cambió su nombre completo',
        ],
        [
          'Документ, удостоверяющий личность и гражданство второго родителя (при наличии)',
          'Document proving the identity and citizenship of the second parent (if available)',
          '证明第二父母身份和国籍的文件（如有）',
          'Documento que acredite la identidad y la ciudadanía del segundo padre (si está disponible)',
        ],
        [
          'Документ, подтверждающий законные основания нахождения ребёнка в стране консульства (удостоверение личности другой страны, внж, виза или прописка)',
          'A document confirming the legal grounds for the child’s presence in the country of the consulate (identity card of another country, residence permit, visa or registration)',
          '确认孩子在领事馆所在国家合法身份的文件（其他国家身份证、居留许可、签证或登记）',
          'Documento que confirme los motivos legales de la presencia del niño en el país del consulado (cédula de otro país, permiso de residencia, visa o registro)',
        ],
        [
          'Документ, подтверждающий наличие гражданства этой страны или гарантий предоставления заявителю иного гражданства в случае выхода из гражданства РФ',
          'A document confirming citizenship of this country or guarantees that the applicant will be granted another citizenship in the event of renunciation of Russian citizenship',
          '确认该国国籍的文件或保证在放弃俄罗斯国籍的情况下授予申请人其他国籍的保证',
          'Documento que acredite la ciudadanía de este país o garantías de que al solicitante se le concederá otra ciudadanía en caso de renuncia a la ciudadanía rusa',
        ],
        [
          'Согласие ребёнка (для детей старше 14 лет)',
          'Child consent (for children over 14 years old)',
          '14岁以上儿童的同意书',
          'Consentimiento del niño (para niños mayores de 14 años)',
        ],
        [
          'Фотографии 3,5 × 4,5 см',
          'Photos 3.5x4.5 cm',
          '3.5x4.5厘米的照片',
          'Fotos 3.5x4.5 cm',
        ],
      ],
      ps: [
        'Смотреть требования к фотографии',
        'View photo requirements',
        '查看照片要求',
        'Ver requisitos de la foto',
      ],
    },
  ],

  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'заполним заявление (и опросный лист) на консульском портале',
        'проконсультируем по списку документов, которые потребуется предоставить, согласно вашей ситуации',
        'проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '60-100',
    },
    {
      header: 'Without appointment at the consulate',
      list: [
        'we will fill out the application (and questionnaire) on the consulate portal',
        'we will advise you on the list of documents that will have to be provided, according to your situation',
        'we will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '60-100',
    },
    {
      header: '没有预约领事馆',
      list: [
        '我们将在领事馆门户网站上填写申请（和问卷）',
        '我们会根据您的情况咨询您所需提供的文件清单',
        '我们会就如何自行预约领事馆进行咨询',
      ],
      value: '60-100',
    },
    {
      header: 'Sin cita en el consulado',
      list: [
        'completaremos la solicitud (y el cuestionario) en el portal consular',
        'le asesoraremos sobre la lista de documentos que deberá presentar, de acuerdo con su situación',
        'le asesoraremos sobre cómo programar una cita en el consulado por su cuenta',
      ],
      value: '60-100',
    },
  ],
  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'заполним заявление (и опросный лист) на консульском портале',
        'проконсультируем по списку документов, которые потребуется предоставить, согласно вашей ситуации',
        'Запишем вас на приём в консульство',
      ],
      value: '80-120',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'we will fill out the application (and questionnaire) on the consulate portal',
        'we will advise you on the list of documents that will have to be provided, according to your situation',
        'we will schedule an appointment at the consulate for you',
      ],
      value: '80-120',
    },
    {
      header: '有预约领事馆',
      list: [
        '我们将在领事馆门户网站上填写申请（和问卷）',
        '我们会根据您的情况咨询您所需提供的文件清单',
        '我们将为您预约领事馆面谈',
      ],
      value: '80-120',
    },
    {
      header: 'Con cita en el consulado',
      list: [
        'completaremos la solicitud (y el cuestionario) en el portal consular',
        'le asesoraremos sobre la lista de documentos que deberá presentar, de acuerdo con su situación',
        'le programaremos una cita en el consulado',
      ],
      value: '80-120',
    },
  ],
  faq: [
    {
      userName: [
        'Кто может обратиться в консульство для оформления гражданства ребёнку?',
        'Who can contact the consulate to obtain citizenship for a child?',
        '谁可以联系领事馆为孩子申请国籍？',
        '¿Quién puede contactar con el consulado para obtener la ciudadanía de un niño?',
      ],
      desc: [
        [
          'Один из родителей ребёнка (законный представитель), который имеет гражданство РФ.',
          'One of the child’s parents (legal representative) who has the Russian citizenship.',
          '拥有俄罗斯国籍的孩子的父母之一（法定代表人）。',
          'Uno de los padres del niño (representante legal) que tenga la ciudadanía rusa.',
        ],
      ],
    },
    {
      userName: [
        'Нужно ли согласие от второго родителя-иностранца на приобретение ребёнком гражданства РФ?',
        'Is consent required from the second foreign parent for a child to acquire Russian citizenship?',
        '孩子获得俄罗斯国籍是否需要外国籍的另一位家长同意？',
        '¿Se requiere el consentimiento del segundo padre extranjero para que un niño adquiera la ciudadanía rusa?',
      ],
      desc: [
        [
          'Нет, согласие второго родителя, имеющего иностранное гражданство, не требуется.',
          'No, the consent of the second parent who has foreign citizenship is not required.',
          '不，不需要外国籍家长的同意。',
          'No, no se requiere el consentimiento del segundo padre con ciudadanía extranjera.',
        ],
      ],
    },
    {
      userName: [
        'В каком случае считается, что у ребёнка имеется единственный родитель?',
        'In what case is it considered that a child has a single parent?',
        '在什么情况下被认为孩子只有一位家长？',
        '¿En qué caso se considera que un niño tiene un solo padre?',
      ],
      desc: [
        [
          'Так происходит в ряде случаев, когда второй родитель умер, пропал без вести, лишён родительских прав, либо запись о нём в свидетельстве о рождении ребёнка отсутствует или внесена со слов матери.',
          'This happens in a number of cases when the second parent has died, gone missing, been deprived of parental rights, or there is no entry about him in the child’s birth certificate or it was made according to the words of the mother.',
          '在一些情况下，第二位家长死亡、失踪、被剥夺了监护权，或者在孩子的出生证明上没有关于他的记录，或者记录是根据母亲的陈述填写的。',
          'Esto ocurre en varios casos cuando el segundo padre ha muerto, ha desaparecido, ha sido privado de los derechos parentales o no hay constancia de él en el certificado de nacimiento del niño o se hizo según las palabras de la madre.',
        ],
      ],
    },
    {
      userName: [
        'Может ли гражданин России оформить гражданство другой страны и какие могут быть для него последствия?',
        'Can a Russian citizen obtain citizenship of another country and what might be the consequences for him/her?',
        '俄罗斯公民能否申请另一国家的国籍，这可能会给他带来什么后果？',
        '¿Puede un ciudadano ruso obtener la ciudadanía de otro país y cuáles pueden ser las consecuencias para él/ella?',
      ],
      desc: [
        [
          'Да, может, законодательством РФ это не запрещено. Однако о факте получения ВНЖ или гражданства другого государства необходимо уведомить МВД в срок до 60 дней с момента въезда на территорию России (можно онлайн через Госуслуги).',
          'Yes, the legislation of the Russian Federation does not prohibit this. However, the fact of obtaining a residence permit or citizenship of another state must be notified to the Ministry of Internal Affairs within 60 days from the date of entry into the territory of Russia (this can be done online through State Services).',
          '是的，俄罗斯联邦的法律并不禁止这种行为。然而，必须在进入俄罗斯境内后60天内向内务部通知获得其他国家的居留许可或国籍的事实（可以通过“公共服务”在线完成）。',
          'Sí, la legislación de la Federación Rusa no lo prohíbe. Sin embargo, el hecho de obtener un permiso de residencia o la ciudadanía de otro estado debe notificarse al Ministerio del Interior dentro de los 60 días posteriores a la entrada en el territorio de Rusia (esto se puede hacer en línea a través de los Servicios Estatales).',
        ],
      ],
    },
    {
      userName: [
        'Могут ли граждане Украины, ранее проживавшие на территории Крыма, ДНР, ЛНР, Запорожской и Херсонской области, подать на российское гражданство в консульствах РФ?',
        'Can citizens of Ukraine who previously lived in the territory of Crimea, DPR, LPR, Zaporozhye, and Kherson regions apply for Russian citizenship at the consulates of the Russian Federation?',
        '此前居住在克里米亚、DNR、LNR、扎波罗热和赫尔松地区的乌克兰公民可以在俄罗斯联邦的领事馆申请俄罗斯国籍吗？',
        '¿Pueden los ciudadanos de Ucrania que vivían anteriormente en el territorio de Crimea, DPR, LPR, Zaporozhye y las regiones de Kherson solicitar la ciudadanía rusa en los consulados de la Federación Rusa?',
      ],
      desc: [
        [
          'Нет, оформить гражданство РФ для данной категории лиц возможно только на территории России в органах МВД.',
          'No, it is possible to obtain Russian citizenship for this category of persons only on the territory of Russia through the Ministry of Internal Affairs.',
          '不，这类人员只能在俄罗斯境内通过内务部申请俄罗斯国籍。',
          'No, es posible obtener la ciudadanía rusa para esta categoría de personas solo en el territorio de Rusia a través del Ministerio del Interior.',
        ],
      ],
    },
  ],

  faqDesc: [
    [
      {
        question:
          'Кто может обратиться в консульство для оформления гражданства ребёнку?',
        answer:
          'Один из родителей ребёнка (законный представитель), который имеет гражданство РФ.',
      },
      {
        question:
          'Нужно ли согласие от второго родителя-иностранца на приобретение ребёнком гражданства РФ?',
        answer:
          'Нет, согласие второго родителя, имеющего иностранное гражданство, не требуется.',
      },
      {
        question:
          'В каком случае считается, что у ребёнка имеется единственный родитель?',
        answer:
          'Так происходит в ряде случаев, когда второй родитель умер, пропал без вести, лишён родительских прав, либо запись о нем в свидетельстве о рождении ребёнка отсутствует или внесена со слов матери.',
      },
      {
        question:
          'Может ли гражданин России оформить гражданство другой страны и какие могут быть для него последствия?',
        answer:
          'Да, может, законодательством РФ это не запрещено. Однако о факте получения ВНЖ или гражданства другого государства необходимо уведомить МВД в срок до 60 дней с момента въезда на территорию России (можно онлайн через Госуслуги).',
      },
      {
        question:
          'Могут ли граждане Украины, ранее проживавшие на территории Крыма, ДНР, ЛНР, Запорожской и Херсонской области, подать на российское гражданство в консульствах РФ?',
        answer:
          'Нет, оформить гражданство РФ для данной категории лиц возможно только на территории России в органах МВД.',
      },
    ],
    [
      {
        question:
          'Who can contact the consulate to obtain citizenship for a child?',
        answer:
          'One of the child’s parents (legal representative) who has Russian citizenship.',
      },
      {
        question:
          'Is consent required from the second foreign parent for a child to acquire Russian citizenship?',
        answer:
          'No, the consent of the second parent who has foreign citizenship is not required.',
      },
      {
        question:
          'In what case is it considered that a child has a single parent?',
        answer:
          'This happens in a number of cases when the second parent has died, gone missing, been deprived of parental rights, or there is no entry about him in the child’s birth certificate or was made according to the words of the mother.',
      },
      {
        question:
          'Can a Russian citizen obtain citizenship of another country and what might be the consequences for him?',
        answer:
          'Yes, the legislation of the Russian Federation does not prohibit this. However, the fact of obtaining a residence permit or citizenship of another state must be notified to the Ministry of Internal Affairs within 60 days from the date of entry into the territory of Russia (this can be done online through State Services).',
      },
      {
        question:
          'Can citizens of Ukraine who previously lived in the territory of Crimea, DPR, LPR, Zaporozhye and Kherson regions apply for Russian citizenship at the consulates of the Russian Federation?',
        answer:
          'No, it is possible to obtain Russian citizenship for this category of persons only on the territory of Russia through the Ministry of Internal Affairs.',
      },
    ],
  ],

  workflow: {
    requestServiceType: {
      title: [
        'Что необходимо сделать?',
        'What needs to be done?',
        '需要做什么？',
        '¿Qué se necesita hacer?',
      ],
      options: serviceTypes,
    },
    requestPersonWhoObtainCitizenship: {
      title: [
        'Кому нужно оформить гражданство?',
        'Who needs to obtain citizenship?',
        '谁需要获得公民身份？',
        '¿Quién necesita obtener la ciudadanía?',
      ],
      options: [
        [
          'Мне (бывшему гражданину СССР)',
          'Me (former USSR citizen)',
          '我（前苏联公民）',
          'Yo (exciudadano de la URSS)',
        ],
        ['Ребенку', 'Child', '孩子', 'Niño'],
      ],
    },
    requestCase: {
      title: [
        'Выберите ситуацию, подходящую под ваш случай',
        'Select the situation that applies to your case',
        '选择适合您情况的情况',
        'Seleccione la situación que se aplica a su caso',
      ],
      options: [
        [
          'На момент 06.02.1992 г. я проживал на территории РФ, но не был официально зарегистрирован по месту жительства',
          'As of 02.06.1992, I was residing in the territory of the Russian Federation but was not officially registered at my place of residence',
          '截至1992年2月6日，我在俄罗斯联邦境内居住，但未在我的居住地正式登记',
          'A partir del 02.06.1992, residía en el territorio de la Federación Rusa pero no estaba oficialmente registrado en mi lugar de residencia',
        ],
        [
          'Я проживал и проживаю в другом государстве, входившем в состав СССР, и не получил гражданство этого государства',
          'I was and am residing in another state that was part of the USSR and did not acquire citizenship of that state',
          '我在另一个曾是苏联的国家居住，并没有获得该国的公民身份',
          'Residía y resido en otro estado que formaba parte de la URSS y no adquirí la ciudadanía de ese estado',
        ],
        [
          'Я - ветеран Великой Отечественной войны, афганской войны или войны в Чечне',
          'I am a veteran of the Great Patriotic War, the Afghan War, or the Chechen War',
          '我是一名伟大卫国战争、阿富汗战争或车臣战争的老兵',
          'Soy veterano de la Gran Guerra Patriótica, la Guerra de Afganistán o la Guerra de Chechenia',
        ],
      ],
    },
    requestPersonWhoRenounceCitizenship: {
      title: [
        'Кому нужно выйти из гражданства РФ?',
        'Who needs to renounce Russian citizenship?',
        '谁需要放弃俄罗斯公民身份？',
        '¿Quién necesita renunciar a la ciudadanía rusa?',
      ],
      options: [
        ['Мне', 'Me', '我', 'Yo'],
        [
          'Мне и моему ребенку',
          'Me and my child',
          '我和我的孩子',
          'Yo y mi hijo',
        ],
        [
          'Только моему ребенку',
          'Only my child',
          '只有我的孩子',
          'Solo mi hijo',
        ],
      ],
    },
    requestBirthDate: {
      title: [
        'Укажите дату вашего рождения',
        'Enter the date of your birth',
        '请输入您的出生日期',
        'Ingrese la fecha de su nacimiento',
      ],
      format: ['ДД.ММ.ГГГГ', 'DD.MM.YYYY', 'ДД.ММ.ГГГГ', 'DD.MM.AAAA'],
      label: ['Дата рождения', 'Birth date', '出生日期', 'Fecha de nacimiento'],
    },
    requestChildsBirthDate: {
      title: [
        'Укажите дату рождения ребёнка',
        'Enter the date of your child’s birth',
        '请输入您孩子的出生日期',
        'Ingrese la fecha de nacimiento de su hijo',
      ],
      label: [
        'Дата рождения ребёнка',
        'Child’s birth date',
        '孩子的出生日期',
        'Fecha de nacimiento del niño',
      ],
    },
    requestChildsPlaceOfBirth: {
      title: [
        'Где родился ребенок?',
        'Where was the child born?',
        '孩子在哪里出生？',
        '¿Dónde nació el niño?',
      ],
      options: [
        ['В России', 'In Russia', '在俄罗斯', 'En Rusia'],
        [
          'За пределами России',
          'Outside Russia',
          '在俄罗斯以外',
          'Fuera de Rusia',
        ],
      ],
    },
    requestParentsCitizenship: {
      title: {
        default: [
          'Гражданство родителей (законных представителей) ребёнка',
          'Citizenship of parents (legal guardians) of the child',
          '父母（法定监护人）的国籍',
          'Ciudadanía de los padres (tutores legales) del niño',
        ],
        onChildBirth: [
          'Гражданство родителей (законных представителей) на момент рождения ребёнка',
          "Citizenship of parents (legal guardians) at the time of the child's birth",
          '父母（法定监护人）在孩子出生时的国籍',
          'Ciudadanía de los padres (tutores legales) en el momento del nacimiento del niño',
        ],
      },
      options: [
        [
          'Оба родителя имели гражданство РФ',
          'Both parents had Russian citizenship',
          '双亲都拥有俄罗斯公民身份',
          'Ambos padres tenían ciudadanía rusa',
        ],
        [
          'Единственный родитель ребёнка имел гражданство РФ',
          'The only parent of the child had Russian citizenship',
          '孩子的唯一监护人拥有俄罗斯国籍',
          'El único padre del niño tenía ciudadanía rusa',
        ],
        [
          'Один из родителей имел гражданство РФ, а другой родитель являлся лицом без гражданства, или был признан безвестно отсутствующим, или место его нахождения было неизвестно',
          'One parent had Russian citizenship, and the other parent was stateless, or was declared missing, or their whereabouts were unknown',
          '一个父母有俄罗斯国籍，另一个父母是无国籍者，或被宣布失踪，或下落不明',
          'Un padre tenía ciudadanía rusa y el otro era apátrida, o fue declarado desaparecido, o su paradero era desconocido',
        ],
        [
          'Один родитель имеет гражданство РФ, второй — иностранный гражданин',
          'One parent has Russian citizenship, the other is a foreign citizen',
          '一个父母拥有俄罗斯国籍，另一个是外国公民',
          'Un padre tiene ciudadanía rusa, el otro es un ciudadano extranjero',
        ],
      ],
    },
    requestCitizenshipInfo: {
      title: [
        'Где и как вы получили гражданство РФ?',
        'Where and how did you obtain Russian citizenship?',
        '您在哪里以及如何获得俄罗斯公民身份？',
        '¿Dónde y cómo obtuviste la ciudadanía rusa?',
      ],
      options: {
        previousPeriod: [
          [
            'На момент 06.02.1992 г. я проживал и был зарегистрирован на территории РФ и приобрёл гражданство автоматически',
            'As of 02.06.1992, I was residing and registered in the territory of the Russian Federation and acquired citizenship automatically',
            '截至1992年2月6日，我在俄罗斯联邦境内居住并登记，自动获得公民身份',
            'A partir del 02.06.1992, residía y estaba registrado en el territorio de la Federación Rusa y adquirí la ciudadanía automáticamente',
          ],
          [
            'В посольстве или консульстве РФ за рубежом',
            'At the Russian embassy or consulate abroad',
            '在俄罗斯驻国外大使馆或领事馆',
            'En la embajada o consulado ruso en el extranjero',
          ],
          [
            'В органах ФМС/МВД на территории России',
            'At the FMS/MVD offices in the territory of Russia',
            '在俄罗斯境内的FMS/MVD办公室',
            'En las oficinas de FMS/MVD en el territorio de Rusia',
          ],
          [
            'Во время службы в частях Российской армии',
            'During service in the Russian army',
            '在俄罗斯军队服役期间',
            'Durante el servicio en el ejército ruso',
          ],
          ['Не знаю', "I don't know", '不知道', 'No sé'],
        ],
        nowadays: [
          [
            'Я родился в России и получил гражданство по рождению',
            'I was born in Russia and acquired citizenship by birth',
            '我在俄罗斯出生，获得了出生时的国籍',
            'Nací en Rusia y obtuve la ciudadanía por nacimiento',
          ],
          [
            'В посольстве или консульстве РФ за рубежом',
            'At the Russian embassy or consulate abroad',
            '在俄罗斯驻国外大使馆或领事馆',
            'En la embajada o consulado ruso en el extranjero',
          ],
          [
            'В органах ФМС/МВД на территории России',
            'At the FMS/MVD offices in the territory of Russia',
            '在俄罗斯境内的FMS/MVD办公室',
            'En las oficinas de FMS/MVD en el territorio de Rusia',
          ],
          ['Не знаю', "I don't know", '不知道', 'No sé'],
        ],
      },
    },
  },
  orderDescription: [
    [
      {
        headerText: 'Оформление заявки',
        cardText:
          'Опишите вашу ситуацию. Вы можете направить нам требуемые документы как одновременно с заявкой, так и напрямую юристу после консультации с ним',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Заполнение заявления (и опросного листа)',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали и заполнит онлайн-заявление на консульском портале. Вы получите заполненный документ, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Подача документов в консульство',
        cardText:
          'В день записи приходите в консульство с пакетом документов для подачи на получение, выход или проверку гражданства',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submitting an application',
        cardText:
          'Describe your situation. You can send us the required documents either simultaneously with the application or directly to the lawyer after consultation with him/her',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Filling out the application (and questionnaire)',
        cardText:
          'Our lawyer will contact you, discuss the details and fill out an online application on the consulate portal. You will receive a completed document that you will have to take with you to the consulate. The service completion time is 2 business days from the date of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Scheduling an appointment at the consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance (according to the selected tariff)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Submitting documents to the consulate',
        cardText:
          'On the day of your appointment, arrive to the consulate with a package of documents to obtain, renounce, or verify the citizenship',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: '提交申请',
        cardText:
          '描述您的情况。您可以在申请时同时发送所需文件，或在与律师咨询后直接发送给他/她',
        cardImage: checkBoxImage,
      },
      {
        headerText: '填写申请表（和问卷）',
        cardText:
          '我们的律师会联系您，讨论细节并在领事馆门户网站上填写在线申请。您将收到一份填写好的文件，您需要带着它去领事馆。服务完成时间为付款后的2个工作日。',
        cardImage: documentBrown,
      },
      {
        headerText: '预约领事馆面谈',
        cardText:
          '您可以自己预约领事馆面谈，或者在我们帮助下预约（根据选择的收费标准）',
        cardImage: calendarImage,
      },
      {
        headerText: '向领事馆提交文件',
        cardText: '在预约当天，携带文件包前往领事馆以申请、放弃或验证国籍',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Presentación de una solicitud',
        cardText:
          'Describa su situación. Puede enviarnos los documentos requeridos simultáneamente con la solicitud o directamente al abogado después de consultarlo/a',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Rellenando la solicitud (y el cuestionario)',
        cardText:
          'Nuestro abogado se pondrá en contacto con usted, discutirá los detalles y completará una solicitud en línea en el portal consular. Recibirá un documento completado que deberá llevar consigo al consulado. El tiempo de finalización del servicio es de 2 días hábiles a partir de la fecha de pago.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Programar una cita en el consulado',
        cardText:
          'Programe una cita en el consulado usted mismo o con nuestra asistencia (de acuerdo con la tarifa seleccionada)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Presentación de documentos en el consulado',
        cardText:
          'El día de su cita, llegue al consulado con un paquete de documentos para obtener, renunciar o verificar la ciudadanía',
        cardImage: stampImage,
      },
    ],
  ],
  priceText1: [
    'Стоимость услуги зависит от индивидуальных обстоятельств заявителя и будет уточнена после консультации с юристом.',
    'The cost of the service depends on the individual circumstances of the applicant and will be clarified after consultation with a lawyer.',
    '服务费用取决于申请人的个人情况，并将在与律师咨询后明确。',
    'El costo del servicio depende de las circunstancias individuales del solicitante y se aclarará después de la consulta con un abogado.',
  ],
}

export default vocabularyCitizenship

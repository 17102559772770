import React, { useContext } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import { Typography, useTheme } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import GlobalContext from '../../../GlobalContext'

export default function PriceCard({ data, squareImg, colorBorder }) {
  const { state } = useContext(GlobalContext)
  const theme = useTheme()

  const formatPrice = price => {
    if (state.country === 'Kazakhstan' || state.country === 'Казахстан') {
      if (price.length > 3) {
        const arr = price.split('-')
        const formattedArr = arr.map(val => (parseFloat(val) * 478).toFixed(2))
        return formattedArr.join('-') + ' ₸'
      } else {
        price = price * 478 + ' ₸'
      }
    } else if (state.currency === 'usd') {
      price = price + ' $'
    } else if (state.currency === 'eur') {
      if (price.length > 3) {
        const arr = price.split('-')
        const formattedArr = arr.map(val => (parseFloat(val) /1.1).toFixed(0))
        return formattedArr.join('-') + ' €'
      } else {
     let arr=price.split()
        price=arr.map(val => (parseFloat(val) /1.1).toFixed(0)) 
        price = price + ' €'
      }


     
    }

    return price
  }

  const price = formatPrice(data.value || '')

  return (
    <Box display='flex' key={data.header} sx={{ height: '100%' }}>
      <Card
        elevation={0}
        sx={{
          width: '100%',
          height: '100%',
          border: colorBorder
            ? `2px solid ${theme.palette.primary.primary}`
            : `2px solid ${theme.palette.primary.outlineVariant}`,
          borderRadius: '12px',
          backgroundColor: theme.palette.primary.surface,
        }}
      >
        <CardContent>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{ width: 1 }}
          >
            <Typography component='div' variant='titleSmall'>
              {data.header}
            </Typography>

            <Typography
              component='div'
              variant='h2Small'
              sx={{
                color: theme.palette.primary.primary,
                display: 'flex',
                justifyContent: 'flex-end',
                minWidth: 1 / 3,
                ml: 1,
              }}
            >
              {price}
            </Typography>
          </Box>

          {data.list.map(val => (
            <Box
              key={uuidv4()}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                m: '16px',
              }}
            >
              <img
                src={squareImg}
                alt='logo'
                width={20}
                style={{ marginRight: '16px' }}
              />
              <Typography variant='bodySmall' component='div'>
                {val}
              </Typography>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Box>
  )
}

import { useEffect, useState } from 'react'
import { getAdminData } from 'api/admin'
import {
  Button,
  Typography,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Box,
  TextField,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const InfoIp = () => {
  const [countryStats, setCountryStats] = useState(null)
  const [openCountries, setOpenCountries] = useState({})
  const [sortBy, setSortBy] = useState('visits-desc')
  const [allOpen, setAllOpen] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [chartVisible, setChartVisible] = useState(true) // State for chart visibility

  const setDateRange = days => {
    const end = new Date()
    const start = new Date()
    start.setDate(end.getDate() - days)

    setStartDate(start.toISOString().split('T')[0])
    setEndDate(end.toISOString().split('T')[0])
  }

  const data = async () => {
    let response = await getAdminData()

    if (response?.visits) {
      const filteredData = response.visits.filter(item => {
        if (!item.createdAt) return false
        const visitDate = new Date(item.createdAt)
        if (startDate && visitDate < new Date(startDate)) return false
        if (endDate && visitDate > new Date(endDate)) return false

        return true
      })

      const groupedData = filteredData.reduce((acc, item) => {
        if (!acc[item.country]) {
          acc[item.country] = {
            count: 0,
            requests: [],
          }
        }

        acc[item.country].count += 1
        acc[item.country].requests.push(item)

        return acc
      }, {})

      const sortedCountries = Object.keys(groupedData).sort((a, b) => {
        const countA = groupedData[a].count
        const countB = groupedData[b].count

        if (sortBy === 'visits-desc') {
          return countB - countA
        } else {
          return countA - countB
        }
      })

      const sortedCountryStats = {}
      sortedCountries.forEach(country => {
        sortedCountryStats[country] = groupedData[country]
      })

      setCountryStats(sortedCountryStats)

      const initialOpenStates = {}
      sortedCountries.forEach(country => {
        initialOpenStates[country] = allOpen
      })
      setOpenCountries(initialOpenStates)
    }
  }

  useEffect(() => {
    data()
  }, [sortBy, allOpen, startDate, endDate])

  const toggleCountry = country => {
    setOpenCountries(prevState => ({
      ...prevState,
      [country]: !prevState[country],
    }))
  }

  const handleSortChange = event => {
    setSortBy(event.target.value)
  }

  const toggleAllCountries = () => {
    setAllOpen(!allOpen)
  }

  const handleStartDateChange = event => {
    setStartDate(event.target.value)
  }

  const handleEndDateChange = event => {
    setEndDate(event.target.value)
  }

  const getChartData = () => {
    if (!countryStats) return { labels: [], datasets: [] }

    // Sort countries by number of visits and slice to get top 10
    const sortedCountries = Object.entries(countryStats)
      .sort((a, b) => b[1].count - a[1].count) // Sort by count
      .slice(0, 10) // Get top 10
      .map(([country, data]) => ({ country, ...data }))

    const labels = sortedCountries.map(({ country }) => country)
    const data = sortedCountries.map(({ count }) => count)

    const generateBarColors = count => {
      const colors = []
      for (let i = 0; i < count; i++) {
        const color = `hsl(${((i * 360) / count) % 360}, 70%, 50%)`
        colors.push(color)
      }
      return colors
    }

    const colors = generateBarColors(labels.length)

    return {
      labels,
      datasets: [
        {
          label: 'Number of Visits',
          data,
          backgroundColor: colors,
          borderColor: colors.map(color => color.replace('0.2', '1')), // Adjust border color
          borderWidth: 1,
        },
      ],
    }
  }

  return (
    <>
      {countryStats ? (
        <div>
          <Typography variant='h4' gutterBottom>
            IP Requests Grouped by Country
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <Select
              value={sortBy}
              onChange={handleSortChange}
              style={{ marginRight: 16 }}
            >
              <MenuItem value='visits-desc'>Visits - Descending</MenuItem>
              <MenuItem value='visits-asc'>Visits - Ascending</MenuItem>
            </Select>
            <Button variant='contained' onClick={toggleAllCountries}>
              {allOpen ? 'Hide All' : 'Open All'}
            </Button>
            <Button
              variant='contained'
              color='error'
              onClick={() => setChartVisible(!chartVisible)}
              style={{ marginLeft: 16 }}
            >
              {chartVisible ? 'Hide Chart' : 'Show Chart'}
            </Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
            <Button
              variant='outlined'
              onClick={() => setDateRange(1)}
              style={{ marginRight: 8 }}
            >
              Last 24 Hours
            </Button>
            <Button
              variant='outlined'
              onClick={() => setDateRange(3)}
              style={{ marginRight: 8 }}
            >
              Last 3 Days
            </Button>
            <Button
              variant='outlined'
              onClick={() => setDateRange(7)}
              style={{ marginRight: 8 }}
            >
              Last Week
            </Button>
            <Button
              variant='outlined'
              onClick={() => setDateRange(14)}
              style={{ marginRight: 8 }}
            >
              Last 2 Weeks
            </Button>
            <TextField
              label='Start Date'
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={handleStartDateChange}
              style={{ marginRight: 16 }}
            />
            <TextField
              label='End Date'
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={handleEndDateChange}
              style={{ marginRight: 16 }}
            />
          </Box>
          {chartVisible && (
            <Box sx={{ marginBottom: 2 }}>
              <Bar
                data={getChartData()}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          return `${context.label}: ${context.raw}`
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      beginAtZero: true,
                      barPercentage: 0.8,
                      categoryPercentage: 0.9,
                    },
                    y: {
                      beginAtZero: true,
                    },
                  },
                }}
              />
            </Box>
          )}
          {Object.keys(countryStats).map(country => (
            <Accordion
              key={country}
              expanded={openCountries[country]}
              onChange={() => toggleCountry(country)}
              style={{ marginBottom: 8 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${country}-content`}
                id={`${country}-header`}
              >
                <Typography>{`${country} - ${countryStats[country].count} visits`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {countryStats[country].requests.map(item => (
                    <ListItem key={item._id}>
                      <ListItemText primary={`IP Address: ${item.requestIp}`} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ) : null}
    </>
  )
}

export default InfoIp

// search keys
export const optionsCn = [
  '委托书',
  '一般委托书',
  '房地产赠与委托书',
  '房地产购买委托书',
  '房地产出售委托书',
  '股份出售委托书',
  '汽车委托书',
  '遗产案件处理委托书',
  '养老金申请委托书',
  '母亲资本申请委托书',
  '银行账户管理委托书',
  '文件请求与公证委托书',
  '法庭代理委托书',
  '政府机关代理委托书',
  '撤销委托书的命令',
  '同意书、声明、合同',
  '护照',
  '儿童出境同意书',
  '配偶同意书',
  '有限责任公司股份变更',
  '遗产继承申请/放弃申请',
  '注销登记申请',
  '养老金申请或恢复养老金支付申请',
  '遗嘱',
  '婚姻合同',
  '财产分割协议',
  '抚养费协议',
  '担保合同',
  '权利转让合同（转让）',
  '证明',
  '无犯罪记录证明（有或没有犯罪记录）',
  '婚姻能力申请（家庭组成、婚姻状况或身份）',
  '国外永久居留证明',
  '亲自到场证明',
  '在境外完成（未完成）有偿工作的证明',
  '民事登记处的文件（出生、死亡、婚姻、离婚、更名证明）',
  '家庭状况证明',
  '工作和劳动经历证明',
  '兵役证明',
  '法人文件',
  '法人一般委托书',
  '股份购买或出售委托书',
  '法人法庭代理委托书',
  '法人在俄罗斯政府机关的代理委托书',
  '税务局法人注册申请',
  '税务局变更申请',
  '税务局法人重组申请',
  '民事登记处服务',
  '儿童出生登记',
  '收养登记',
  '父亲身份确认',
  '婚姻登记',
  '离婚登记',
  '更名登记',
  '死亡登记',
  '国籍',
  '检查俄罗斯国籍',
  '获得俄罗斯国籍',
  '退出俄罗斯国籍',
  '文件合法化',
  '领事馆登记',

  '5年和10年护照的区别',
  '如何填写工作经历信息',
  '回国证书',
].sort((a, b) => a.localeCompare(b))

export const optionsEsp = [
  'Poderes',
  'Poder general',
  'Poder para donar bienes inmuebles',
  'Poder para comprar bienes inmuebles',
  'Poder para vender bienes inmuebles',
  'Poder para vender una parte de la sociedad',
  'Poder para automóvil',
  'Poder para llevar a cabo un asunto hereditario',
  'Poder para la tramitación de pensiones',
  'Poder para la obtención de capital materno',
  'Poder para gestionar cuentas bancarias',
  'Poder para solicitar y apostillar documentos',
  'Poder para representación en juicio',
  'Poder para representación ante organismos gubernamentales',
  'Disposición para revocar el poder',
  'Consentimientos, Declaraciones, Contratos',
  'Pasaporte',
  'Consentimiento para la salida del niño fuera de la RF',
  'Consentimiento del cónyuge',
  'Cambio de participación en una sociedad de responsabilidad limitada',
  'Solicitud para aceptar/rechazar la herencia',
  'Solicitud para dar de baja el registro',
  'Solicitud para la tramitación de pensiones o para la reactivación de su pago',
  'Testamento',
  'Contrato matrimonial',
  'Acuerdo sobre la división de bienes',
  'Acuerdo sobre pensiones alimenticias',
  'Contrato de garantía',
  'Contrato de cesión de derechos (cesión)',
  'Certificados',
  'Certificado de antecedentes penales (sobre la ausencia o existencia de antecedentes)',
  'Solicitud sobre la capacidad matrimonial (sobre la composición familiar, situación o estado familiar)',
  'Certificado de confirmación de residencia permanente fuera de Rusia',
  'Acta de comparecencia personal',
  'Certificado de ejecución (no ejecución) de trabajo remunerado en el extranjero',
  'Documentos del registro civil (certificado de nacimiento, defunción, matrimonio, divorcio, cambio de nombre)',
  'Certificado de situación familiar',
  'Certificado de experiencia laboral y actividad',
  'Certificado de cumplimiento del servicio militar',
  'Documentos para personas jurídicas',
  'Poder general (para personas jurídicas)',
  'Poder para la compra o venta de una parte de la sociedad',
  'Poder para representación en juicio (para personas jurídicas)',
  'Poder para representación ante organismos gubernamentales de Rusia (para personas jurídicas)',
  'Solicitud al impuesto para el registro de una persona jurídica',
  'Solicitud al impuesto para la introducción de cambios',
  'Solicitud al impuesto para la reorganización de una persona jurídica',
  'Servicios del registro civil',
  'Registro del nacimiento de un niño',
  'Registro de adopción',
  'Establecimiento de la paternidad',
  'Registro del matrimonio',
  'Registro del divorcio',
  'Registro del cambio de nombre',
  'Registro de defunción',
  'Ciudadanía',
  'Verificación de la existencia de ciudadanía de la RF',
  'Obtención de ciudadanía de la RF',
  'Renuncia a la ciudadanía de la RF',
  'Legalización de documentos',
  'Inscripción en el consulado',

  'Diferencias entre pasaporte de 5 y 10 años',
  'Cómo indicar información sobre la actividad laboral',
  'Certificado de regreso',
].sort((a, b) => a.localeCompare(b))

export const optionsRu = [
  'Доверенности',
  'Генеральная доверенность',
  'Доверенность на дарение недвижимости',
  'Доверенность на покупку недвижимости',
  'Доверенность на продажу недвижимости',
  'Доверенность на продажу доли общества',
  'Доверенность на машину',
  'Доверенность на ведение наследственного дела',
  'Доверенность на оформление пенсии',
  'Доверенность на оформление материнского капитала',
  'Доверенность на управление банковскими счетами',
  'Доверенность на истребование и апостилирование документов',
  'Доверенность на представительство в суде',
  'Доверенность на представительство в госорганах',
  'Распоряжение об отмене доверенности',
  'Согласия, Заявляения, Договоры',
  'Загранпаспорт',
  'Согласие на выезд ребёнка за пределы РФ',
  'Согласие супруга',
  'Изменение доли в ООО',
  'Заявление на вступление / отказ от наследства',
  'Заявление на снятие с регистрационного учёта',
  'Заявление на оформление пенсии или восстановление её выплаты',
  'Завещание',
  'Брачный договор',
  'Соглашение о разделе имущества',
  'Соглашение об алиментах',
  'Договор поручительства',
  'Договор уступки права требования (цессии)',
  'Справки',
  'Справка о несудимости (об отсутствии или наличии судимости)',
  'Заявление о брачной правоспособности (о составе семьи, о семейном положении или статусе)',
  'Справка о подтверждении ПМЖ за пределами России',
  'Акт о личной явке',
  'Справка о выполнении (невыполнении) оплачиваемой работы за пределами РФ',
  'Документы ЗАГС (свидетельство о рождении, смерти, браке, разводе, смене имени)',
  'Справка о семейном положении',
  'Справка о стаже и трудовой деятельности',
  'Справка о прохождении воинской службы',
  'Документы для юридических лиц',
  'Генеральная доверенность (для юр. лиц)',
  'Доверенность на покупку или продажу доли общества',
  'Доверенность на представительство в суде (для юр. лиц)',
  'Доверенность на представительство в госорганах России (для юр. лиц)',
  'Заявление в налоговую на регистрацию юридического лица',
  'Заявление в налоговую на внесение изменений',
  'Заявление в налоговую на реорганизацию юридического лица',
  'Услуги ЗАГС',
  'Регистрация рождения ребёнка',
  'Регистрация усыновления (удочерения)',
  'Установление отцовства',
  'Регистрация заключения брака',
  'Регистрация расторжения брака',
  'Регистрация перемены имени',
  'Регистрация смерти',
  'Гражданство',
  'Проверка наличия гражданства РФ',
  'Получение гражданства РФ',
  'Выход из гражданства РФ',
  'Легализация документов',
  'Запись в консульство',

  'Отличия загранпаспорта сроком на 5 и 10 лет',
  'Как указывать сведения о трудовой деятельности',
  'Свидетельство на возвращение',
].sort((a, b) => a.localeCompare(b))

const optionsEn = [
  'Power of Attorney',
  'General Power of Attorney',
  'Power of Attorney for Gift of Real Estate',
  'Power of Attorney for Purchase of Real Estate',
  'Power of Attorney for Sale of Real Estate',
  'Power of Attorney for Sale of Company Shares',
  'Power of Attorney for a Car',
  'Power of Attorney for Inheritance Matters',
  'Power of Attorney for Pension Matters',
  'Power of Attorney for Maternity Capital',
  'Power of Attorney for Managing Bank Accounts',
  'Power of Attorney for Retrieval and Apostille of Documents',
  'Power of Attorney for Legal Representation in Court',
  'Power of Attorney for Representation in Government Agencies',
  'Revocation of Power of Attorney',
  'Consents, Statements, Contracts',
  'International Passport',
  'Consent for a Child to Travel Outside the Russian Federation',
  'Spousal Consent',
  'Change of Share in an LLC',
  'Application for Inheritance Entry/Refusal',
  'Application for Deregistration',
  'Application for Pension Assignment or Restoration',
  'Will',
  'Prenuptial Agreement',
  'Property Division Agreement',
  'Alimony Agreement',
  'Surety Agreement',
  'Assignment Agreement',
  'Certificates',
  'Criminal Record Certificate (Presence or Absence of Conviction)',
  'Statement of Marital Capacity (on family composition, marital status, or status)',
  'Certificate of Permanent Residence Outside Russia',
  'Personal Attendance Act',
  'Certificate of Employment Outside Russia',
  'ZAGS Documents (birth, death, marriage, divorce, name change certificates)',
  'Certificate of Marital Status',
  'Certificate of Work Experience and Employment',
  'Certificate of Military Service',
  'Documents for Legal Entities',
  'General Power of Attorney (for legal entities)',
  'Power of Attorney for Buying or Selling a Share of an LLC',
  'Power of Attorney for Legal Representation in Court (for legal entities)',
  'Power of Attorney for Representation in Government Agencies (for legal entities)',
  'Application to the Tax Office for Registration of a Legal Entity',
  'Application to the Tax Office for Making Changes',
  'Application to the Tax Office for Reorganization of a Legal Entity',
  'ZAGS Services',
  'Child Birth Registration',
  'Adoption Registration',
  'Establishment of Paternity',
  'Marriage Registration',
  'Divorce Registration',
  'Name Change Registration',
  'Death Registration',
  'Citizenship',
  'Russian Citizenship Verification',
  'Obtaining Russian Citizenship',
  'Renouncing the Russian Citizenship',
  'Document Legalization',
  'Appointment at the Consulate',

  'Differences between 5-year and 10-year international passports',
  'How to provide information about work activities',
  'Certificate for return',
].sort((a, b) => a.localeCompare(b))

export const options = [optionsRu, optionsEn, optionsCn, optionsEsp]

export const vocabulary = {
  searchResults: [
    'Результаты поиска',
    'Search results',
    '搜索结果',
    'Resultados de búsqueda',
  ],
  searchModalInputPlaceHolder: [
    'Найдите нужную информацию во ключевым словам',
    'Find necessary information by key words',
    '通过关键词找到必要的信息',
    'Encuentre la información necesaria mediante palabras clave',
  ],
  page: ['Страница', 'Page', '页面', 'Página'],
  notFound: [
    'Ничего не найдено',
    'search results are not found',
    '未找到任何结果',
    'no se encontraron resultados',
  ],
}

export const titleByPath = {
  '/poa': ['Доверенности', 'Power of Attorney', '授权书', 'Poder Notarial'],
  '/about': ['О нас', 'About Us', '关于我们', 'Sobre nosotros'],

  '/poa?section=poa&service=general&id=0': [
    'Генеральная доверенность',
    'General Power of Attorney',
    '一般授权书',
    '一般授权书',
  ],
  '/poa?section=poa&service=inheritance+of+real+estate&id=1': [
    'Доверенность на дарение недвижимости',
    'Power of Attorney for Gift of Real Estate',
    '赠与不动产的授权书',
    'Poder Notarial para Donación de Bienes Inmuebles',
  ],
  '/poa?section=poa&service=for+the+purchase+of+real+estate&id=2': [
    'Доверенность на покупку недвижимости',
    'Power of Attorney for Purchase of Real Estate',
    '购买不动产的授权书',
    'Poder Notarial para Compra de Bienes Inmuebles',
  ],
  '/poa?section=poa&service=for+the+sale+of+real+estate&id=3': [
    'Доверенность на продажу недвижимости',
    'Power of Attorney for Sale of Real Estate',
    '出售不动产的授权书',
    'Poder Notarial para Venta de Bienes Inmuebles',
  ],
  '/poa?section=poa&service=for+the+sale+of+a+share+in+a+company&id=4': [
    'Доверенность на продажу доли общества',
    'Power of Attorney for Sale of Company Shares',
    '出售公司股份的授权书',
    'Poder Notarial para Venta de Acciones de la Empresa',
  ],
  '/poa?section=poa&service=for+a+car&id=5': [
    'Доверенность на машину',
    'Power of Attorney for a Car',
    '汽车的授权书',
    'Poder Notarial para un Coche',
  ],
  '/poa?section=poa&service=for+handling+inheritance+matters&id=6': [
    'Доверенность на ведение наследственного дела',
    'Power of Attorney for Inheritance Matters',
    '处理继承事务的授权书',
    'Poder Notarial para Asuntos de Herencia',
  ],
  '/poa?section=poa&service=for+processing+pension+documents&id=7': [
    'Доверенность на оформление пенсии',
    'Power of Attorney for Pension Matters',
    '养老金事务的授权书',
    'Poder Notarial para Asuntos de Pensiones',
  ],
  '/poa?section=poa&service=for+processing+maternity+capital+documents&id=8': [
    'Доверенность на оформление материнского капитала',
    'Power of Attorney for Maternity Capital',
    '母亲资本事务的授权书',
    'Poder Notarial para Capital Materno',
  ],
  '/poa?section=poa&service=for+managing+bank+accounts&id=9': [
    'Доверенность на управление банковскими счетами',
    'Power of Attorney for Managing Bank Accounts',
    '管理银行账户的授权书',
    'Poder Notarial para Gestión de Cuentas Bancarias',
  ],
  '/poa?section=poa&service=for+retrieval+and+apostille+of+documents&id=10': [
    'Доверенность на истребование и апостилирование документов',
    'Power of Attorney for Retrieval and Apostille of Documents',
    '提取和公证文件的授权书',
    'Poder Notarial para Recuperación y Apostilla de Documentos',
  ],
  '/poa?section=poa&service=for+legal+representation+in+court&id=11': [
    'Доверенность на представительство в суде',
    'Power of Attorney for Legal Representation in Court',
    '法庭代理的授权书',
    'Poder Notarial para Representación Legal en el Tribunal',
  ],
  '/poa?section=poa&service=for+representation+in+government+agencies&id=12': [
    'Доверенность на представительство в госорганах',
    'Power of Attorney for Representation in Government Agencies',
    '在政府机构的代理授权书',
    'Poder Notarial para Representación en Agencias Gubernamentales',
  ],
  '/poa?section=poa&service=revocation+of+power+of+attorney&id=13': [
    'Распоряжение об отмене доверенности',
    'Revocation of Power of Attorney',
    '撤销授权书的通知',
    'Revocación del Poder Notarial',
  ],

  '/passport': [
    'Загранпаспорт',
    'International Passport',
    '国际护照',
    'Pasaporte Internacional',
  ],

  '/consent': [
    'Согласия, Заявляения, Договоры',
    'Consents, Statements, Contracts',
    '同意书、声明、合同',
    'Consentimientos, Declaraciones, Contratos',
  ],
  '/consent?section=consent&service=Consent&id=0&additionalServiceType=For%2520the%2520child%2520to%2520travel%2520outside%2520the%2520Russian%2520Federation':
    [
      'Согласие на выезд ребёнка за пределы РФ',
      'Consent for a Child to Travel Outside the Russian Federation',
      '儿童出境的同意书',
      'Consentimiento para que un Niño Viaje Fuera de la Federación Rusa',
    ],
  '/consent?section=consent&service=Consent&id=1': [
    'Согласие супруга',
    'Spousal Consent',
    '配偶同意书',
    'Consentimiento Conyugal',
  ],
  '/consent?section=consent&service=Consent&id=2': [
    'Изменение доли в ООО',
    'Change of Share in an LLC',
    '有限责任公司的股份变更',
    'Cambio de Participación en una LLC',
  ],
  '/consent?section=consent&service=Application&id=3&additionalServiceType=For%2520inheritance%2520entry':
    [
      'Заявление на вступление / отказ от наследства',
      'Application for Inheritance Entry/Refusal',
      '继承申请/拒绝',
      'Solicitud de Entrada/Negación de Herencia',
    ],
  '/consent?section=consent&service=Application&id=4&additionalServiceType=For%2520deregistration':
    [
      'Заявление на снятие с регистрационного учёта',
      'Application for Deregistration',
      '申请注销',
      'Solicitud de Cancelación de Registro',
    ],
  '/consent?section=consent&service=Application&id=5&additionalServiceType=For%2520pension%2520payment':
    [
      'Заявление на оформление пенсии или восстановление её выплаты',
      'Application for Pension Assignment or Restoration',
      '养老金申请或恢复支付',
      'Solicitud de Asignación o Restauración de Pensiones',
    ],
  '/consent?section=consent&service=Application&id=6&additionalServiceType=Will':
    ['Завещание', 'Will', '遗嘱', 'Testamento'],
  '/consent?section=consent&service=Agreement&id=7&additionalServiceType=Marriage%2520contract':
    [
      'Брачный договор',
      'Prenuptial Agreement',
      '婚前协议',
      'Acuerdo Prenupcial',
    ],
  '/consent?section=consent&service=Agreement&id=8&additionalServiceType=Property%2520division%2520agreement':
    [
      'Соглашение о разделе имущества',
      'Property Division Agreement',
      '财产分割协议',
      'Acuerdo de División de Propiedades',
    ],
  '/consent?section=consent&service=Agreement&id=9&additionalServiceType=Alimony%2520agreement':
    [
      'Соглашение об алиментах',
      'Alimony Agreement',
      '抚养费协议',
      'Acuerdo de Pensión Alimentaria',
    ],
  '/consent?section=consent&service=Agreement&id=10&additionalServiceType=Surety%2520agreement':
    [
      'Договор поручительства',
      'Surety Agreement',
      '担保协议',
      'Acuerdo de Fianza',
    ],
  '/consent?section=consent&service=Agreement&id=11&additionalServiceType=Assignment%2520agreement':
    [
      'Договор уступки права требования (цессии)',
      'Assignment Agreement',
      '债权转让协议',
      'Acuerdo de Cesión',
    ],

  '/certificates': ['Справки', 'Certificates', '证书', 'Certificados'],
  '/certificates?section=certificates&service=Consular+certificate&id=0&additionalServiceType=Criminal%2520record%2520certificate':
    [
      'Справка о несудимости (об отсутствии или наличии судимости)',
      'Criminal Record Certificate (Presence or Absence of Conviction)',
      '无犯罪记录证明（有无犯罪记录）',
      'Certificado de Antecedentes Penales (Presencia o Ausencia de Condena)',
    ],
  '/certificates?section=certificates&service=Consular+certificate&id=1&additionalServiceType=Marriage+certificate':
    [
      'Справка о браке (об отсутствии или наличии брака)',
      'Marriage Certificate (Presence or Absence of Marriage)',
      '结婚证明（有无婚姻关系）',
      'Certificado de Matrimonio (Presencia o Ausencia de Matrimonio)',
    ],
  '/certificates?section=certificates&service=Consular+certificate&id=2&additionalServiceType=Birth+certificate':
    [
      'Справка о рождении (об отсутствии или наличии родства)',
      'Birth Certificate (Presence or Absence of Kinship)',
      '出生证明（有无亲属关系）',
      'Certificado de Nacimiento (Presencia o Ausencia de Parentesco)',
    ],
  '/certificates?section=certificates&service=Consular+certificate&id=3&additionalServiceType=Residence+certificate':
    [
      'Справка о регистрации по месту жительства',
      'Residence Certificate',
      '居住证明',
      'Certificado de Residencia',
    ],
  '/certificates?section=certificates&service=Consular+certificate&id=4&additionalServiceType=Education+certificate':
    [
      'Справка об образовании',
      'Education Certificate',
      '教育证明',
      'Certificado de Educación',
    ],
  '/certificates?section=certificates&service=Consular+certificate&id=5&additionalServiceType=Social+security+certificate':
    [
      'Справка о наличии/отсутствии задолженности по социальным взносам',
      'Social Security Certificate (Presence or Absence of Debt)',
      '社会保障证明（有无债务）',
      'Certificado de Seguridad Social (Presencia o Ausencia de Deuda)',
    ],
}

import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'

import marriageRingsImage from '../images/marriage-black.png'

import {
  Baby,
  Buildings,
  Coins,
  FileText,
  House,
  Stamp,
} from '@phosphor-icons/react'

export const serviceTypes = [
  ['Согласие', 'Consent', '同意', 'Consentimiento'],
  ['Заявление', 'Application', '申请', 'Solicitud'],
  ['Договор, соглашение', 'Agreement', '协议', 'Acuerdo'],
]

export const consentTypes = [
  [
    'На выезд ребенка за пределы РФ',
    'For the child to travel outside the Russian Federation',
    '让孩子出境',
    'Para que el niño viaje fuera de la Federación Rusa',
  ],
  [
    'От супруга на покупку недвижимости',
    'From spouse for the purchase of real estate',
    '配偶购置房地产',
    'Del cónyuge para la compra de bienes raíces',
  ],
  [
    'От супруга на продажу недвижимости',
    'From spouse for the sale of real estate',
    '配偶出售房地产',
    'Del cónyuge para la venta de bienes raíces',
  ],
  [
    'От супруга на дарение недвижимости',
    'From spouse for the donation of real estate',
    '配偶赠与房地产',
    'Del cónyuge para la donación de bienes raíces',
  ],
  [
    'От супруга на залог недвижимости',
    'From spouse for the pledge of real estate',
    '配偶房地产抵押',
    'Del cónyuge para el empeño de bienes raíces',
  ],
  [
    'От супруга на покупку доли в ООО',
    'From spouse for the purchase of a share in LLC',
    '配偶购买有限责任公司股份',
    'Del cónyuge para la compra de una participación en la LLC',
  ],
  [
    'От супруга на продажу доли в ООО',
    'From spouse for the sale of a share in LLC',
    '配偶出售有限责任公司股份',
    'Del cónyuge para la venta de una participación en la LLC',
  ],
  [
    'От супруга на изменение доли в ООО',
    'From spouse for the change of a share in LLC',
    '配偶变更有限责任公司股份',
    'Del cónyuge para el cambio de una participación en la LLC',
  ],
  [
    'От супруга на дарение доли в ООО',
    'From spouse for the donation of a share in LLC',
    '配偶赠与有限责任公司股份',
    'Del cónyuge para la donación de una participación en la LLC',
  ],
  [
    'От супруга на залог доли в ООО',
    'From spouse for the pledge of a share in LLC',
    '配偶股份抵押',
    'Del cónyuge para el empeño de una participación en la LLC',
  ],
]

export const applicationTypes = [
  [
    'На вступление в наследство',
    'For inheritance entry',
    '继承登记',
    'Para la entrada en la herencia',
  ],
  [
    'На отказ от наследства',
    'For renunciation of inheritance',
    '放弃继承',
    'Para la renuncia de la herencia',
  ],
  [
    'На снятие с регистрационного учета',
    'For deregistration',
    '注销',
    'Para la cancelación del registro',
  ],
  [
    'На выплату пенсии',
    'For pension payment',
    '养老金领取',
    'Para el pago de pensiones',
  ],
  ['Завещание', 'Will', '遗嘱', 'Testamento'],
]

export const agreementTypes = [
  ['Брачный договор', 'Marriage contract', '婚姻合同', 'Contrato matrimonial'],
  [
    'Соглашение о разделе имущества',
    'Property division agreement',
    '财产分割协议',
    'Acuerdo de división de bienes',
  ],
  [
    'Соглашение об алиментах',
    'Alimony agreement',
    '抚养费协议',
    'Acuerdo de pensión alimenticia',
  ],
  [
    'Договор поручительства',
    'Surety agreement',
    '担保协议',
    'Acuerdo de garantía',
  ],
  [
    'Договор уступки права требования',
    'Assignment agreement',
    '债权转让协议',
    'Acuerdo de cesión de derechos',
  ],
]

const vocabularyConsent = {
  consentHeader: [
    'Согласия, Заявления, Договора',
    'Consents, Applications, Contracts',
    '同意，申请，合同',
    'Consentimientos, Solicitudes, Contratos',
  ],

  consentText1: [
    'Если вам из-за рубежа требуется оформить сделку с недвижимостью или заключить брачный договор, вам могут понадобиться нотариально оформленные документы, такие как согласие, заявление или договор.',
    'If you need to arrange a real estate deal or conclude a marriage contract while abroad, you may need notarized documents such as consent, application, or contract.',
    '如果您需要在国外办理房地产交易或签订婚姻合同，您可能需要公证文件，如同意书、申请或合同。',
    'Si necesita realizar un trato de bienes raíces o concluir un contrato matrimonial mientras está en el extranjero, es posible que necesite documentos notarizados como consentimiento, solicitud o contrato.',
  ],

  consentText2: [
    'Такие документы можно получить в ближайшем к вам Российском консульстве. Но обратите внимание, что сотрудники консульства не составляют тексты документов, они только заверяют заранее подготовленные в специальном формате проекты документов.',
    'You can obtain such documents at the nearest Russian consulate. However, please note that consulate staff do not draft document texts; they only certify prepared draft documents in a special format.',
    '您可以在离您最近的俄罗斯领事馆获得此类文件。但请注意，领事馆工作人员不撰写文件文本；他们只会对以特殊格式准备的草案文件进行认证。',
    'Puede obtener dichos documentos en el consulado ruso más cercano. Sin embargo, tenga en cuenta que el personal del consulado no redacta los textos de los documentos; solo certifican los documentos preparados en un formato especial.',
  ],
  consentText3: [
    'Мы поможем вам составить требуемый документ, проконсультируем по записи на приём в консульство или запишем вас сами, и разместим готовый проект документа в нужном формате на консульском портале.',
    'We will assist you in drafting the required document, provide consultation on scheduling an appointment at the consulate, or schedule an appointment for you. Additionally, we will upload the prepared document draft in the required format to the consulate portal.',
    '我们将帮助您起草所需的文件，提供关于预约领事馆的咨询，或为您预约。此外，我们会将准备好的文件草案以所需格式上传到领事馆门户网站。',
    'Le ayudaremos a redactar el documento requerido, proporcionaremos asesoramiento sobre cómo programar una cita en el consulado o programaremos una cita para usted. Además, subiremos el borrador del documento preparado en el formato requerido al portal consular.',
  ],

  consent: ['Согласия', 'Consents', '同意', 'Consentimientos'],

  typesOfConsents: [
    {
      section: ['Согласия', 'Consent', '同意', 'Consentimiento'],
      types: [
        {
          id: 0,
          service: serviceTypes[0][1],
          additionalServiceType: consentTypes[0][1],
          text: [
            'На выезд ребёнка за пределы РФ',
            'Consent for the child to travel outside the Russian Federation.',
            '让孩子出境',
            'Consentimiento para que el niño viaje fuera de la Federación Rusa.',
          ],
          icon: <Baby size={24} weight='light' />,
        },
        {
          id: 1,
          service: serviceTypes[0][1],
          text: [
            'От супруга на сделку с недвижимостью',
            'Consent from a spouse for a real estate transaction.',
            '配偶在房地产交易中的同意',
            'Consentimiento de un cónyuge para una transacción de bienes raíces.',
          ],
          icon: <House size={24} weight='light' />,
        },
        {
          id: 2,
          service: serviceTypes[0][1],
          text: [
            'От супруга на сделку с долей в ООО',
            'Consent from a spouse for a transaction involving a share in an LLC.',
            '配偶在有限责任公司股份交易中的同意',
            'Consentimiento de un cónyuge para una transacción que involucre una participación en una LLC.',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
      ],
    },
    {
      section: ['Заявления', 'Statements', '声明', 'Declaraciones'],
      types: [
        {
          id: 3,
          service: serviceTypes[1][1],
          additionalServiceType: applicationTypes[0][1],
          text: [
            'На вступление / отказ от наследства',
            'For inheritance acceptance / refusal',
            '关于继承接受/拒绝',
            'Para aceptación/renuncia de herencia',
          ],
          icon: <FileText size={24} weight='light' />,
        },
        {
          id: 4,
          service: serviceTypes[1][1],
          additionalServiceType: applicationTypes[2][1],
          text: [
            'На снятие с регистрационного учёта',
            'For deregistration',
            '注销',
            'Para la cancelación del registro',
          ],
          icon: <Stamp size={24} weight='light' />,
        },
        {
          id: 5,
          service: serviceTypes[1][1],
          additionalServiceType: applicationTypes[3][1],
          text: [
            'На выплату пенсии',
            'For pension payment',
            '养老金领取',
            'Para el pago de pensiones',
          ],
          icon: <Coins size={24} weight='light' />,
        },
        {
          id: 6,
          service: serviceTypes[1][1],
          additionalServiceType: applicationTypes[4][1],
          text: ['Завещание', 'Will', '遗嘱', 'Testamento'],
          icon: <FileText size={24} weight='light' />,
        },
      ],
    },
    {
      section: [
        'Договора, соглашения',
        'Contracts, agreements',
        '合同，协议',
        'Contratos, acuerdos',
      ],
      types: [
        {
          id: 7,
          service: serviceTypes[2][1],
          additionalServiceType: agreementTypes[0][1],
          text: [
            'Брачный договор',
            'Marriage contract',
            '婚姻合同',
            'Contrato matrimonial',
          ],
          icon: <img src={marriageRingsImage} alt='logo' width={'24px'} />,
        },
        {
          id: 8,
          service: serviceTypes[2][1],
          additionalServiceType: agreementTypes[1][1],
          text: [
            'Соглашение о разделе имущества',
            'Property division agreement',
            '财产分割协议',
            'Acuerdo de división de bienes',
          ],
          icon: <img src={marriageRingsImage} alt='logo' width={'24px'} />,
        },
        {
          id: 9,
          service: serviceTypes[2][1],
          additionalServiceType: agreementTypes[2][1],
          text: [
            'Соглашение об алиментах',
            'Agreement on alimony',
            '抚养费协议',
            'Acuerdo sobre pensión alimenticia',
          ],
          icon: <Baby size={24} weight='light' />,
        },
        {
          id: 10,
          service: serviceTypes[2][1],
          additionalServiceType: agreementTypes[3][1],
          text: [
            'Договор поручительства',
            'Surety agreement',
            '担保协议',
            'Acuerdo de garantía',
          ],
          icon: <Coins size={24} weight='light' />,
        },
        {
          id: 11,
          service: serviceTypes[2][1],
          additionalServiceType: agreementTypes[4][1],
          text: [
            'Договор уступки права требования',
            'Assignment of claim agreement',
            '债权转让协议',
            'Acuerdo de cesión de derechos',
          ],
          icon: <Coins size={24} weight='light' />,
        },
      ],
    },
  ],

  registrationProcess: [
    'Процесс оформления',
    'Process of registration',
    '注册流程',
    'Proceso de registro',
  ],
  interactuation: [
    [
      {
        type: 'title',
        text: [
          'Согласие на выезд ребёнка за пределы РФ',
          'Permission for the child to travel outside the Russian Federation.',
          '孩子出境许可',
          'Permiso para que el niño viaje fuera de la Federación Rusa.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, требуемый при подаче документов на визу другого государства и далее для пересечения ребёнком границы РФ.',
          'Document required for the issuance of documents to another country and for the purpose of avoiding the child’s borders.',
          '申请其他国家签证时需要的文件，以及孩子越境的目的。',
          'Documento requerido para la emisión de documentos a otro país y para evitar las fronteras del niño.',
        ],
      },
      {
        type: 'list',
        title: [
          'Бывает без сопровождения и с сопровождением:',
          'It can happen without an accompanying and with the accompanying',
          '可以没有陪伴，也可以有陪伴：',
          'Puede suceder sin acompañamiento y con acompañamiento:',
        ],
        items: [
          [
            'дети до 2 лет: только с сопровождением',
            'children 2 years old: only with an accompanying',
            '2岁以下的儿童：只能有陪伴',
            'niños menores de 2 años: solo con acompañante',
          ],
          [
            'дети от 2 до 12 лет: либо в сопровождении лица старше 18 лет, либо под наблюдением перевозчика (без сопровождения)',
            'children from 2 to 12 years old: either with a witness older than 18 years or with the watch of the transporter (without an accompanying)',
            '2到12岁儿童：可以有超过18岁的人陪伴，也可以由运输公司监督（没有陪伴）',
            'niños de 2 a 12 años: o bien con un testigo mayor de 18 años o bajo la supervisión del transportista (sin acompañante)',
          ],
          [
            'дети старше 12 лет: возможно без сопровождения',
            'children older than 12 years: it is possible without an accompanying',
            '12岁以上的儿童：可以没有陪伴',
            'niños mayores de 12 años: es posible sin acompañamiento',
          ],
        ],
      },
      {
        type: 'list',
        title: [
          'Требуемые документы:',
          'Required documents:',
          '所需文件：',
          'Documentos requeridos:',
        ],
        items: [
          [
            'внутренний паспорт РФ и/или загранпаспорт РФ родителя (или законного представителя)',
            'internal russian passport and/or foreign passport of the parent (or the lawful representative)',
            '俄罗斯内部护照和/或父母（或法定代表人）的外国护照',
            'pasaporte interno ruso y/o pasaporte extranjero del padre (o representante legal)',
          ],
          [
            'свидетельство о рождении ребёнка (если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык)',
            'witness of the birth of the child (if the document of the foreign origin, it must be with an apostillum and notarized transfer to Russian language)',
            '孩子的出生证明（如果是外国文件，则必须有公证和翻译成俄语的公证）',
            'certificado de nacimiento del niño (si el documento es de origen extranjero, debe estar con apostilla y traducción notariada al ruso)',
          ],
          [
            'загранпаспорт РФ ребёнка',
            'foreign passport of the child',
            '孩子的外国护照',
            'pasaporte extranjero del niño',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of the residence in Russia (if there is no proclamation in Russia)',
            '在俄罗斯的地址（如果在俄罗斯没有注册）',
            'dirección de residencia en Rusia (si no hay proclamación en Rusia)',
          ],
          [
            'данные загранпаспорта лица, в сопровождении которого выезжает ребёнок (если согласие оформляется с сопровождением)',
            'data of the foreign passport of the person accompanying which the child (if the consent is issued with accompanying)',
            '陪伴孩子的人的外国护照信息（如果同意书是与陪伴一起发出的）',
            'datos del pasaporte extranjero de la persona que acompaña al niño (si el consentimiento se emite con acompañamiento)',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Присутствие ребёнка при оформлении согласия не требуется.',
          'Absence of the child during the issuance of a consent is not required.',
          '孩子在发放同意书时不需要在场。',
          'La ausencia del niño durante la emisión del consentimiento no es requerida.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'При выезде ребёнка за пределы РФ с одним из родителей, согласие второго не требуется. Однако для оформления визы или ВНЖ страна въезда может потребовать предоставление согласий от обоих родителей.',
          'When leaving a child outside of Russia, the consent is not required. However, for the issuance of a visa or VAT, the consent may be required from both parents.',
          '当孩子在父母之一的陪同下离开俄罗斯时，不需要另一方的同意。然而，在签发签证或居留许可证时，入境国可能会要求提供双方的同意书。',
          'Al salir un niño de Rusia con uno de los padres, no se requiere el consentimiento del otro. Sin embargo, para la emisión de una visa o permiso de residencia, el país de entrada puede exigir el consentimiento de ambos padres.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Согласие супруга на покупку / продажу / дарение / залог недвижимости',
          'Consent of the spouse for the purchase / sale / donation / pledge of real estate',
          '配偶同意购买/出售/赠与/抵押房地产',
          'Consentimiento del cónyuge para la compra/venta/donación/embargo de bienes raíces',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, который оформляют, когда собственность является для супругов совместной.',
          'Document that is drawn up when the property is jointly owned by the spouses.',
          '当财产是夫妻共同所有时制定的文件。',
          'Documento que se elabora cuando la propiedad es de propiedad conjunta de los cónyuges.',
        ],
      },
      {
        type: 'list',
        title: [
          'Так бывает в двух случаях, если:',
          'This happens in two cases if:',
          '这种情况在两种情况下发生：',
          'Esto ocurre en dos casos si:',
        ],
        items: [
          [
            'недвижимость купили в браке',
            'the property was bought in marriage',
            '房地产在婚姻中购买',
            'la propiedad se compró en matrimonio',
          ],
          [
            'был произведён капитальный ремонт на общие деньги',
            'major repairs were carried out with joint money',
            '用共同资金进行了重大维修',
            'se realizaron reparaciones importantes con dinero conjunto',
          ],
        ],
      },
      {
        type: 'list',
        title: [
          'Согласие не требуется, если недвижимость:',
          'Consent is not required if the property:',
          '如果财产：不需要同意',
          'No se requiere consentimiento si la propiedad:',
        ],
        items: [
          [
            'куплена до вступления в брак',
            'was purchased before marriage',
            '在婚姻之前购买',
            'fue comprada antes del matrimonio',
          ],
          [
            'подарена или досталась в наследство одному из супругов',
            'was donated or inherited by one of the spouses',
            '由一方配偶赠与或继承',
            'fue donada o heredada por uno de los cónyuges',
          ],
          [
            'приватизирована в браке одним из супругов, при наличии отказа от участия в приватизации другого супруга',
            'was privatized in marriage by one of the spouses, with the refusal of the other spouse to participate in the privatization',
            '在婚姻中由一方配偶私有化，另一方放弃参与私有化',
            'fue privatizada en matrimonio por uno de los cónyuges, con la renuncia del otro cónyuge a participar en la privatización',
          ],
          [
            'принадлежит только одному супругу на основании брачного договора или договору о разделе имущества',
            'belongs to only one spouse on the basis of a marriage contract or property division agreement',
            '根据婚姻合同或财产分割协议，仅归一方配偶所有',
            'pertenece solo a un cónyuge sobre la base de un contrato matrimonial o un acuerdo de división de propiedad',
          ],
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'For registration, the following are required:',
          '注册需要：',
          'Para el registro, se requiere lo siguiente:',
        ],
        items: [
          [
            'внутренние и/или заграничные паспорта РФ супругов (приоритет отдаётся внутреннему паспорту)',
            'internal and/or foreign passports of the spouses (priority is given to the internal passport)',
            '夫妻的内外护照（优先考虑内护照）',
            'pasaportes internos y/o extranjeros de los cónyuges (se da prioridad al pasaporte interno)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
            '在俄罗斯没有注册的情况下，在俄文中的海外居住地址',
            'dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
          ],
          [
            'свидетельство о браке или его расторжении (если уже в разводе)',
            'marriage certificate or its dissolution (if already divorced)',
            '结婚证或离婚证（如果已经离婚）',
            'certificado de matrimonio o su disolución (si ya está divorciado)',
          ],
          [
            'документы, подтверждающие право собственности',
            'documents confirming ownership',
            '确认所有权的文件',
            'documentos que confirmen la propiedad',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Согласие супруга на покупку / продажу / дарение / залог / изменение доли в ООО',
          'Consent of the spouse for the purchase / sale / donation / pledge / change of share in LLC',
          '配偶同意购买/出售/赠与/抵押/变更在有限责任公司中的份额',
          'Consentimiento del cónyuge para la compra/venta/donación/embargo/cambio de participación en la LLC',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, предоставляемый во всех случаях, когда доля в ООО приобретается в браке на общие деньги.',
          'Document provided in all cases when a share in an LLC is acquired in marriage with joint funds.',
          '当有限责任公司的股份在婚姻中用共同资金获得时提供的文件。',
          'Documento proporcionado en todos los casos en que una participación en una LLC se adquiere en matrimonio con fondos conjuntos.',
        ],
      },
      {
        type: 'list',
        title: [
          'Согласие не требуется, если доля:',
          'Consent is not required if the share:',
          '如果股份：不需要同意',
          'No se requiere consentimiento si la participación:',
        ],
        items: [
          [
            'куплена до вступления в брак',
            'was purchased before marriage',
            '在婚姻之前购买',
            'fue comprada antes del matrimonio',
          ],
          [
            'подарена или досталась в наследство одному из супругов',
            'was donated or inherited by one of the spouses',
            '由一方配偶赠与或继承',
            'fue donada o heredada por uno de los cónyuges',
          ],
          [
            'принадлежит только одному супругу на основании брачного договора или договору о разделе имущества',
            'belongs to only one spouse on the basis of a marriage contract or property division agreement',
            '根据婚姻合同或财产分割协议，仅归一方配偶所有',
            'pertenece solo a un cónyuge sobre la base de un contrato matrimonial o un acuerdo de división de propiedad',
          ],
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'For registration, the following are required:',
          '注册需要：',
          'Para el registro, se requiere lo siguiente:',
        ],
        items: [
          [
            'внутренние и/или заграничные паспорта РФ супругов (приоритет отдаётся внутреннему паспорту)',
            'internal and/or foreign passports of the spouses (priority is given to the internal passport)',
            '夫妻的内外护照（优先考虑内护照）',
            'pasaportes internos y/o extranjeros de los cónyuges (se da prioridad al pasaporte interno)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
            '在俄罗斯没有注册的情况下，在俄文中的海外居住地址',
            'dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
          ],
          [
            'свидетельство о браке или его расторжении (если уже в разводе)',
            'marriage certificate or its dissolution (if already divorced)',
            '结婚证或离婚证（如果已经离婚）',
            'certificado de matrimonio o su disolución (si ya está divorciado)',
          ],
          [
            'наименование, адрес, ОГРН и ИНН компании, информация о размере доли',
            'name, address, OGRN and INN of the company, information about the size of the share',
            '公司的名称、地址、OGRN和INN，股份信息',
            'nombre, dirección, OGRN e INN de la empresa, información sobre el tamaño de la participación',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление на вступление / отказ от наследства',
          'Application for acceptance / renunciation of inheritance',
          '接受/放弃遗产的申请',
          'Solicitud de aceptación / renuncia de herencia',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Чтобы принять наследство в России необходимо подготовить заявление на вступление в наследство и предъявить его нотариусу, который ведёт дело (часто оформляется в паре с доверенностью на ведение наследственного дела на человека, находящегося в России).',
          'To accept an inheritance in Russia, it is necessary to prepare an application for inheritance and present it to a notary who handles the case (often issued in conjunction with a Power of Attorney for handling the inheritance case to a person located in Russia).',
          '在俄罗斯接受遗产需要准备一份遗产接受申请并向处理该案件的公证人提交（通常与授权委托书一起办理，以便在俄罗斯的某人处理遗产事宜）。',
          'Para aceptar una herencia en Rusia, es necesario preparar una solicitud de herencia y presentarla a un notario que maneje el caso (a menudo se emite junto con un poder notarial para manejar el caso de herencia a una persona ubicada en Rusia).',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Заявление на вступление или отказ от наследства подаются в течение 6 месяцев со смерти наследодателя.',
          "The application for acceptance or renunciation of inheritance is submitted within 6 months from the date of the testator's death.",
          '接受或放弃遗产的申请须在遗嘱人去世后6个月内提交。',
          'La solicitud de aceptación o renuncia de herencia se presenta dentro de los 6 meses posteriores a la fecha de fallecimiento del testador.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Отказ бывает адресным (в пользу другого наследника) или неадресный.',
          'Renunciation can be addressed (in favor of another heir) or non-addressed.',
          '放弃可以是定向的（有利于其他继承人）或不定向的。',
          'La renuncia puede ser dirigida (a favor de otro heredero) o no dirigida.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'For registration, the following are required:',
          '注册需要：',
          'Para el registro, se requiere lo siguiente:',
        ],
        items: [
          [
            'внутренний паспорт РФ и/или загранпаспорт РФ наследника (приоритет у внутреннего паспорта)',
            'internal Russian passport and/or foreign passport of the heir (priority given to the internal passport)',
            '俄罗斯遗产继承人的内外护照（优先考虑内护照）',
            'pasaporte interno ruso y/o pasaporte extranjero del heredero (se da prioridad al pasaporte interno)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
            '在俄罗斯没有注册的情况下，在俄文中的海外居住地址',
            'dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
          ],
          [
            'свидетельство о смерти или данные о наследодателе: ФИО умершего, дата смерти, адрес регистрации места жительства на момент смерти',
            'death certificate or information about the testator: full name of the deceased, date of death, address of residence registration at the time of death',
            '死亡证明或遗嘱人的信息：死者的全名、死亡日期、去世时的居住地址',
            'certificado de defunción o información sobre el testador: nombre completo del fallecido, fecha de fallecimiento, dirección de registro de residencia en el momento del fallecimiento',
          ],
        ],
      },
      {
        type: 'link',
        text: [
          'Смотреть также доверенность на вступление в наследство и ведение наследственного дела',
          'Also see the Power of Attorney for inheritance and handling the inheritance case',
          '另请参阅遗产继承和处理遗产案件的授权委托书',
          'También vea el poder notarial para la herencia y el manejo del caso de herencia',
        ],
        to: '/poa?section=poa&service=For+handling+inheritance+matters&id=6',
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление на снятие с регистрационного учёта',
          'Application for deregistration',
          '注销登记申请',
          'Solicitud de baja de registro',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Чтобы выписаться с места прописки в России через консульство, обычно оформляют два документа: заявление на снятие с регистрационного учёта и доверенность на представительство в Госорганах по данному вопросу на человека в России, который сможет обратиться в МВД от вашего имени.',
          'To deregister from your place of residence in Russia through the consulate, two documents are usually issued: an application for deregistration and a Power of Attorney for representation in state authorities on this issue to a person in Russia who will be able to contact the Ministry of Internal Affairs on your behalf.',
          '要通过领事馆注销在俄罗斯的注册地址，通常需要办理两份文件：注销登记申请和对在此问题上代表国家机关的俄罗斯境内人士的授权委托书，该人将能够代表您与内务部联系。',
          'Para darse de baja de su lugar de residencia en Rusia a través del consulado, generalmente se emiten dos documentos: una solicitud de baja y un poder notarial para la representación en las autoridades estatales sobre este asunto a una persona en Rusia que podrá contactar al Ministerio de Asuntos Internos en su nombre.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'For registration, the following are required:',
          '注册需要：',
          'Para el registro, se requiere lo siguiente:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority given to the internal passport)',
            '俄罗斯联邦的内外护照（优先考虑内护照）',
            'pasaporte interno y/o pasaporte extranjero de la Federación Rusa (se da prioridad al pasaporte interno)',
          ],
        ],
      },
      {
        type: 'link',
        text: [
          'Смотреть доверенность на представительство в госорганах',
          'See the Power of Attorney for representation in state authorities',
          '请查看在国家机关中的代表权授权委托书',
          'Vea el poder notarial para la representación en las autoridades estatales',
        ],
        to: '/poa?section=poa&service=For+representation+in+government+agencies&id=12',
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление на оформление пенсии или восстановление её выплаты',
          'Application for pension registration or restoration of its payment',
          '申请养老金注册或恢复支付',
          'Solicitud de registro de pensiones o restauración de su pago',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Если до переезда вы не получали пенсию, для её оформления вам потребуется заявление о назначении пенсии.',
          'If you did not receive a pension before moving, you will need an application for pension assignment.',
          '如果您在搬迁之前未收到养老金，则需要一份养老金申请。',
          'Si no recibió una pensión antes de mudarse, necesitará una solicitud de asignación de pensión.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Если вы ранее уже получали пенсию, но выплаты прекратились после переезда за границу, для их восстановления потребуется предоставить заявление о выезде за пределы РФ.',
          'If you previously received a pension, but payments stopped after moving abroad, to restore them, you need to provide an application for departure from the Russian Federation.',
          '如果您之前已经领取了养老金，但在搬到国外后停止了支付，则需要提供一份出境申请以恢复支付。',
          'Si previamente recibió una pensión, pero los pagos se detuvieron después de mudarse al extranjero, para restaurarlos, necesita presentar una solicitud de salida de la Federación Rusa.',
        ],
      },
      {
        type: 'list',
        title: [
          'Для подготовки заявления потребуются:',
          'To prepare the application, the following are required:',
          '准备申请需要：',
          'Para preparar la solicitud, se requiere lo siguiente:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority given to the internal passport)',
            '俄罗斯联邦的内外护照（优先考虑内护照）',
            'pasaporte interno y/o pasaporte extranjero de la Federación Rusa (se da prioridad al pasaporte interno)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
            '在俄罗斯没有注册的情况下，在俄文中的海外居住地址',
            'dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
          ],
          ['СНИЛС', 'SNILS', 'СНИЛС', 'SNILS'],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Завещание', 'Will', '遗嘱', 'Testamento'],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, в котором человек распоряжается своим имуществом на случай смерти: как именно и между какими людьми нужно разделить его собственность, долги и права.',
          'A document in which a person disposes of their property in the event of death: how and between which people to divide their property, debts, and rights.',
          '一份文件，其中一个人处理其财产以备死亡：如何以及在何人之间划分其财产、债务和权利。',
          'Un documento en el que una persona dispone de sus bienes en caso de fallecimiento: cómo y entre qué personas dividir sus bienes, deudas y derechos.',
        ],
      },
      {
        type: 'list',
        title: [
          'Используют, например, в случаях, если требуется:',
          'Used, for example, in cases where it is necessary:',
          '例如，在需要的情况下使用：',
          'Se utiliza, por ejemplo, en casos donde es necesario:',
        ],
        items: [
          [
            'завещать часть имущества человеку, не входящему в круг прямых наследников',
            'to bequeath part of the property to a person who is not in the circle of direct heirs',
            '将部分财产遗赠给不在直系继承人范围内的人',
            'legar parte de la propiedad a una persona que no está en el círculo de herederos directos',
          ],
          [
            'отказать в наследстве или изменить его долю одному из наследников',
            'to deny inheritance or change the share of one of the heirs',
            '拒绝继承或改变某个继承人的份额',
            'negar la herencia o cambiar la parte de uno de los herederos',
          ],
          [
            'наложить какое-то дополнительное условие на получение наследства',
            'to impose some additional condition on the receipt of the inheritance',
            '对继承的获得施加某些附加条件',
            'imponer alguna condición adicional para la recepción de la herencia',
          ],
        ],
      },
      {
        type: 'list',
        title: [
          'Для оформления потребуются:',
          'For registration, the following are required:',
          '注册需要：',
          'Para el registro, se requiere lo siguiente:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority given to the internal passport)',
            '俄罗斯联邦的内外护照（优先考虑内护照）',
            'pasaporte interno y/o pasaporte extranjero de la Federación Rusa (se da prioridad al pasaporte interno)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
            '在俄罗斯没有注册的情况下，在俄文中的海外居住地址',
            'dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Брачный договор',
          'Marriage contract',
          '婚姻合同',
          'Contrato matrimonial',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, который определяет финансовые и имущественные отношения супругов во время брака и в случае развода.',
          'A document that defines the financial and property relations of spouses during the marriage and in the event of a divorce.',
          '一份文件，定义了配偶在婚姻期间及离婚时的财务和财产关系。',
          'Un documento que define las relaciones financieras y de propiedad de los cónyuges durante el matrimonio y en caso de divorcio.',
        ],
      },
      {
        type: 'list',
        title: [
          'Что не включает:',
          'What it does not include:',
          '不包括：',
          'Lo que no incluye:',
        ],
        items: [
          [
            'вопросы выплаты алиментов',
            'issues of alimony payments',
            '赡养费支付问题',
            'cuestiones de pago de pensiones alimenticias',
          ],
          [
            'порядок и способ воспитания детей',
            'the order and method of raising children',
            '孩子抚养的方式和方法',
            'el orden y el método de criar a los hijos',
          ],
          [
            'не может выполнять функции завещания',
            'cannot perform the functions of a will',
            '不能执行遗嘱的功能',
            'no puede cumplir las funciones de un testamento',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Брачный договор можно заключить как до брака, так и во время брака, вплоть до момента его окончания.',
          'A marriage contract can be concluded both before and during the marriage, until its termination.',
          '婚姻合同可以在婚前和婚中签订，直到其终止为止。',
          'Se puede celebrar un contrato matrimonial tanto antes como durante el matrimonio, hasta su terminación.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Для оформления необходимо личное присутствие обоих сторон.',
          'Both parties must be present in person to complete the registration.',
          '双方必须亲自到场才能完成登记。',
          'Ambas partes deben estar presentes en persona para completar el registro.',
        ],
      },
      {
        type: 'list',
        title: [
          'Требуемые документы:',
          'Required documents:',
          '所需文件：',
          'Documentos requeridos:',
        ],
        items: [
          [
            'внутренние и/или заграничные паспорта РФ обоих сторон (приоритет отдаётся внутреннему паспорту)',
            'internal and/or foreign passports of both parties (priority given to the internal passport)',
            '双方的内外护照（优先考虑内护照）',
            'pasaportes internos y/o extranjeros de ambas partes (se da prioridad al pasaporte interno)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
            '在俄罗斯没有注册的情况下，在俄文中的海外居住地址',
            'dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
          ],
          [
            'свидетельство о браке (если уже состоят в нем)',
            'marriage certificate (if already married)',
            '结婚证（如果已结婚）',
            'certificado de matrimonio (si ya están casados)',
          ],
          [
            'документы, подтверждающие право собственности на недвижимое или движимое имущество',
            'documents confirming ownership of real or movable property',
            '证明对不动产或动产所有权的文件',
            'documentos que confirman la propiedad de bienes inmuebles o muebles',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Соглашение о разделе имущества',
          'Property Division Agreement',
          '财产分割协议',
          'Acuerdo de división de bienes',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, по которому супруги в добровольном порядке делят между собой совместно нажитую собственность.',
          'A document by which spouses voluntarily divide jointly acquired property.',
          '一份文件，夫妻双方自愿分配共同拥有的财产。',
          'Un documento mediante el cual los cónyuges dividen voluntariamente la propiedad adquirida en conjunto.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Заключается во время брака, при его расторжении или после развода.',
          'Concluded during the marriage, upon its dissolution, or after a divorce.',
          '在婚姻期间、婚姻解除时或离婚后签订。',
          'Se concluye durante el matrimonio, al disolverse este o después de un divorcio.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Для оформления необходимо личное присутствие обоих сторон.',
          'Both parties must be present in person to complete the registration.',
          '双方必须亲自到场才能完成登记。',
          'Ambas partes deben estar presentes en persona para completar el registro.',
        ],
      },
      {
        type: 'list',
        title: [
          'Требуемые документы:',
          'Required documents:',
          '所需文件：',
          'Documentos requeridos:',
        ],
        items: [
          [
            'внутренние и/или заграничные паспорта РФ обоих сторон (приоритет отдаётся внутреннему паспорту)',
            'internal and/or foreign passports of both parties (priority given to the internal passport)',
            '双方的内外护照（优先考虑内护照）',
            'pasaportes internos y/o extranjeros de ambas partes (se da prioridad al pasaporte interno)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
            '在俄罗斯没有注册的情况下，在俄文中的海外居住地址',
            'dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
          ],
          [
            'свидетельство о браке или его расторжении',
            'marriage certificate or its dissolution',
            '结婚证或其解除证明',
            'certificado de matrimonio o su disolución',
          ],
          [
            'документы, подтверждающие право собственности на недвижимое или движимое имущество',
            'documents confirming ownership of real or movable property',
            '证明对不动产或动产所有权的文件',
            'documentos que confirman la propiedad de bienes inmuebles o muebles',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Соглашение об алиментах',
          'Alimony Agreement',
          '抚养费协议',
          'Acuerdo de pensiones alimenticias',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Обоюдная договоренность родителей по вопросам выплаты алиментов на содержание несовершеннолетних детей.',
          'Mutual agreement between parents on alimony payments for the support of minor children.',
          '父母之间就抚养未成年子女的抚养费支付达成的共同协议。',
          'Acuerdo mutuo entre los padres sobre los pagos de pensiones alimenticias para el sustento de los hijos menores.',
        ],
      },
      {
        type: 'list',
        title: [
          'Что включает:',
          'What it includes:',
          '包括：',
          'Lo que incluye:',
        ],
        items: [
          [
            'размер выплат (не может быть меньше положенного по закону)',
            'amount of payments (cannot be less than what is required by law)',
            '支付金额（不得低于法律规定的最低限额）',
            'monto de los pagos (no puede ser menor al requerido por la ley)',
          ],
          ['периодичность', 'frequency', '频率', 'frecuencia'],
          [
            'способ: доля от дохода, фиксированная сумма или имущество',
            'method: share of income, fixed amount, or property',
            '方式：收入的一部分、固定金额或财产',
            'método: porcentaje de ingresos, cantidad fija o bienes',
          ],
          [
            'форма получения: наличными или банковским переводом',
            'form of receipt: in cash or by bank transfer',
            '接收形式：现金或银行转账',
            'forma de recepción: en efectivo o por transferencia bancaria',
          ],
        ],
      },
      {
        type: 'list',
        title: [
          'Требуемые документы:',
          'Required documents:',
          '所需文件：',
          'Documentos requeridos:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ родителей (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the parents (priority given to the internal passport)',
            '父母的内外护照（优先考虑内护照）',
            'pasaporte interno y/o pasaporte extranjero de los padres (se da prioridad al pasaporte interno)',
          ],
          [
            'свидетельство о рождении ребенка (если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык)',
            'birth certificate of the child (if the document is of foreign origin, it must have an apostille and a notarized translation into Russian)',
            '孩子的出生证明（如果是外国文件，必须附有公证和翻译成俄文的公证文件）',
            'certificado de nacimiento del niño (si el documento es de origen extranjero, debe tener un apostille y una traducción notariada al ruso)',
          ],
          [
            'загранпаспорт РФ ребенка',
            'foreign passport of the child',
            '孩子的俄罗斯护照',
            'pasaporte extranjero del niño',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
            '在俄罗斯没有注册的情况下，在俄文中的海外居住地址',
            'dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
          ],
          [
            'свидетельство о браке или его расторжении',
            'marriage certificate or its dissolution',
            '结婚证或其解除证明',
            'certificado de matrimonio o su disolución',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Договор поручительства',
          'Surety Agreement',
          '担保协议',
          'Contrato de fianza',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Поручительство — это готовность исполнить кредитные обязательства заёмщика. Иными словами, если заёмщик не выплатит долг, поручитель обязан вернуть его кредитору вместе с просрочками.',
          "Suretyship is the willingness to fulfill the borrower's loan obligations. In other words, if the borrower does not repay the debt, the guarantor is obliged to return it to the creditor along with any overdue payments.",
          '担保是指愿意履行借款人的贷款义务。换句话说，如果借款人未能偿还债务，担保人有义务将其连同任何逾期付款一起返还给债权人。',
          'La fianza es la disposición a cumplir con las obligaciones crediticias del prestatario. En otras palabras, si el prestatario no paga la deuda, el fiador está obligado a devolverla al acreedor junto con cualquier pago atrasado.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Договор может быть заключён в обеспечение как денежных, так и неденежных обязательств, а также в обеспечение обязательства, которое возникнет в будущем.',
          'The agreement can be concluded to secure both monetary and non-monetary obligations, as well as to secure an obligation that will arise in the future.',
          '该协议可用于确保金钱和非金钱义务，以及确保将来可能产生的义务。',
          'El acuerdo puede concluirse para garantizar tanto obligaciones monetarias como no monetarias, así como para garantizar una obligación que surgirá en el futuro.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Поручителем может быть как физическое, так и юридическое лицо или ИП, однако финансовые организации охотнее дают кредиты, если поручителем выступает именно физическое лицо.',
          'The guarantor can be an individual, a legal entity, or a sole proprietor, but financial institutions are more willing to lend if the guarantor is an individual.',
          '担保人可以是个人、法人或个体工商户，但金融机构更愿意贷款给个人担保人。',
          'El fiador puede ser una persona física, una entidad jurídica o un propietario único, pero las instituciones financieras están más dispuestas a prestar si el fiador es una persona física.',
        ],
      },
      {
        type: 'list',
        title: [
          'Требуемые документы:',
          'Required documents:',
          '所需文件：',
          'Documentos requeridos:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ всех сторон (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of all parties (priority given to the internal passport)',
            '所有方的内外护照（优先考虑内护照）',
            'pasaporte interno y/o pasaporte extranjero de todas las partes (se da prioridad al pasaporte interno)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
            '在俄罗斯没有注册的情况下，在俄文中的海外居住地址',
            'dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
          ],
          [
            'документы, подтверждающие финансовое состояние и трудовую занятость заёмщика и поручителя',
            'documents confirming the financial status and employment of the borrower and the guarantor',
            '证明借款人和担保人的财务状况和就业的文件',
            'documentos que confirman el estado financiero y el empleo del prestatario y el fiador',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Договор уступки права требования (цессии)',
          'Assignment Agreement',
          '债权转让协议',
          'Contrato de cesión de derechos',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Если человеку (или организации) кто-то должен деньги или другие вещи (например, недвижимость), он может передать другому лицу право требовать долг.',
          'If someone (or an organization) is owed money or other things (such as real estate), they can transfer the right to claim the debt to another person.',
          '如果某人（或组织）欠他钱或其他东西（例如房地产），他们可以将索取债务的权利转让给另一个人。',
          'Si a alguien (o a una organización) le deben dinero u otras cosas (como bienes raíces), puede transferir el derecho a reclamar la deuda a otra persona.',
        ],
      },
      {
        type: 'list',
        title: [
          'Какие документы потребуются:',
          'What documents are required:',
          '需要哪些文件：',
          'Qué documentos se requieren:',
        ],
        items: [
          [
            'документы, подтверждающие права, которые уступают по договору',
            'documents confirming the rights being assigned under the contract',
            '确认根据合同转让的权利的文件',
            'documentos que confirman los derechos que se ceden según el contrato',
          ],
        ],
      },
      {
        type: 'list',
        title: [
          'Для физических лиц:',
          'For individuals:',
          '对于个人：',
          'Para individuos:',
        ],
        items: [
          [
            'внутренний паспорт и/или загранпаспорт РФ (приоритет у внутреннего паспорта)',
            'internal passport and/or foreign passport of the Russian Federation (priority given to the internal passport)',
            '俄罗斯联邦的内外护照（优先考虑内护照）',
            'pasaporte interno y/o pasaporte extranjero de la Federación Rusa (se da prioridad al pasaporte interno)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
            '在俄罗斯没有注册的情况下，在俄文中的海外居住地址',
            'dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
          ],
          [
            'Согласие супруга (если состоит в браке)',
            "spouse's consent (if married)",
            '配偶同意书（如果已婚）',
            'consentimiento del cónyuge (si está casado)',
          ],
        ],
      },
      {
        type: 'list',
        title: [
          'Для юридических лиц:',
          'For legal entities:',
          '对于法人：',
          'Para entidades legales:',
        ],
        items: [
          [
            'Устав Организации и его изменения, если имели место быть',
            "organization's charter and its amendments, if applicable",
            '组织章程及其变更（如适用）',
            'estatutos de la organización y sus enmiendas, si corresponde',
          ],
          [
            'Выписка из ЕГРЮЛ',
            'extract from the Unified State Register of Legal Entities (USRLE)',
            '统一国家法人登记册的摘录',
            'extracto del Registro Estatal Unificado de Entidades Legales (USRLE)',
          ],
          [
            'Свидетельство ОГРН, ИНН, КПП',
            'OGRN, INN, KPP certificates',
            'OGRN、INN、KPP证书',
            'certificados OGRN, INN, KPP',
          ],
          [
            'Решение и приказ о назначении и приказ о назначении директора',
            'decision and order on the appointment of the director',
            '关于任命董事的决议和命令',
            'decisión y orden sobre el nombramiento del director',
          ],
          [
            'Паспортные данные директора',
            'passport details of the director',
            '董事护照信息',
            'datos del pasaporte del director',
          ],
        ],
      },
    ],
  ],
  orderDescription: [
    [
      {
        headerText: 'Оформление заявки',
        cardText:
          'Опишите, что должно содержаться в документе. Вы можете направить нам требуемые документы как одновременно с заявкой, так и напрямую юристу после консультации с ним.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Подготовка текста документа',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали, подготовит проект документа и разместит его на портале КД МИД России. Вы получите номер размещения, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Получение готового документа',
        cardText:
          'В день записи приходите в консульство с паспортом и номером размещения для заверения подготовленного документа',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submit an Application',
        cardText:
          'Describe what should be included in the document. You can send us the required documents either simultaneously with the application or directly to the lawyer after consulting with them.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparation of Document Text',
        cardText:
          'Our lawyer will contact you, discuss the details, prepare the document draft, and upload it to the portal of the Consular Department of the Ministry of Foreign Affairs of Russia. You will receive a placement number, which you will need to take with you to the consulate. The service will be completed within 2 business days from the date of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Appointment Scheduling at the Consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance (according to the selected tariff)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Receipt of the Ready Document',
        cardText:
          'On the day of your appointment, come to the consulate with your passport and placement number to have the prepared document certified.',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: '提交申请',
        cardText:
          '描述文件中应包含的内容。您可以在申请时同时发送所需文件，也可以在咨询律师后直接发送给律师。',
        cardImage: checkBoxImage,
      },
      {
        headerText: '准备文件文本',
        cardText:
          '我们的律师将与您联系，讨论细节，准备文件草案，并将其上传到俄罗斯外交部领事处的门户网站。您将收到一个位置编号，您需要将其带到领事馆。服务将在付款后2个工作日内完成。',
        cardImage: documentBrown,
      },
      {
        headerText: '在领事馆预约',
        cardText:
          '您可以自己预约领事馆，也可以在我们的帮助下预约（根据所选的收费标准）',
        cardImage: calendarImage,
      },
      {
        headerText: '领取已准备好的文件',
        cardText:
          '在您预约的当天，请带着护照和位置编号到领事馆，以便对准备好的文件进行公证。',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Presentar una Solicitud',
        cardText:
          'Describa qué debe incluirse en el documento. Puede enviarnos los documentos requeridos simultáneamente con la solicitud o directamente al abogado después de consultar con él.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparación del Texto del Documento',
        cardText:
          'Nuestro abogado se pondrá en contacto con usted, discutirá los detalles, preparará el borrador del documento y lo cargará en el portal del Departamento Consular del Ministerio de Relaciones Exteriores de Rusia. Recibirá un número de ubicación, que deberá llevar con usted al consulado. El servicio se completará dentro de los 2 días hábiles a partir de la fecha de pago.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Programación de Cita en el Consulado',
        cardText:
          'Programe una cita en el consulado usted mismo o con nuestra asistencia (según la tarifa seleccionada)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Recepción del Documento Listo',
        cardText:
          'El día de su cita, dirígete al consulado con tu pasaporte y número de ubicación para certificar el documento preparado.',
        cardImage: stampImage,
      },
    ],
  ],

  userTypes: [
    {
      userName: ['Физические лица', 'Individuals', '自然人', 'Individuos'],
      desc: [
        [
          'Внутренний паспорт и/или загранпаспорт РФ для всех упомянутых в документе сторон',
          'Russian internal passport and/or Russian foreign passport for all parties mentioned in the document',
          '俄罗斯内部护照和/或俄罗斯外国护照，适用于文件中提到的所有方',
          'Pasaporte interno ruso y/o pasaporte extranjero ruso para todas las partes mencionadas en el documento',
        ],
        [
          'Адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
          'Address of residence abroad in Russian (if there is no registration in Russia)',
          '在国外的居住地址（如果在俄罗斯没有注册）',
          'Dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
        ],
        [
          'Свидетельство о рождении ребёнка (если фигурирует ребёнок)',
          "Child's birth certificate (if a child is mentioned)",
          '儿童出生证明（如果提到孩子）',
          'Certificado de nacimiento del niño (si se menciona a un niño)',
        ],
        [
          'Свидетельство о браке и/или о его расторжении (если касается взаимоотношений супругов)',
          'Marriage certificate and/or divorce certificate (if it concerns the relationship between spouses)',
          '结婚证和/或离婚证（如果涉及配偶关系）',
          'Certificado de matrimonio y/o de divorcio (si se refiere a la relación entre cónyuges)',
        ],
        [
          'Документы на имущество (если речь идёт о каком-то конкретном)',
          'Property documents (if referring to specific property)',
          '财产文件（如果涉及具体财产）',
          'Documentos de propiedad (si se refiere a una propiedad específica)',
        ],
      ],
    },
    {
      userName: [
        'Юридические лица',
        'Legal Entities',
        '法人',
        'Entidades legales',
      ],
      desc: [
        [
          'Устав Организации и его изменения, если имели место быть',
          'Charter of the Organization and its amendments, if applicable',
          '组织章程及其修正案（如适用）',
          'Estatutos de la organización y sus enmiendas, si corresponde',
        ],
        [
          'Выписка из ЕГРЮЛ',
          'Extract from the Unified State Register of Legal Entities',
          '统一国家法人注册的摘录',
          'Extracto del Registro Estatal Unificado de Personas Jurídicas',
        ],
        [
          'Свидетельство ОГРН, ИНН, КПП',
          'Certificate of state registration number, tax identification number, and tax registration reason code',
          '国家注册号、税务识别号和税务登记原因代码的证书',
          'Certificado del número de registro estatal, número de identificación fiscal y código de razón de registro fiscal',
        ],
        [
          'Решение и приказ о назначении директора',
          'Decision and order on the appointment of the director',
          '关于任命董事的决定和命令',
          'Decisión y orden sobre el nombramiento del director',
        ],
        [
          'Паспортные данные директора',
          'Passport details of the director',
          '董事的护照信息',
          'Datos del pasaporte del director',
        ],
      ],
    },
    {
      userName: [
        'Учредитель (участник, акционер)',
        'Founder (participant, shareholder)',
        '创始人（参与者，股东）',
        'Fundador (participante, accionista)',
      ],
      desc: [
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ представителя (учредителя, участника, акционера)',
          'Russian internal passport and/or Russian foreign passport of the representative (founder, participant, shareholder)',
          '代表（创始人、参与者、股东）的俄罗斯内部护照和/或俄罗斯外国护照',
          'Pasaporte interno ruso y/o pasaporte extranjero ruso del representante (fundador, participante, accionista)',
        ],
        [
          'СНИЛС (если имеется)',
          'Individual insurance account number (if available)',
          '个人保险账户号码（如有）',
          'Número de cuenta de seguro individual (si está disponible)',
        ],
        [
          'Устав Организации и его изменения, если имели место быть',
          'Charter of the Organization and its amendments, if applicable',
          '组织章程及其修正案（如适用）',
          'Estatutos de la organización y sus enmiendas, si corresponde',
        ],
        [
          'Выписка из ЕГРЮЛ',
          'Extract from the Unified State Register of Legal Entities',
          '统一国家法人注册的摘录',
          'Extracto del Registro Estatal Unificado de Personas Jurídicas',
        ],
      ],
    },
  ],

  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '40',
    },
    {
      header: 'Without an appointment at the consulate',
      list: [
        'We will prepare a draft document',
        'We will submit the document on the consulate portal',
        'We will consult you on the list of documents required for certification',
        'We will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '40',
    },
    {
      header: '没有预约领事馆',
      list: [
        '我们将准备文件草稿',
        '我们将在领事馆门户网站上提交文件',
        '我们将咨询您所需的文件清单，以便进行认证',
        '我们将就如何自行预约领事馆进行咨询',
      ],
      value: '40',
    },
    {
      header: 'Sin cita en el consulado',
      list: [
        'Prepararemos un borrador del documento',
        'Presentaremos el documento en el portal consular',
        'Le asesoraremos sobre la lista de documentos necesarios para la certificación',
        'Le asesoraremos sobre cómo programar una cita en el consulado por su cuenta',
      ],
      value: '40',
    },
  ],
  requiredDocumentsText1: [
    'Базовый комплект документов, которые потребуются для оформления документа, включает в себя:',
    'The basic set of documents required for document processing includes:',
    '处理文件所需的基本文件包括：',
    'El conjunto básico de documentos requeridos para el procesamiento de documentos incluye:',
  ],

  requiredDocumentsText2: [
    'Для оформления нотариальных документов приоритет отдаётся внутреннему паспорту. В случае, если внутренний паспорт не оформлялся или просрочен — потребуется действующий загранпаспорт. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
    'For the processing of notarized documents, priority is given to the internal passport. In the event that the internal passport has not been issued or has expired, a valid foreign passport will be required. If both passports are available, it is necessary to bring both passports to the consulate appointment.',
    '对于公证文件的处理，优先考虑内部护照。如果内部护照尚未签发或已过期，则需要有效的外国护照。如果两本护照均可用，则必须在领事馆预约时携带两本护照。',
    'Para el procesamiento de documentos notariales, se da prioridad al pasaporte interno. En caso de que el pasaporte interno no haya sido emitido o haya expirado, se requerirá un pasaporte extranjero válido. Si ambos pasaportes están disponibles, es necesario llevar ambos pasaportes a la cita consular.',
  ],

  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Запишем вас на приём в консульство',
      ],
      value: '60',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'We will prepare a draft document',
        'We will submit the document on the consulate portal',
        'We will consult you on the list of documents required for certification',
        'We will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '60',
    },
    {
      header: '有预约领事馆',
      list: [
        '我们将准备文件草稿',
        '我们将在领事馆门户网站上提交文件',
        '我们将咨询您所需的文件清单，以便进行认证',
        '我们将为您预约领事馆的面谈',
      ],
      value: '60',
    },
    {
      header: 'Con cita en el consulado',
      list: [
        'Prepararemos un borrador del documento',
        'Presentaremos el documento en el portal consular',
        'Le asesoraremos sobre la lista de documentos necesarios para la certificación',
        'Le ayudaremos a programar una cita en el consulado',
      ],
      value: '60',
    },
  ],

  priceWithoutAppointment1: [
    {
      header: 'Без записи в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '80',
    },
    {
      header: 'Without an appointment at the consulate',
      list: [
        'We will prepare a draft document',
        'We will submit the document on the consulate portal',
        'We will consult you on the list of documents required for certification',
        'We will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '80',
    },
    {
      header: '没有预约领事馆',
      list: [
        '我们将准备文件草稿',
        '我们将在领事馆门户网站上提交文件',
        '我们将咨询您所需的文件清单，以便进行认证',
        '我们将就如何自行预约领事馆进行咨询',
      ],
      value: '80',
    },
    {
      header: 'Sin cita en el consulado',
      list: [
        'Prepararemos un borrador del documento',
        'Presentaremos el documento en el portal consular',
        'Le asesoraremos sobre la lista de documentos necesarios para la certificación',
        'Le asesoraremos sobre cómo programar una cita en el consulado por su cuenta',
      ],
      value: '80',
    },
  ],
  priceWithAppointment1: [
    {
      header: 'С записью в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Запишем вас на приём в консульство',
      ],
      value: '100',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'We will prepare a draft document',
        'We will submit the document on the consulate portal',
        'We will consult you on the list of documents required for certification',
        'We will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '100',
    },
    {
      header: '有预约领事馆',
      list: [
        '我们将准备文件草稿',
        '我们将在领事馆门户网站上提交文件',
        '我们将咨询您所需的文件清单，以便进行认证',
        '我们将为您预约领事馆的面谈',
      ],
      value: '100',
    },
    {
      header: 'Con cita en el consulado',
      list: [
        'Prepararemos un borrador del documento',
        'Presentaremos el documento en el portal consular',
        'Le asesoraremos sobre la lista de documentos necesarios para la certificación',
        'Le ayudaremos a programar una cita en el consulado',
      ],
      value: '100',
    },
  ],
  consentsStatements: [
    'Согласия, заявления:',
    'Consents, statements:',
    '同意，声明：',
    'Consentimientos, declaraciones:',
  ],
  contractsAgreements: [
    'Договора, соглашения:',
    'Contracts, agreements:',
    '合同，协议：',
    'Contratos, acuerdos:',
  ],
  faq: [
    {
      userName: [
        'Как я могу выписаться из своей квартиры в РФ, находясь за рубежом?',
        'How can I deregister from my apartment in the Russian Federation while abroad?',
        '我如何在国外办理俄罗斯联邦的公寓退房？',
        '¿Cómo puedo darme de baja de mi apartamento en la Federación Rusa mientras estoy en el extranjero?',
      ],
      desc: [
        [
          'В консульстве можно оформить два документа (как правило их оформляют в паре): заявление (согласие) о снятии с регистрационного учёта и доверенность на представительство в Госорганах по данному вопросу.',
          'At the consulate you can draw up two documents (usually they are drawn up in pairs): an application (consent) for deregistration and a Power of Attorney for representation in government agencies on this issue.',
          '在领事馆，您可以准备两个文件（通常是成对准备）：注销申请（同意）和用于在政府机构代表的授权书。',
          'En el consulado, puede redactar dos documentos (generalmente se redactan en pares): una solicitud (consentimiento) de baja y un poder notarial para representación ante los organismos gubernamentales sobre este asunto.',
        ],
      ],
    },
    {
      userName: [
        'Если ребёнок выезжает за границу с одним из родителей, требуется ли на это согласие второго?',
        'If a child travels abroad with one of the parents, is the consent of the other required?',
        '如果孩子与其中一位父母一起出国，是否需要另一位父母的同意？',
        'Si un niño viaja al extranjero con uno de los padres, ¿se requiere el consentimiento del otro?',
      ],
      desc: [
        [
          'Для выезда ребёнка за пределы России — не требуется, однако его может запросить страна въезда при оформлении визы (например, в страны шенгенской зоны), а также для получения ВНЖ другой страны.',
          'It is not required for a child to go outside of Russia, but it may be requested by the country of entry when applying for a visa (for example, to countries in the Schengen zone), as well as to obtain a residence permit of another country.',
          '孩子出境不需要，但入境国在申请签证时（例如，申根区国家）可能会要求同意，此外，还可能需要在其他国家获得居留许可。',
          'No se requiere para que un niño salga de Rusia, pero puede ser solicitado por el país de entrada al solicitar una visa (por ejemplo, a los países de la zona Schengen), así como para obtener un permiso de residencia en otro país.',
        ],
        [
          'Необходимость получения согласия от родителя в таких случаях не зависит от того, в разводе супруги или нет.',
          'The need to obtain consent from a parent in such cases does not depend on whether the spouse is divorced or not.',
          '在这种情况下，是否需要获得父母的同意与配偶是否离婚无关。',
          'La necesidad de obtener el consentimiento de un padre en tales casos no depende de si la pareja está divorciada o no.',
        ],
      ],
    },
    {
      userName: [
        'Когда требуется согласие супруга на сделку?',
        "When is a spouse's consent required for a transaction?",
        '何时需要配偶同意进行交易？',
        '¿Cuándo se requiere el consentimiento del cónyuge para una transacción?',
      ],
      desc: [
        [
          'Согласие требуется в каждом случае, когда речь идёт о совместно нажитом в браке имуществе.',
          'Consent is required in every case when it comes to property acquired jointly during marriage.',
          '在每个涉及婚姻期间共同获得的财产的情况下，均需要同意。',
          'Se requiere consentimiento en cada caso que se refiera a bienes adquiridos en común durante el matrimonio.',
        ],
        [
          'К нему не относятся объекты, приобретённые одним из супругов до брака, полученные в дар или по наследству.',
          'It does not include objects acquired by one of the spouses before marriage, received as a gift or by inheritance.',
          '不包括婚前由一方获得的财产、赠予或继承的财产。',
          'No incluye objetos adquiridos por uno de los cónyuges antes del matrimonio, recibidos como regalo o por herencia.',
        ],
      ],
    },
  ],
  faqDesc: [
    [
      {
        question:
          'Как я могу выписаться из своей квартиры в РФ, находясь за рубежом?',
        answer:
          'В консульстве можно оформить два документа (как правило их оформляют в паре): заявление (согласие) о снятии с регистрационного учёта и доверенность на представительство в Госорганах по данному вопросу.',
      },
      {
        question:
          'Если ребёнок выезжает за границу с одним из родителей, требуется ли на это согласие второго?',
        answer:
          'Для выезда ребёнка за пределы России — не требуется, однако его может запросить страна въезда при оформлении визы (например, в страны шенгенской зоны), а также для получения ВНЖ другой страны.',
        answer2:
          'Необходимость получения согласия от родителя в таких случаях не зависит от того, в разводе супруги или нет.',
      },
      {
        question: 'Когда требуется согласие супруга на сделку?',
        answer:
          'Согласие требуется в каждом случае, когда речь идёт о совместно нажитом в браке имуществе.',
        answer1:
          'К нему не относятся объекты, приобретённые одним из супругов до брака, полученные в дар или по наследству.',
      },
    ],
    [
      {
        question:
          'How can I check out of my apartment in the Russian Federation while abroad?',
        answer:
          'At the consulate, you can draw up two documents (usually they are drawn up in pairs): an application (consent) for deregistration and a Power of Attorney for representation in government agencies on this issue.',
      },
      {
        question:
          'If a child travels abroad with one of the parents, is the consent of the other required?',
        answer:
          'It is not required for a child to travel outside of Russia, but it may be requested by the country of entry when applying for a visa (for example, to countries in the Schengen zone), as well as to obtain a residence permit of another country.',
        answer1:
          'The need to obtain consent from a parent in such cases does not depend on whether the spouse is divorced or not.',
      },
      {
        question: "When is a spouse's consent required for a transaction?",
        answer:
          'Consent is required in every case when it comes to property acquired jointly during marriage.',
        answer1:
          'It does not include objects acquired by one of the spouses before marriage, received as a gift, or by inheritance.',
      },
    ],
    [
      {
        question: '我如何在国外办理俄罗斯联邦的公寓退房？',
        answer:
          '在领事馆，您可以准备两个文件（通常是成对准备）：注销申请（同意）和用于在政府机构代表的授权书。',
      },
      {
        question: '如果孩子与其中一位父母一起出国，是否需要另一位父母的同意？',
        answer:
          '孩子出境不需要，但入境国在申请签证时（例如，申根区国家）可能会要求同意，此外，还可能需要在其他国家获得居留许可。',
        answer1: '在这种情况下，是否需要获得父母的同意与配偶是否离婚无关。',
      },
      {
        question: '何时需要配偶同意进行交易？',
        answer: '在每个涉及婚姻期间共同获得的财产的情况下，均需要同意。',
        answer1: '不包括婚前由一方获得的财产、赠予或继承的财产。',
      },
    ],
    [
      {
        question:
          '¿Cómo puedo darme de baja de mi apartamento en la Federación Rusa mientras estoy en el extranjero?',
        answer:
          'En el consulado, puede redactar dos documentos (generalmente se redactan en pares): una solicitud (consentimiento) de baja y un poder notarial para representación ante los organismos gubernamentales sobre este asunto.',
      },
      {
        question:
          'Si un niño viaja al extranjero con uno de los padres, ¿se requiere el consentimiento del otro?',
        answer:
          'No se requiere para que un niño salga de Rusia, pero puede ser solicitado por el país de entrada al solicitar una visa (por ejemplo, a los países de la zona Schengen), así como para obtener un permiso de residencia en otro país.',
        answer1:
          'La necesidad de obtener el consentimiento de un padre en tales casos no depende de si la pareja está divorciada o no.',
      },
      {
        question:
          '¿Cuándo se requiere el consentimiento del cónyuge para una transacción?',
        answer:
          'Se requiere consentimiento en cada caso que se refiera a bienes adquiridos en común durante el matrimonio.',
        answer1:
          'No incluye objetos adquiridos por uno de los cónyuges antes del matrimonio, recibidos como regalo o por herencia.',
      },
    ],
  ],

  workflow: {
    requestDocumentType: {
      title: [
        'Какой документ вам необходим?',
        'What document do you need?',
        '您需要什么文件？',
        '¿Qué documento necesita?',
      ],
      options: [
        ...serviceTypes,
        ['Не знаю', 'Not sure', '不知道', 'No estoy seguro'],
      ],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words what is required',
        '用自己的话描述所需的文件',
        'Describe con sus propias palabras lo que se requiere',
      ],
    },
    requestConsentType: {
      title: [
        'Какое согласие вам необходимо?',
        'What type of consent do you need?',
        '您需要什么类型的同意？',
        '¿Qué tipo de consentimiento necesita?',
      ],
      selectPlaceholder: [
        'Тип согласия',
        'Type of consent',
        '同意类型',
        'Tipo de consentimiento',
      ],
      options: [
        ...consentTypes,
        ['Другой документ', 'Other document', '其他文件', 'Otro documento'],
      ],
      additionalInfo: [
        'Или опишите своими словами, на что должно быть выдано согласие',
        'Or describe in your own words what should be given as consent',
        '或者用自己的话描述应给予什么同意',
        'O describa con sus propias palabras qué debe otorgarse como consentimiento',
      ],
    },
    requestApplicationType: {
      title: [
        'Какое заявление вам необходимо?',
        'What application do you need?',
        '您需要什么申请？',
        '¿Qué solicitud necesita?',
        'Какое заявление вам необходимо?',
        'What application do you need?',
        '您需要什么申请？',
        '¿Qué solicitud necesita?',
      ],
      options: [
        ...applicationTypes,
        ['Другой документ', 'Other document', '其他文件', 'Otro documento'],
      ],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words which document is required',
        '用自己的话描述所需的文件',
        'Describe con sus propias palabras el documento que se requiere',
      ],
    },
    requestAgreementType: {
      title: [
        'Какой договор или соглашение вам необходимо?',
        'Which contract or agreement do you need?',
        '您需要什么合同或协议？',
        '¿Qué contrato o acuerdo necesita?',
        'Какой договор или соглашение вам необходимо?',
        'Which contract or agreement do you need?',
        '您需要什么合同或协议？',
        '¿Qué contrato o acuerdo necesita?',
      ],
      options: [
        ...agreementTypes,
        ['Другой документ', 'Other document', '其他文件', 'Otro documento'],
      ],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words which document is required',
        '用自己的话描述所需的文件',
        'Describe con sus propias palabras el documento que se requiere',
      ],
    },
    requestAdditionalInformation: {
      title: [
        'Ваши комментарии',
        'Your comments',
        '您的评论',
        'Sus comentarios',
      ],
      additionalInfo: [
        'Опишите своими словами, для каких целей будет использоваться документ',
        'Describe in your own words the purpose for which the document will be used',
        '用自己的话描述文件的用途',
        'Describa con sus propias palabras el propósito para el cual se utilizará el documento',
      ],
    },
    requestGuarantorAndBorrower: {
      titleGuarantor: [
        'Кто является поручителем?',
        'Who is the guarantor?',
        '谁是担保人？',
        '¿Quién es el garante?',
      ],
      titleBorrower: [
        'Кто является заёмщиком?',
        'Who is the borrower?',
        '谁是借款人？',
        '¿Quién es el prestatario?',
      ],
      options: [
        ['Физическое лицо', 'Individual', '自然人', 'Persona individual'],
        ['Юридическое лицо', 'Legal entity', '法人', 'Entidad legal'],
      ],
    },
    requestCreditor: {
      title: [
        'Кто является кредитором?',
        'Who is the creditor?',
        '谁是债权人？',
        '¿Quién es el acreedor?',
      ],
      options: [
        ['Физическое лицо', 'Individual', '自然人', 'Persona individual'],
        ['Юридическое лицо', 'Legal entity', '法人', 'Entidad legal'],
      ],
      subjectOfGuaranteeTitle: [
        'Предмет поручительства',
        'Subject of guarantee',
        '担保的主题',
        'Sujeto de la garantía',
      ],
      additionalInfo: [
        'Опишите своими словами. Например, ипотечный договор на покупку недвижимости',
        'Describe in your own words. For example, a mortgage agreement for the purchase of real estate',
        '用自己的话描述。例如，购买房地产的抵押贷款协议',
        'Describa con sus propias palabras. Por ejemplo, un contrato de hipoteca para la compra de bienes raíces',
      ],
    },
    requestPersonWhoTransferringRights: {
      whoTitle: [
        'Кто уступает права?',
        'Who is transferring the rights?',
        '谁转让权利？',
        '¿Quién está transfiriendo los derechos?',
      ],
      toWhomTitle: [
        'Кому уступаются права?',
        'To whom are the rights being transferred?',
        '权利转让给谁？',
        '¿A quién se transfieren los derechos?',
      ],
      options: [
        ['Физическое лицо', 'Individual', '自然人', 'Persona individual'],
        ['Юридическое лицо', 'Legal entity', '法人', 'Entidad legal'],
      ],
    },
    requestRightsAreBeingTransferred: {
      title: [
        'Какие права переуступаются?',
        'What rights are being transferred?',
        '转让哪些权利？',
        '¿Qué derechos se están transfiriendo?',
      ],
      additionalInfo: [
        'Опишите своими словами. Например, право на получение квартиры или право взыскать задолженность по кредиту',
        'Describe in your own words. For example, the right to receive an apartment or the right to collect debt on a loan',
        '用自己的话描述。例如，获取公寓的权利或收回贷款债务的权利',
        'Describa con sus propias palabras. Por ejemplo, el derecho a recibir un apartamento o el derecho a cobrar una deuda de un préstamo',
      ],
    },
  },
}
export default vocabularyConsent

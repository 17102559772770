import serviceAxios from './serviceAxios'

export async function postNewFile(data, { onUploadProgress }) {
  try {
    const formData = new FormData()
    formData.append('file', data.file)
    const response = await serviceAxios.post(
      '/private/new-user-file',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },

        onUploadProgress: progressEvent => {
          const progress = parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total),
          )
          // Update state here
          onUploadProgress(progress)
        },
      },
    )

    return response
  } catch (error) {
    console.error('An error occurred:', error)

    return 'error'
  }
}

export async function getUserRequests() {
  const apiUrl = '/private/get-user-requests'

  let response

  try {
    response = await serviceAxios.get(apiUrl)
    response = response.data.userRequests
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }

  return response
}

export async function createPayment(data) {
  const apiUrl = '/private/create-payment'
  let response

  try {
    response = await serviceAxios.post(apiUrl, { price: data })

    return response.data.paymentIntent
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }
}

export async function createPaymentRandom(data) {
  const apiUrl = '/public/create-payment'
  let response

  try {
    response = await serviceAxios.post(apiUrl, { price: data })

    return response.data.paymentIntent
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }
}

export async function cancelUserRequests(data) {
  const apiUrl = '/private/cancel-user-request'

  let response
  console.log('api call to delete')

  try {
    response = await serviceAxios.put(apiUrl, data)
    response = response.data
    console.log(response, 'delete response')
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }

  return response
}

export async function changeUserPasswordCall(data) {
  const apiUrl = '/private/change-user-password'

  let response

  try {
    response = await serviceAxios.put(apiUrl, data)
    response = response.data
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }

  return response
}

export async function updateUserRequests(data) {
  const apiUrl = '/private/update-user-request'

  let response

  try {
    response = await serviceAxios.put(apiUrl, data)
    response = response.data
    console.log(response, 'delete response')
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }

  return response
}

export async function deleteUserRequests(data) {
  const apiUrl = '/private/delete-user-request'

  let response

  try {
    response = await serviceAxios.put(apiUrl, data)
    response = response.data
    console.log(response, 'delete response')
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }

  return response
}

import vocabularyPowerOfAttorney from 'vocabulary/vocabularyPowerOfAttorney'
import vocabularyPassport from 'vocabulary/vocabularyPassport'
import {
  serviceTypes as consentServiceTypes,
  applicationTypes as consentApplicationTypes,
  agreementTypes as consentAgreementTypes,
  consentTypes,
} from 'vocabulary/vocabularyConsent'
import vocabularyCitizenship from './vocabularyCitizenship'
import vocabularyZags from './vocabularyZags'
import vocabularyOther from './vocabularyOther'
import vocabularyConsulting from './vocabularyConsulting'
import vocabularyCertificates from 'vocabulary/vocabularyCertificates'

/**
 * Функция для объединения нескольких массивов подмассивов в один объект.
 * Ключом объекта становится второй элемент каждого подмассива, а значением - сам подмассив.
 *
 * @param {Array[]} arrays - Массив массивов подмассивов, которые нужно объединить в объект.
 * @returns {Object} Объект, где ключами являются вторые элементы подмассивов, а значениями - подмассивы.
 */
const mergeArraysToObject = arrays => {
  return arrays.reduce((acc, array) => {
    array.forEach(subArray => {
      acc[subArray[1]] = subArray
    })

    return acc
  }, {})
}

export const vocabulary = {
  application: ['Заявка', 'Application', '申请', 'Solicitud'],
  allApplications: [
    'Все заявки',
    'All applications',
    '所有申请',
    'Todas las solicitudes',
  ],
  tabs: [
    ['Общее', 'Summary', '摘要', 'Resumen'],
    ['Детали', 'Details', '细节', 'Detalles'],
    ['Документы', 'Documents', '文件', 'Documentos'],
  ],
  edit: ['Редактировать', 'Edit', '编辑', 'Editar'],
  createdDate: [
    'Дата создания',
    'Created date',
    '创建日期',
    'Fecha de creación',
  ],
  consultation: ['Консультация', 'Consultation', '咨询', 'Consulta'],
  tariff: [
    'Выбранный тариф',
    'Selected tariff',
    '选定的费率',
    'Tarifa seleccionada',
  ],
  sumToPaid: ['Сумма к оплате', 'Sum to paid', '应付款总额', 'Suma a pagar'],
  paymentStatus: [
    'Статус оплаты',
    'Payment status',
    '支付状态',
    'Estado de pago',
  ],
  requestStatus: ['Статус заявки', 'Status', '状态', 'Estado'],
  place: {
    Australia: ['Австралия', 'Australia', '澳大利亚', 'Australia'],
    Sydney: ['Сидней', 'Sydney', '悉尼', 'Sídney'],
    Austria: ['Австрия', 'Austria', '奥地利', 'Austria'],
    Salzburg: ['Зальцбург', 'Salzburg', '萨尔茨堡', 'Salzburgo'],
    Algeria: ['Алжир', 'Algeria', '阿尔及利亚', 'Argelia'],
    Annaba: ['Аннаба', 'Annaba', '安纳巴', 'Annaba'],
    Armenia: ['Армения', 'Armenia', '亚美尼亚', 'Armenia'],
    Gyumri: ['Гюмри', 'Gyumri', '居姆里', 'Gyumri'],
    Afghanistan: ['Афганистан', 'Afghanistan', '阿富汗', 'Afganistán'],
    'Mazar-i-Sharif': [
      'Мазари-Шариф',
      'Mazar-i-Sharif',
      '马扎里沙里夫',
      'Mazar-i-Sharif',
    ],
    Bangladesh: ['Бангладеш', 'Bangladesh', '孟加拉国', 'Bangladés'],
    Chittagong: ['Читтагонг', 'Chittagong', '吉大港', 'Chittagong'],
    Belarus: ['Белоруссия', 'Belarus', '白俄罗斯', 'Bielorrusia'],
    Brest: ['Брест', 'Brest', '布列斯特', 'Brest'],
    Belgium: ['Бельгия', 'Belgium', '比利时', 'Bélgica'],
    Antwerp: ['Антверпен', 'Antwerp', '安特卫普', 'Amberes'],
    Bulgaria: ['Болгария', 'Bulgaria', '保加利亚', 'Bulgaria'],
    Varna: ['Варна', 'Varna', '瓦尔纳', 'Varna'],
    Ruse: ['Русе', 'Ruse', '鲁塞', 'Ruse'],
    Brazil: ['Бразилия', 'Brazil', '巴西', 'Brasil'],
    'Rio de Janeiro': [
      'Рио-де-Жанейро',
      'Rio de Janeiro',
      '里约热内卢',
      'Río de Janeiro',
    ],
    'São Paulo': ['Сан-Паулу', 'São Paulo', '圣保罗', 'São Paulo'],
    'United Kingdom': [
      'Великобритания',
      'United Kingdom',
      '英国',
      'Reino Unido',
    ],
    Edinburgh: ['Эдинбург', 'Edinburgh', '爱丁堡', 'Edimburgo'],
    Hungary: ['Венгрия', 'Hungary', '匈牙利', 'Hungría'],
    Debrecen: ['Дебрецен', 'Debrecen', '德布勒岑', 'Debrecen'],
    Vietnam: ['Вьетнам', 'Vietnam', '越南', 'Vietnam'],
    'Da Nang': ['Дананг', 'Da Nang', '岘港', 'Da Nang'],
    'Ho Chi Minh City': [
      'Хошимин',
      'Ho Chi Minh City',
      '胡志明市',
      'Ciudad Ho Chi Minh',
    ],
    Germany: ['Германия', 'Germany', '德国', 'Alemania'],
    Bonn: ['Бонн', 'Bonn', '波恩', 'Bonn'],
    Hamburg: ['Гамбург', 'Hamburg', '汉堡', 'Hamburgo'],
    Leipzig: ['Лейпциг', 'Leipzig', '莱比锡', 'Leipzig'],
    Munich: ['Мюнхен', 'Munich', '慕尼黑', 'Múnich'],
    Frankfurt: ['Франкфурт-на-Майне', 'Frankfurt', '法兰克福', 'Fráncfort'],
    Greece: ['Греция', 'Greece', '希腊', 'Grecia'],
    Thessaloniki: ['Салоники', 'Thessaloniki', '塞萨洛尼基', 'Salónica'],
    Egypt: ['Египет', 'Egypt', '埃及', 'Egipto'],
    Alexandria: ['Александрия', 'Alexandria', '亚历山大', 'Alejandría'],
    Israel: ['Израиль', 'Israel', '以色列', 'Israel'],
    Haifa: ['Хайфа', 'Haifa', '海法', 'Haifa'],
    India: ['Индия', 'India', '印度', 'India'],
    Kolkata: ['Калькутта', 'Kolkata', '加尔各答', 'Calcuta'],
    Mumbai: ['Мумбаи', 'Mumbai', '孟买', 'Bombay'],
    Chennai: ['Ченнаи', 'Chennai', '钦奈', 'Chennai'],
    Iraq: ['Ирак', 'Iraq', '伊拉克', 'Irak'],
    Basra: ['Басра', 'Basra', '巴斯拉', 'Basora'],
    Erbil: ['Эрбиль', 'Erbil', '埃尔比尔', 'Erbil'],
    Iran: ['Иран', 'Iran', '伊朗', 'Irán'],
    Isfahan: ['Исфахан', 'Isfахан', '伊斯法罕', 'Isfahán'],
    Rasht: ['Решт', 'Rasht', '拉什特', 'Rasht'],
    Spain: ['Испания', 'Spain', '西班牙', 'España'],
    Barcelona: ['Барселона', 'Barcelona', '巴塞罗那', 'Barcelona'],
    Italy: ['Италия', 'Italy', '意大利', 'Italia'],
    Genoa: ['Генуя', 'Genoa', '热那亚', 'Génova'],
    Milan: ['Милан', 'Milan', '米兰', 'Milán'],
    Palermo: ['Палермо', 'Palermo', '巴勒莫', 'Palermo'],
    Yemen: ['Йемен', 'Yemen', '也门', 'Yemen'],
    Aden: ['Аден', 'Aden', '亚丁', 'Adén'],
    Kazakhstan: ['Казахстан', 'Kazakhstan', '哈萨克斯坦', 'Kazajistán'],
    Almaty: ['Алма-Ата', 'Almaty', '阿拉木图', 'Almaty'],
    Uralsk: ['Уральск', 'Uralsk', '乌拉尔斯克', 'Uralsk'],
    'Ust-Kamenogorsk': [
      'Усть-Каменогорск',
      'Ust-Kamenogorsk',
      '乌斯季卡门诺戈尔斯克',
      'Ust-Kamenogorsk',
    ],
    Canada: ['Канада', 'Canada', '加拿大', 'Canadá'],
    Montreal: ['Монреаль', 'Montreal', '蒙特利尔', 'Montreal'],
    Toronto: ['Торонто', 'Toronto', '多伦多', 'Toronto'],
    Kyrgyzstan: ['Кыргызстан', 'Kyrgyzстан', '吉尔吉斯斯坦', 'Kirguistán'],
    Osh: ['Ош', 'Osh', '奥什', 'Osh'],
    China: ['Китай', 'China', '中国', 'China'],
    'Hong Kong': ['Гонконг', 'Hong Kong', '香港', 'Hong Kong'],
    Guangzhou: ['Гуанчжоу', 'Guangzhou', '广州', 'Cantón'],
    Harbin: ['Харбин', 'Harbin', '哈尔滨', 'Harbin'],
  },
  statusValue: {
    sent: ['Отправлена', 'Sent', '已发送', 'Enviado'],
    ready: ['Готовo', 'Ready', '准备好', 'Listo'],
    processing: ['В процессе', 'In process', '处理中', 'En proceso'],
  },
  tariffValue: {
    withAppointment: [
      'С записью на приём в консульство',
      'With appointment',
      '预约',
      'Con cita',
    ],
    withoutAppointment: [
      'Без записи на приём в консульство',
      'Without appointment',
      '无需预约',
      'Sin cita',
    ],
  },
  marriageStatus: [
    'Состояли ли родители ребёнка в браке на момент его рождения:',
    'Did the parents of the child in marriage at the time of its birth:',
    '孩子出生时父母是否结婚：',
    '¿Estaban los padres del niño casados en el momento de su nacimiento?',
  ],
  paternityBase: [
    'Основание для установления отцовства:',
    'Paternity base:',
    '父权基础：',
    'Base de paternidad:',
  ],
  isThisMarriageFirst: [
    'Является ли этот брак первым для обоих:',
    'Is this marriage the first for both:',
    '这是双方的第一次婚姻吗：',
    '¿Es este matrimonio el primero para ambos?',
  ],
  divorceBase: [
    'Основание для расторжения брака:',
    'Divorce base:',
    '离婚基础：',
    'Base de divorcio:',
  ],
  powerOfAttorney: {
    // service
    subtitle: mergeArraysToObject([vocabularyPowerOfAttorney.typesOfPoa]),
  },
  passport: {
    subtitle: {
      '10 year': vocabularyPassport.newPassportVariant,
      '5 year': vocabularyPassport.oldPassportVariant,
    },
  },
  consent: {
    summaryTitle: mergeArraysToObject([consentServiceTypes]),
    subtitle: mergeArraysToObject([
      consentApplicationTypes,
      consentAgreementTypes,
      consentTypes,
    ]),
  },
  certificates: {
    subtitle: {
      'Consular certificate': [
        'Консульская справка',
        'Consular certificate',
        '领事证书',
        'Certificado consular',
      ],
      'Request a document from Russia': [
        'Истребование документа из России',
        'Request a document from Russia',
        '请求从俄罗斯获取文件',
        'Solicitud de un documento de Rusia',
      ],
    },
  },
  section: {
    poa: ['Доверенность', 'Power of attorney', '授权书', 'Poder notarial'],
    passport: [
      'Загранпаспорт',
      'International passport',
      '护照',
      'Pasaporte internacional',
    ],
    certificates: vocabularyCertificates.certificates,
    consent: [
      'Согласие, Заявление, Договор, соглашение',
      'Agreement, Application, Consent',
      '同意，申请，协议',
      'Acuerdo, Solicitud, Consentimiento',
    ],
    legalEntities: [
      'Документы для юр. лиц',
      'Legal entities documents',
      '法律实体文件',
      'Documentos para entidades legales',
    ],
    citizenship: vocabularyCitizenship.citizenship,
    zags: vocabularyZags.zagsServices,
    other: vocabularyOther.otherServices,
    consulting: vocabularyConsulting.consulting,
  },

  labels: {
    whoGives: ['Доверители', 'Trustors', '委托人', 'Fiduciarios'],
    whomGives: ['Представители', 'Representatives', '代表', 'Representantes'],
    whatGives: ['Полномочия', 'Powers', '权力', 'Poderes'],
    transferRights: [
      'Право передоверия',
      'Right to Delegate',
      '转让权利',
      'Derecho a delegar',
    ],
    timePoa: ['Срок действия', 'Duration', '有效期', 'Duración'],
  },
  yes: ['Да', 'Yes', '是', 'Sí'],
  no: ['Нет', 'No', '不是', 'No'],
  until: ['до', 'until', '直到', 'hasta'],
  notChosen: ['Не выбрано', 'Not chosen', '未选择', 'No elegido'],
}

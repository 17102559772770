import React, { useEffect, useState } from 'react'
import { getAdminData } from 'api/admin'
import { updateUserRequests, deleteUserRequests } from 'api/workflow'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CircularProgress from '@mui/material/CircularProgress'

const Orders = () => {
  const [infoArray, setInfoArray] = useState([])
  const [filteredInfoArray, setFilteredInfoArray] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [filterField, setFilterField] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [loading, setLoading] = useState(true)
  const fetchData = async () => {
    try {
      setLoading(true)
      let response = await getAdminData()
      setLoading(false)
      if (response?.requestData) {
        let parsedData = response.requestData.map((val, index) => {
          let reqData = JSON.parse(val.requestData)
          reqData.status = response.requestData[index].requestStatus

          return {
            user: val.userId,
            request: reqData,
            timeCreated: response.requestData[index].createdAt,
            id: response.requestData[index].id,
            selectedStatus: reqData.status, // Initialize with current status
          }
        })
        const reversedData = parsedData.reverse()
        setInfoArray(reversedData)
        setFilteredInfoArray(reversedData)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleDateFilter = () => {
    const filteredData = infoArray.filter(item => {
      const itemDate = new Date(item.timeCreated)
      const start = startDate ? new Date(startDate) : new Date('1970-01-01')
      const end = endDate ? new Date(endDate) : new Date()

      return itemDate >= start && itemDate <= end
    })
    setFilteredInfoArray(filteredData)
  }

  const handleFieldFilter = () => {
    if (filterField && filterValue) {
      const filteredData = infoArray.filter(item => {
        if (filterField === 'username') {
          return item.user.username.includes(filterValue)
        } else {
          return item.request[filterField]?.toString().includes(filterValue)
        }
      })
      setFilteredInfoArray(filteredData)
    }
  }

  const handleResetFilters = () => {
    setFilteredInfoArray(infoArray)
    setStartDate('')
    setEndDate('')
    setFilterField('')
    setFilterValue('')
  }

  const handleStatusChange = async (userId, newStatus, orderId) => {
    try {
      await updateUserRequests({ requestId: orderId, requestStatus: newStatus })
      const updatedArray = infoArray.map(item =>
        item.user === userId
          ? {
              ...item,
              request: { ...item.request, status: newStatus },
              selectedStatus: newStatus,
            }
          : item,
      )
      setInfoArray(updatedArray)
      setFilteredInfoArray(updatedArray)
    } catch (error) {
      console.error('Error updating status:', error)
    }
  }

  const formatDateTime = dateTime => {
    const date = new Date(dateTime)
    const day = ('0' + date.getDate()).slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    const hours = ('0' + date.getHours()).slice(-2)
    const minutes = ('0' + date.getMinutes()).slice(-2)

    return `${day}-${month}-${year}, ${hours}:${minutes}`
  }

  return (
    <Box sx={{ width: '100%', m: 4 }}>
      <Typography variant='h4' gutterBottom>
        Orders
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <TextField
          label='Start Date'
          type='date'
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginRight: 2 }}
        />
        <TextField
          label='End Date'
          type='date'
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginRight: 2 }}
        />
        <Button variant='contained' onClick={handleDateFilter}>
          Filter by Date
        </Button>
        <FormControl sx={{ marginLeft: 2, minWidth: 200 }}>
          <InputLabel>Filter Field</InputLabel>
          <Select
            value={filterField}
            onChange={e => setFilterField(e.target.value)}
          >
            <MenuItem value='username'>Username</MenuItem>
            {infoArray.length > 0 &&
              Object.keys(infoArray[0].request).map(key => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          label='Filter Value'
          value={filterValue}
          onChange={e => setFilterValue(e.target.value)}
          sx={{ marginLeft: 2 }}
        />
        <Button
          variant='contained'
          onClick={handleFieldFilter}
          sx={{ marginLeft: 2 }}
        >
          Filter by Field
        </Button>
        <Button
          variant='contained'
          onClick={handleResetFilters}
          sx={{ marginLeft: 2 }}
        >
          Reset Filters
        </Button>
      </Box>

      {loading ? (
        <Box
          sx={{
            w: 1,
            height: '50vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '100%',
            flexWrap: 'wrap',
          }}
        >
          {filteredInfoArray.map((item, index) => {
            const keys = Object.keys(item.request)
            const sortedKeys = [
              'userData',
              ...keys.filter(key => key !== 'userData'),
            ]

            return (
              <Paper
                key={index}
                sx={{ marginTop: '16px', maxWidth: 400, m: 4 }}
              >
                <List>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography variant='subtitle1' fontWeight='bold'>
                          Mail: {item.user.username}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography variant='subtitle1' fontWeight='bold'>
                          Family name: {item.user.familyName}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography variant='subtitle1' fontWeight='bold'>
                          Name: {item.user.givenName}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography variant='subtitle1' fontWeight='bold'>
                          Time created: {formatDateTime(item.timeCreated)}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          variant='subtitle1'
                          fontWeight='bold'
                          sx={{
                            color:
                              item.request.status === 'sent'
                                ? 'red'
                                : item.request.status === 'processing'
                                  ? 'orange'
                                  : item.request.status === 'ready'
                                    ? 'green'
                                    : null,
                          }}
                        >
                          Status: {item.request.status}
                        </Typography>
                      }
                    />
                    <FormControl sx={{ marginLeft: 2, minWidth: 120 }}>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={item.selectedStatus}
                        onChange={e => {
                          const newStatus = e.target.value
                          const updatedArray = infoArray.map(order =>
                            order.id === item.id
                              ? { ...order, selectedStatus: newStatus }
                              : order,
                          )
                          setInfoArray(updatedArray)
                          setFilteredInfoArray(updatedArray)
                        }}
                      >
                        <MenuItem value='sent'>Sent</MenuItem>
                        <MenuItem value='processing'>Processing</MenuItem>
                        <MenuItem value='ready'>Ready</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      variant='contained'
                      onClick={() =>
                        handleStatusChange(
                          item.user,
                          item.selectedStatus,
                          item.id,
                        )
                      }
                      sx={{ marginLeft: 2 }}
                    >
                      Change status
                    </Button>
                  </ListItem>
                  <Button
                    variant='contained'
                    color='error'
                    onClick={async () => {
                      await deleteUserRequests({ requestId: item.id })
                      fetchData()
                    }}
                    sx={{ margin: 2 }}
                  >
                    Delete request
                  </Button>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography variant='subtitle1' fontWeight='bold'>
                        Request Information
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {sortedKeys.map(key => {
                        if (key === 'userData') {
                          return Object.keys(item.request.userData).map(
                            userDataKey => (
                              <ListItem key={userDataKey}>
                                <Paper elevation={3} sx={{ px: 2 }}>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        variant='h7'
                                        color='primary'
                                        fontWeight='bold'
                                      >
                                        {userDataKey === 'userName'
                                          ? 'Name'
                                          : userDataKey === 'userMail'
                                            ? 'Mail'
                                            : userDataKey === 'userPhone'
                                              ? 'Phone'
                                              : null}
                                        : {item.request.userData[userDataKey]}
                                      </Typography>
                                    }
                                  />
                                </Paper>
                              </ListItem>
                            ),
                          )
                        } else if (key === 'serviceDetails') {
                          if (!item.request.serviceDetails) {
                            return null
                          }

                          return Object.keys(item.request.serviceDetails).map(
                            serviceDetailsKey => (
                              <ListItem key={serviceDetailsKey}>
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant='body2'
                                      style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      {serviceDetailsKey}:{' '}
                                      {JSON.stringify(
                                        item.request.serviceDetails[
                                          serviceDetailsKey
                                        ],
                                      )}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ),
                          )
                        } else {
                          return (
                            <ListItem key={key}>
                              <ListItemText
                                primary={
                                  <Typography
                                    variant='body2'
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textWrap: 'wrap',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {key}:{' '}
                                    {typeof item.request[key] === 'object'
                                      ? JSON.stringify(item.request[key])
                                      : item.request[key]}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          )
                        }
                      })}
                    </AccordionDetails>
                  </Accordion>
                  {index < filteredInfoArray.length - 1 && (
                    <Divider variant='middle' />
                  )}
                </List>
              </Paper>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default Orders
